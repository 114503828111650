const prefix = "ACTION.LOCAL.AUTH";

const AUTH = {
  CREDENTIALS: {
    SET: `${prefix}.CREDENTIALS.SET`,
    RESET: `${prefix}.CREDENTIALS.RESET`,
    FAILURE: `${prefix}.CREDENTIALS.FAILURE`,
    LOADING: `${prefix}.CREDENTIALS.LOADING`,
    SET_EMPTY: `${prefix}.CREDENTIALS.SET_EMPTY`,
    SET_SIGN_UP_USER: `${prefix}.CREDENTIALS.SET_SIGN_UP_USER`,
  },
  UPDATE_FLAG: {
    IS_LOADING: `${prefix}.UPDATE_FLAG.IS_LOADING`,
    IS_LOADED: `${prefix}.UPDATE_FLAG.IS_LOADED`,
    IS_EMPTY: `${prefix}.UPDATE_FLAG.IS_EMPTY`,
  },
  ANY_FIELD_UPDATE: `${prefix}.ANY_FIELD_UPDATE`,
  RESET_PASSWORDLESS_SIGN_IN: `${prefix}.RESET_PASSWORDLESS_SIGN_IN`,
};
export default AUTH;
