import { call } from "redux-saga/effects";
import { broadcastChannel } from "../../channel";
import { BroadcastMessageType } from "../../types";
import { fetchId } from "../../utils";

/* 
Triggered by Auth to indicate other tabs running flowrite to refresh the page
changing to a logged out state for all tabs
*/
function* onLogout(event: any): any {
  try {
    broadcastChannel.postMessage({
      type: BroadcastMessageType.logout,
      payload: {
        originId: fetchId(),
      },
    });
  } catch (e) {
    yield call(
      console.log,
      `broadcast-channel post message raised an exception: ${e}`
    );
  }
}

export default onLogout;
