/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from "redux";
import { appError } from "../../initial-state/errors";
import { ACTION } from "../../actions";

const appErrorReducer = (state: any = appError, action: any): Reducer => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.ERROR.APP_ERROR.SET: {
      const { type, message } = payload;
      return {
        ...state,
        status: true,
        body: { type, message, timestamp: Date.now() },
      };
    }
    case ACTION.ERROR.APP_ERROR.RESET: {
      return {
        ...appError,
      };
    }
    default:
      return state;
  }
};

export default appErrorReducer;
