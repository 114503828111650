/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */

import { takeEvery, call, all, select, put } from "typed-redux-saga";
import db from "../../services/hasura";
import SagasAppState from "../actions/sagas/app-state";
import SAGAS from "../actions/sagas/constants";
import { getToken } from "./utils/helpers";

function* updateIdentityFlag(action: any): any {
  const { identityFlag } = action.payload;
  try {
    const uid: string = yield select((state) => state.auth.uid);
    const token: string = yield getToken();

    const response = yield call(
      db.flags.new_identity_added.update,
      uid,
      token,
      identityFlag as boolean
    );
    // console.log("updateIdentityFlag", response);
    yield put(SagasAppState.extensionSettingsMessage());
  } catch (error) {
    yield call(console.log, `Error in identity flag saga, ${error}`);
  }
}

function* flagSaga(): any {
  yield all([
    takeEvery(SAGAS.FLAGS.NEW_IDENTITY_ADDED.UPDATE, updateIdentityFlag),
  ]);
}

export default flagSaga;
