import REMOTE from "./constants";

export type RemoteUseCaseRepo = {
  read(): any;
  create(): any;
  update(useCaseId: any): any;
  delete(useCaseId: any): any;
};

const UseCase: RemoteUseCaseRepo = {
  read: () => ({
    type: REMOTE.USE_CASE.READ,
    payload: {},
  }),
  create: () => ({
    type: REMOTE.USE_CASE.CREATE,
    payload: {},
  }),
  update: (useCaseId) => ({
    type: REMOTE.USE_CASE.UPDATE,
    payload: { useCaseId },
  }),
  delete: (useCaseId) => ({
    type: REMOTE.USE_CASE.DELETE,
    payload: { useCaseId },
  }),
};

export default UseCase;
