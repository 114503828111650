import COMPLETION_LENGTH from "./completion_length";
import KEYBOARD_SHORTCUTS from "./keyboard_shortcuts";
import USAGE_REASON from "./usage_reason";
import SETTINGS from "./settings";
import PERSONA from "./persona";
import EXTENSION_DISABLED_INTEGRATIONS from "./extension_disabled_integrations";
import IDENTITY from "./identity";
import TEST_MODE from "./test_mode";

const ACCOUNT_SETTINGS = {
  KEYBOARD_SHORTCUTS,
  COMPLETION_LENGTH,
  USAGE_REASON,
  PERSONA,
  EXTENSION_DISABLED_INTEGRATIONS,
  IDENTITY,
  TEST_MODE,
  ...SETTINGS,
};

export { ACCOUNT_SETTINGS };
