import { call, put } from "redux-saga/effects";
import AuthState from "../../../../actions/local/auth";
import BroadcastChannelState from "../../../../actions/sagas/broadcast-channel";

function* onSignOut(): any {
  try {
    /* data trigger on sign out */
    yield put(AuthState.Credentials.setEmpty());

    /* indicate to all browser contexts running the flowrite app to logout */
  } catch (e) {
    yield call(console.log, e);
  }
}

export default onSignOut;
