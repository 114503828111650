/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createStore, applyMiddleware } from "redux";
// import * as Sentry from "@sentry/react";
import { compose } from "recompose";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import firebase from "firebase/app";
import "firebase/auth";
// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default
import createRootReducer from "./reducers";
// eslint-disable-next-line import/no-cycle
import mySaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);
// const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const internal = process.env.REACT_APP_NODE_ENV !== "production";
const devMiddleware = internal ? [logger] : [];

const middleware = [
  sagaMiddleware,
  ...devMiddleware,
  routerMiddleware(history),
];
const enhancers = [applyMiddleware(...middleware)];
const isDev = process.env.REACT_APP_NODE_ENV === "development";
const hasWindowObject = typeof window === "object";
const composeEnhancers =
  isDev &&
  hasWindowObject &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const rrfConfig = {
  userProfile: "users",
  profileParamsToPopulate: ["use_cases:use_cases"],
  preserveOnLogout: ["users"],
  enableClaims: true,
};

const configureStore = (preloadedState: any, history: any) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(...enhancers)
  );

  return store;
};

export const store = configureStore({}, history); // provide initial state if any

sagaMiddleware.run(mySaga);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};
