import { eventChannel } from "redux-saga";
import { call, take } from "redux-saga/effects";
import { broadcastChannel } from "../channel";
import { BroadcastMessage, BroadcastMessageType } from "../types";
import { fetchId } from "../utils";
import onLogout from "./handlers/onLogout";

const subscribtion = () => {
  return eventChannel((emitter: any) => {
    broadcastChannel.onmessage = emitter;

    return () => {
      broadcastChannel.close();
    };
  });
};

/*
Subscribing to all messages received on the broadcast-channel
*/
function* broadcastChannelSubscribe(): any {
  const channel = yield call(subscribtion);

  try {
    while (true) {
      const { data: event }: { data: BroadcastMessage } = yield take(channel);

      /* tab identification */
      const currentTab = fetchId();

      switch (event.type) {
        case BroadcastMessageType.logout: {
          if (event.payload.originId !== currentTab) yield onLogout(event);
          break;
        }
        default: {
          console.warn("Unhandled broadcast message: ", event);
        }
      }
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

export default broadcastChannelSubscribe;
