import DB from "../constants";
import createHasuraRequest from "../utils";

type Shortcuts = {
  shiftLeft: boolean;
  shiftEnter: boolean;
  shiftRight: boolean;
};

export type KeyboardShortcuts = {
  id: {
    read: (token: string, shortcuts: Shortcuts) => any;
  };
};

const keyboard_shortcuts: KeyboardShortcuts = {
  id: {
    read: async (token, shortcuts) => {
      const request = createHasuraRequest(
        DB.ACCOUNT_SETTINGS.KEYBOARD_SHORTCUTS.ID.READ,
        token
      );
      const parameters = {
        shift_enter: shortcuts.shiftEnter,
        shift_left: shortcuts.shiftLeft,
        shift_right: shortcuts.shiftRight,
      };

      return await request(parameters);
    },
  },
};

export { keyboard_shortcuts };
