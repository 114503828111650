/* eslint-disable @typescript-eslint/no-explicit-any */
import SAGAS from "./constants";

export type SagasAuthStateRepo = {
  Credentials: {
    getCurrent: () => any;
    passwordlessSignUp: (email: string, fullName: string) => any;
    passwordlessSignIn: (email: string) => any;
    answerAuthChallenge: (code: any) => any;
  };
};

const AuthState: SagasAuthStateRepo = {
  Credentials: {
    getCurrent: () => ({
      type: SAGAS.AUTH.CREDENTIALS.GET_CURRENT,
      payload: {},
    }),
    passwordlessSignUp: (email, fullName) => ({
      type: SAGAS.AUTH.CREDENTIALS.PASSWORDLESS_SIGN_UP,
      payload: { email, fullName },
    }),
    passwordlessSignIn: (email) => ({
      type: SAGAS.AUTH.CREDENTIALS.PASSWORDLESS_SIGN_IN,
      payload: { email },
    }),
    answerAuthChallenge: (code) => ({
      type: SAGAS.AUTH.CREDENTIALS.ANSWER_AUTH_CHALLENGE,
      payload: { code },
    }),
  },
};

export default AuthState;
