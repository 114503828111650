import { Reducer } from "redux";
import { ACTION } from "../actions";
import generationHistory from "../initial-state/generation-history";

const generationHistoryReducer = (
  state: any = generationHistory,
  action: any
): Reducer => {
  switch (action.type) {
    case ACTION.LOCAL.GENERATION_HISTORY.UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        data: [...state.data, ...data],
      };
    }
    case ACTION.LOCAL.GENERATION_HISTORY.RESET: {
      return {
        ...state,
        data: [],
      };
    }

    default:
      return state;
  }
};

export default generationHistoryReducer;
