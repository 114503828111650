import SAGAS from "./constants";

export type SagasAppStateRepo = {
  ConfigAddDialog: {
    open(): any;
  };
  AddConfig: {
    open(): any;
  };
  extensionLogin: () => any;
  extensionLogout: () => any;
  integrationOpen: () => any;
  onboardingFinish: () => any;
  extensionSettingsMessage: () => any;
  onLogin(): any;
  returnToPrompt(): any;
  update(settings: any): any;
  updateCompletionLength(newContent: string): any;
  updateCheckListFlag(newContent: any): any;
  updateOnboardingFlags(data: { [key: string]: any }): any;
  updateExtensionInstallFlag(newContent: boolean): any;
  updateFirstUsageBumper(newContent: boolean): any;
  updateIdentityProperties(): any;
  AccountSettings: {
    update(): any;
  };
  read(): any;
  Onboarding: {
    updateReason(field: any, newContent: any): any;
    updateSurvey(data: { [key: string]: any }): any;
  };
  Feedback: {
    create(feedback: any): any;
  };
  Recipient: {
    get(): any;
  };
  checkSafety(settings: any): any;
  checkAdminRights(): any;
  identifyCallBackend: () => void;
  createVipInvite(email: any): any;
  migration: {
    trigger: () => any;
    loginTrigger: (history: any) => void;
  };
};

const AppState: SagasAppStateRepo = {
  extensionSettingsMessage: () => ({
    type: SAGAS.APP_STATE.EXTENSION_UPDATE_SETTINGS_MESSAGE,
    payload: {},
  }),

  updateCompletionLength: (newContent) => ({
    type: SAGAS.APP_STATE.COMPLETION_LENGTH.UPDATE,
    payload: { newContent },
  }),
  AccountSettings: {
    update: () => ({
      type: SAGAS.APP_STATE.ACCOUNT_SETTINGS.UPDATE,
    }),
  },
  identifyCallBackend: () => ({
    type: SAGAS.APP_STATE.IDENTIFY_CALL,
    payload: {},
  }),
  ConfigAddDialog: {
    open: () => ({
      type: SAGAS.APP_STATE.CONFIG_ADD_DIALOG.OPEN,
      payload: {},
    }),
  },
  AddConfig: {
    open: () => ({
      type: SAGAS.APP_STATE.ADD_CONFIG.OPEN,
      payload: {},
    }),
  },
  extensionLogin: () => ({
    type: SAGAS.APP_STATE.EXTENSION_LOGIN,
    payload: {},
  }),
  extensionLogout: () => ({
    type: SAGAS.APP_STATE.EXTENSION_LOGOUT,
    payload: {},
  }),
  integrationOpen: () => ({
    type: SAGAS.APP_STATE.INTEGRATION_OPEN,
    payload: {},
  }),
  onboardingFinish: () => ({
    type: SAGAS.APP_STATE.ONBOARDING_FINISHED,
    payload: {},
  }),

  onLogin: () => ({
    type: SAGAS.APP_STATE.ON_LOGIN,
    payload: {},
  }),
  returnToPrompt: () => ({
    type: SAGAS.APP_STATE.RETURN_TO_PROMPT,
    payload: {},
  }),
  update: (settings) => ({
    type: SAGAS.APP_STATE.UPDATE,
    payload: { settings },
  }),
  updateCheckListFlag: (newContent) => ({
    type: SAGAS.APP_STATE.FLAGS.UPDATE_CHECKLIST,
    payload: { newContent },
  }),
  updateOnboardingFlags: (data) => ({
    type: SAGAS.APP_STATE.FLAGS.UPDATE_ONBOARDING,
    payload: { data },
  }),
  updateExtensionInstallFlag: (newContent) => ({
    type: SAGAS.APP_STATE.FLAGS.UPDATE_EXTENSION_INSTALL,
    payload: { newContent },
  }),
  updateFirstUsageBumper: (newContent) => ({
    type: SAGAS.APP_STATE.FLAGS.UPDATE_FIRST_USAGE_BUMPER,
    payload: { newContent },
  }),
  updateIdentityProperties: () => ({
    type: SAGAS.APP_STATE.IDENTITY_PROPERTIES.UPDATE,
    payload: {},
  }),
  read: () => ({
    type: SAGAS.APP_STATE.READ,
    payload: {},
  }),
  Onboarding: {
    updateReason: (field, newContent) => ({
      type: SAGAS.APP_STATE.ONBOARDING.UPDATE_REASON,
      payload: { field, newContent },
    }),
    updateSurvey: (data) => ({
      type: SAGAS.APP_STATE.ONBOARDING.UPDATE_SURVEY,
      payload: { data },
    }),
  },
  Feedback: {
    create: (feedback) => ({
      type: SAGAS.APP_STATE.FEEDBACK.CREATE,
      payload: { feedback },
    }),
  },
  Recipient: {
    get: () => ({
      type: SAGAS.APP_STATE.RECIPIENT.GET,
      payload: {},
    }),
  },
  checkSafety: (settings) => ({
    type: SAGAS.APP_STATE.CHECK_SETTINGS_SAFETY,
    payload: { settings },
  }),
  checkAdminRights: () => ({
    type: SAGAS.APP_STATE.CHECK_ADMIN_RIGHTS,
    payload: {},
  }),
  createVipInvite: (email) => ({
    type: SAGAS.APP_STATE.VIP_INVITE,
    payload: { email },
  }),
  migration: {
    trigger: () => ({
      type: SAGAS.APP_STATE.MIGRATION.TRIGGER,
      payload: {},
    }),
    loginTrigger: (history) => ({
      type: SAGAS.APP_STATE.MIGRATION.LOGIN_TRIGGER,
      payload: { history },
    }),
  },
};

export default AppState;
