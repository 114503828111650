import USE_CASES from "./use-cases";
import USE_CASE from "./use-case";
import EVENT_STREAM from "./event-stream";
import APP_STATE from "./app-state";
import FLAGS from "./flags";
import BILLING from "./billing";
import STORAGE from "./local-storage";
import AUTH from "./auth";
import BROADCAST_CHANNEL from "./broadcast-channel";
import GENERATION_HISTORY from "./generation-history";

const SAGAS = {
  BROADCAST_CHANNEL,
  USE_CASES,
  USE_CASE,
  EVENT_STREAM,
  APP_STATE,
  FLAGS,
  STORAGE,
  BILLING,
  AUTH,
  GENERATION_HISTORY,
};

export default SAGAS;
