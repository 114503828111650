import { makeRequest } from "../../../helpers";
import apiRoutes from "../../constants";

export type Customer = {
  create: (
    uid: string,
    name: string,
    token: string,
    billingEmail: string,
    price_id: string,
    indian_user: boolean,
    extended_trial: boolean
  ) => any;
  read: (
    uid: string,
    stripe_customer_id: string,
    subscription_id: string,
    token: string
  ) => any;
  portalSession: {
    create: (uid: string, stripe_customer_id: string, token: string) => any;
  };
  checkoutSession: {
    create: (
      uid: string,
      stripe_customer_id: string,
      price_id: string,
      return_url: string,
      token: string
    ) => any;
  };
  plans: {
    read: (stripe_customer_id: string, token: string) => any;
  };
  plan: {
    read: (
      uid: string,
      stripe_customer_id: string,
      price_id: string,
      token: string
    ) => any;
  };
  stripeId: {
    update: (
      uid: string,
      stripe_customer_id: string,
      session_id: string,
      token: string
    ) => any;
  };
  checkSubscription: (
    uid: string,
    stripe_customer_id: string,
    token: string
  ) => any;
  createScheduledSubscription: (
    uid: string,
    stripe_customer_id: string,
    price_id: string,
    token: string
    ) => any;
};

const customer: Customer = {
  create: async (
    uid,
    name,
    token,
    billingEmail,
    price_id,
    indian_user,
    extended_trial
  ) => {
    const parameters = {
      email: billingEmail,
      name,
      uid,
      price_id,
      indian_user,
      extended_trial,
    };
    return makeRequest(
      apiRoutes.billing.customer.create_customer,
      parameters
    ).authenticated(token);
  },

  read: async (uid, stripe_customer_id, subscription_id, token) => {
    const parameters = {
      stripe_customer_id,
      subscription_id,
    };
    return makeRequest(
      apiRoutes.billing.customer.read_customer,
      parameters
    ).authenticated(token);
  },

  portalSession: {
    create: async (uid, stripe_customer_id, token) => {
      const parameters = {
        uid,
        stripe_customer_id,
      };
      return makeRequest(
        apiRoutes.billing.customer.create_customer_portal_session,
        parameters
      ).authenticated(token);
    },
  },

  checkoutSession: {
    create: async (uid, stripe_customer_id, price_id, return_url, token) => {
      const parameters = {
        uid,
        stripe_customer_id,
        price_id,
        return_url,
      };
      return makeRequest(
        apiRoutes.billing.customer.create_checkout_session,
        parameters
      ).authenticated(token);
    },
  },

  createScheduledSubscription: async (uid, stripe_customer_id, price_id, token) => {
    const parameters = {
      uid,
      stripe_customer_id,
      price_id,
    };
    return makeRequest(
      apiRoutes.billing.customer.create_scheduled_subscription,
      parameters
    ).authenticated(token);
  },

  plans: {
    read: async (stripe_customer_id, token) => {
      const parameters = {
        stripe_customer_id,
      };
      return makeRequest(
        apiRoutes.billing.customer.read_plans,
        parameters
      ).authenticated(token);
    },
  },

  plan: {
    read: async (uid, stripe_customer_id, price_id, token) => {
      const parameters = {
        uid,
        price_id,
        stripe_customer_id,
      };
      return makeRequest(
        apiRoutes.billing.customer.read_plan,
        parameters
      ).authenticated(token);
    },
  },

  stripeId: {
    update: async (uid, stripe_customer_id, session_id, token) => {
      const parameters = {
        uid,
        stripe_customer_id,
        session_id,
      };
      return makeRequest(
        apiRoutes.billing.customer.subscription_success,
        parameters
      ).authenticated(token);
    },
  },
  checkSubscription: async (uid, stripe_customer_id, token) => {
    const parameters = {
      stripe_customer_id,
    };
    return makeRequest(
        apiRoutes.billing.customer.check_subscription,
        parameters
    ).authenticated(token);
  },
};

export { customer };
