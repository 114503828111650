const NEW_IDENTITY_ADDED = {
  UPDATE: `
    mutation MyMutation(
        $uid: String!,
        $new_identity_added: Boolean,
      ) {
        update_db_flags(where: {id: {_eq: $uid}}, 
          _set: {
            new_identity_added: $new_identity_added, 
        }) { 
          affected_rows
          returning {
            id
            new_identity_added
          }
        }
      }
    `,
};

export default NEW_IDENTITY_ADDED;
