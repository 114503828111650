/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from "redux-saga/effects";
import { ACTION } from "../../../../actions";
import {
  flowriteModeDisable,
  output,
  prepareSig,
  resetPromptWarningSecondBody,
  setGenId,
  setSeedId,
} from "../../../utils/helpers";

function* onEnd(source: any, data: any, bodyId: number) {
  try {
    // source.close();
    const { settings } = yield select((state) => state.appState);
    const signature = yield call(prepareSig, settings);
    yield output(signature, false, bodyId);
    if (settings.flowrittenTag !== "") {
      yield output(`\n${settings.flowrittenTag}`, false, bodyId);
    }
    // passing this for the pill
    yield put({
      type: ACTION.SAGAS.EVENT_STREAM.CONCAT,
      payload: { newString: "", pill: true, bodyId },
    });

    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );
    if (!active_stream_1 && !active_stream_2 && !active_stream_3) {
      yield flowriteModeDisable();
      yield resetPromptWarningSecondBody();
      // save frontend data related to the completion
    }
    yield setSeedId(data);
    yield setGenId(data);
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onEnd;
