/* eslint-disable @typescript-eslint/no-unsafe-return */

import { EXTENSION_ID } from "../constants/common";

type Arg = string | number | null | Record<string, unknown> | any[];

type ArgObj = { development: Arg; staging: Arg; production: Arg };

const returnByEnv = ({ development, staging, production }: ArgObj): Arg => {
  switch (process.env.REACT_APP_APP_ENV) {
    case "development": {
      return development;
    }
    case "staging": {
      return staging;
    }
    case "production": {
      return production;
    }
    default:
      return development;
  }
};

export const getExtensionId = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? EXTENSION_ID.PRODUCTION
    : EXTENSION_ID.DEV_STAGING;
};

export const sendChromeMessage = (
  message: any,
  callback?: (response: any) => void
) => {
  if (typeof chrome !== "undefined" && chrome !== null) {
    if (typeof chrome.runtime !== "undefined" && chrome.runtime !== null) {
      chrome.runtime.sendMessage(getExtensionId(), message, callback);
    }
  }
};

export default returnByEnv;
