/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-cycle */
import { all } from "redux-saga/effects";

import eventStreamSaga from "./event-stream";
import appStateSaga from "./app-state";
import useCaseSaga from "./use-case";
import useCasesSaga from "./use-cases";
import flagSaga from "./flags";
import billingSaga from "./billing";
import localStorageSaga from "./local-storage";
import authListener from "./auth/auth-listener";
import authSaga from "./auth";
import broadcastChannelSaga from "./broadcast-channel";
import generationHistorySaga from "./generation-history";

function* mySaga() {
  yield all([
    eventStreamSaga(),
    appStateSaga(),
    useCasesSaga(),
    useCaseSaga(),
    flagSaga(),
    localStorageSaga(),
    billingSaga(),
    authListener(),
    authSaga(),
    broadcastChannelSaga(),
    generationHistorySaga(),
  ]);
}

export default mySaga;
