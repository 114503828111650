import { Hub } from "aws-amplify";
import { eventChannel } from "redux-saga";

/* 
Hub emits event for auth state change
Here we pass it to th event channel
*/
const authSubscription = () => {
  const subscriber = (emitter: any) => {
    Hub.listen("auth", emitter);
    return () => {
      Hub.remove("auth", emitter);
    };
  };
  return eventChannel(subscriber);
};

export default authSubscription;
