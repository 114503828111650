import { delay, put } from "redux-saga/effects";
import AuthState from "../../../../../../actions/local/auth";
import { Exception } from "./types";

function* onUnauthorizedException(exception: Exception) {
  /* exception raised on sendCustomChallengeAnswer */

  if ("message" in exception) {
    if (
      exception.message.toLowerCase().includes("incorrect username or password")
    ) {
      /* 3rd wrong OTP attempt */
      yield put(AuthState.anyFieldUpdate("code_attempt", 3));
      yield put(AuthState.anyFieldUpdate("incorrect_code", true));
    } else if (
      exception.message.toLowerCase().includes("invalid session for the user")
    ) {
      /* expired code: session is invalidated */
      yield put(AuthState.anyFieldUpdate("expired_code", true));
    }

    /* reset strategy */
    yield delay(2000);
    yield put(AuthState.Credentials.setEmpty());
  }
}

export default onUnauthorizedException;
