/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer, combineReducers } from "redux";
import {
  recipientDescription,
  recipientName,
  intention,
} from "../../initial-state/errors";
import { ACTION } from "../../actions";
import { ComponentName } from "../../actions/errors/component-error";

/*
 Generic template for individual component error reducer, 
 combined using the utility function.
*/

function createComponentErrorReducer(
  COMPONENT_NAME: ComponentName,
  initialState: any
) {
  return function componentErrorReducer(
    state: any = initialState,
    action: any
  ): Reducer {
    const { type, payload } = action;
    switch (type) {
      case ACTION.ERROR.COMPONENT_ERROR[COMPONENT_NAME].ANY_FIELD_ENABLE: {
        return {
          ...state,
          [payload.field]: true,
        };
      }
      case ACTION.ERROR.COMPONENT_ERROR[COMPONENT_NAME].ANY_FIELD_DISABLE: {
        return {
          ...state,
          [payload.field]: false,
        };
      }
      default:
        return state;
    }
  };
}

const componentErrorsReducer = combineReducers<any>({
  recipientName: createComponentErrorReducer("RECIPIENT_NAME", recipientName),
  recipientDescription: createComponentErrorReducer(
    "RECIPIENT_DESCRIPTION",
    recipientDescription
  ),
  intention: createComponentErrorReducer("INTENTION", intention),
});

export default componentErrorsReducer;
