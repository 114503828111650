import DB from "../constants";
import createHasuraRequest from "../utils";

export type IterationInstructions = {
  read: (token: string) => any;
};

const iteration_instructions: IterationInstructions = {
  read: async (token) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.ITERATION_INSTRUCTIONS.READ,
      token
    );
    const parameters = {};

    return await request(parameters);
  },
};

export { iteration_instructions };
