/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { call, put, select } from "redux-saga/effects";
import { ACTION } from "../../../../actions";
import {
  flowriteModeDisable,
  streamError,
  sendAppError,
  loadingIndicatorDisable,
  feedbackModeDisable,
  closeStream,
  loadingFieldDisable,
} from "../../../utils/helpers";

function* onCustomStop(source: any, data: any, bodyId: number): any {
  try {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: `active_stream_${bodyId}`, newContent: false },
    });

    yield streamError(); // these need to go to analytics or datadog or a logging service
    yield sendAppError("Stream Error", "There was an error while streaming.");
    yield loadingFieldDisable(bodyId as 1 | 2 | 3);

    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );
    if (!active_stream_1 && !active_stream_2 && !active_stream_3) {
      yield closeStream(source);
      yield flowriteModeDisable();
      yield loadingIndicatorDisable();
      yield feedbackModeDisable();
    }
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onCustomStop;
