import { flags, Flags } from "./flags";
import { template, Template } from "./template";
import { templates, Templates } from "./templates";
import { account_settings, AccountSettings } from "./account_settings";
import { generation, Generation } from "./generation";
import { billing, Billing } from "./billing";
import { invite_codes, invideCode } from "./invite_codes";
import { ob_survey, ObSurvey } from "./ob_survey";
import { stream_options, StreamOptions } from "./stream_options";

export type DBRequests = {
  flags: Flags;
  template: Template;
  templates: Templates;
  account_settings: AccountSettings;
  generation: Generation;
  billing: Billing;
  invite_codes: invideCode;
  ob_survey: ObSurvey;
  stream_options: StreamOptions;
};

const db = {
  flags,
  template,
  templates,
  account_settings,
  stream_options,
  generation,
  billing,
  invite_codes,
  ob_survey,
};

export default db;
