/* eslint-disable @typescript-eslint/no-explicit-any */
// import { oneGeneration } from "../../initial-state/local-storage";
import LOCAL from "./constants";

export type LocalCompliationStorageRepo = {
  // update(generations: oneGeneration[]): any;
  load(): any;
};

const LocalStorageState: LocalCompliationStorageRepo = {
  // update: (generations: oneGeneration[]) => ({
  //   type: LOCAL.STORAGE.UPDATE,
  //   payload: { generations },
  // }),
  load: () => ({
    type: LOCAL.STORAGE.LOAD,
    payload: {},
  }),
};

export default LocalStorageState;
