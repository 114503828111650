/* eslint-disable @typescript-eslint/no-explicit-any */
import LOCAL from "./constants";

export type LocalAppStateRepo = {
  DarkMode: {
    enable(): any;
    disable(): any;
  };
  KeyboardShortcut: {
    enable(field: string): any;
    disable(field: string): any;
  };
  BooleanField: {
    enable(field: string): any;
    disable(field: string): any;
  };
  FlowritingMode: {
    enable(): any;
    disable(): any;
  };
  PromptCharacterLimit: {
    enable(): any;
    disable(): any;
  };
  PreviousSecondBody: {
    update(): any;
    clear(): any;
  };
  ReplyMode: {
    enable(): any;
    disable(): any;
  };
  ConfigEditDialog: {
    open(
      useCaseId: string,
      id: string,
      targetState: boolean,
      useCase: any
    ): any;
    close(): any;
  };
  ConfigAddDialog: {
    open(): any;
    close(): any;
  };
  PromptState: {
    forward(): any;
    backward(): any;
  };
  ConfigEdit: {
    update(id: string, targetState: boolean): any;
  };
  Visibility: {
    toggle(id: string, targetState: boolean): any;
  };
  ActiveUseCase: {
    update(id: any): any;
  };
  EventStream: {
    Request: {
      create(state: any, source: any): any;
      send(appState: any, userId: string): any;
    };
    start(source: any): any;
    sseRequest(): any;
    error(): any;
  };
  Completion: {
    clear(): any;
    update(): any;
    concat(newString: string, pill: boolean, bodyId: number): any;
  };
  AnyField: {
    update(field: string, newContent: string | number | null): any;
    sliderUpdate(field: string, newContent: number): any;
  };
  WarnedList: {
    update(field: string, newArray: any[]): any;
    clear(field: string): any;
  };
  Account: {
    update(settings: any): any;
    read(): any;
  };
  AccountField: {
    update(field: string, newContent: any): any;
    sliderUpdate(field: string, newContent: number): any;
  };
  BillingField: {
    update(field: string, newContent: any): any;
  };
  FlagsField: {
    update(field: string, newContent: any): any;
  };
  IdentityProperties: {
    update(identityProperties: any): any;
    updateField(field: string, newContent: string): any;
  };
  ExtensionIntegration: {
    enable(value: string): any;
    disable(value: string): any;
  };
  Bodies: {
    clear(state: any, source: any): any;
  };
  SecondBody: {
    clear(): any;
  };
  Content: {
    update(state: any, source: any): any;
  };
};

const AppState: LocalAppStateRepo = {
  DarkMode: {
    enable: () => ({
      type: LOCAL.APP_STATE.DARK_MODE.ENABLE,
      payload: {},
    }),
    disable: () => ({
      type: LOCAL.APP_STATE.DARK_MODE.DISABLE,
      payload: {},
    }),
  },
  BooleanField: {
    enable: (field) => ({
      type: LOCAL.APP_STATE.BOOLEAN_FIELD.ENABLE,
      payload: { field },
    }),
    disable: (field) => ({
      type: LOCAL.APP_STATE.BOOLEAN_FIELD.DISABLE,
      payload: { field },
    }),
  },
  PromptState: {
    forward: () => ({
      type: LOCAL.APP_STATE.PROMPT_STATE.FORWARD,
      payload: {},
    }),
    backward: () => ({
      type: LOCAL.APP_STATE.PROMPT_STATE.BACKWARD,
      payload: {},
    }),
  },
  PromptCharacterLimit: {
    enable: () => ({
      type: LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.ENABLE,
      payload: {},
    }),
    disable: () => ({
      type: LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.DISABLE,
      payload: {},
    }),
  },
  KeyboardShortcut: {
    enable: (field) => ({
      type: LOCAL.APP_STATE.KEYBOARD_SHORTCUT.ENABLE,
      payload: { field },
    }),
    disable: (field) => ({
      type: LOCAL.APP_STATE.KEYBOARD_SHORTCUT.DISABLE,
      payload: { field },
    }),
  },
  FlowritingMode: {
    // is state really needed as props? check
    enable: () => ({
      type: LOCAL.APP_STATE.FLOWRITING_MODE.ENABLE,
      payload: {},
    }),
    disable: () => ({
      type: LOCAL.APP_STATE.FLOWRITING_MODE.DISABLE,
      payload: {},
    }),
  },
  PreviousSecondBody: {
    // is state really needed as props? check
    update: () => ({
      type: LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.UPDATE,
      payload: {},
    }),
    clear: () => ({
      type: LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.CLEAR,
      payload: {},
    }),
  },
  ConfigAddDialog: {
    open: () => ({
      type: LOCAL.APP_STATE.CONFIG_ADD_DIALOG.OPEN,
      payload: {},
    }),
    close: () => ({
      type: LOCAL.APP_STATE.CONFIG_ADD_DIALOG.CLOSE,
      payload: {},
    }),
  },
  ConfigEditDialog: {
    open: (useCaseId, id, targetState, useCase) => ({
      type: LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.OPEN,
      payload: { useCaseId, id, targetState, useCase },
    }),
    close: () => ({
      type: LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.CLOSE,
      payload: {},
    }),
  },
  ReplyMode: {
    enable: () => ({
      type: LOCAL.APP_STATE.REPLY_MODE.ENABLE,
      payload: {},
    }),
    disable: () => ({
      type: LOCAL.APP_STATE.REPLY_MODE.DISABLE,
      payload: {},
    }),
  },
  ConfigEdit: {
    update: (id, targetState) => ({
      type: LOCAL.APP_STATE.CONFIG_EDIT.UPDATE,
      payload: { id, targetState },
    }),
  },

  Visibility: {
    toggle: (id, targetState) => ({
      type: LOCAL.APP_STATE.VISIBILITY.TOGGLE,
      payload: { id, targetState },
    }),
  },
  ActiveUseCase: {
    update: (id) => ({
      type: LOCAL.APP_STATE.ACTIVE_USE_CASE.UPDATE,
      payload: { id },
    }),
  },

  EventStream: {
    Request: {
      create: (state, source) => ({
        type: LOCAL.APP_STATE.EVENT_STREAM.REQUEST.CREATE,
        payload: { state, source },
      }),
      send: (appState, userId) => ({
        type: LOCAL.APP_STATE.EVENT_STREAM.REQUEST.SEND,
        payload: { appState, userId },
      }),
    },
    start: (source) => ({
      type: LOCAL.APP_STATE.EVENT_STREAM.START,
      payload: { source },
    }),
    sseRequest: () => ({
      type: LOCAL.APP_STATE.EVENT_STREAM.SSE_REQUEST,
      payload: {},
    }),
    error: () => ({
      type: LOCAL.APP_STATE.EVENT_STREAM.ERROR,
      payload: {},
    }),
  },

  Completion: {
    clear: () => ({
      type: LOCAL.APP_STATE.COMPLETION.CLEAR,
      payload: {},
    }),
    update: () => ({
      type: LOCAL.APP_STATE.COMPLETION.UPDATE,
      payload: {},
    }),
    concat: (newString, pill, bodyId) => ({
      type: LOCAL.APP_STATE.COMPLETION.CONCAT,
      payload: { newString, pill, bodyId },
    }),
  },

  AnyField: {
    update: (field, newContent) => ({
      type: LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field, newContent },
    }),
    sliderUpdate: (field, newContent) => ({
      type: LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field, newContent },
    }),
  },

  WarnedList: {
    update: (field, newArray) => ({
      type: LOCAL.APP_STATE.WARNED_LIST.UPDATE,
      payload: { field, newArray },
    }),
    clear: (field) => ({
      type: LOCAL.APP_STATE.WARNED_LIST.CLEAR,
      payload: { field },
    }),
  },

  Account: {
    update: (settings) => ({
      type: LOCAL.APP_STATE.ACCOUNT.UPDATE,
      payload: { settings },
    }),
    read: () => ({
      type: LOCAL.APP_STATE.ACCOUNT.READ,
      payload: {},
    }),
  },

  AccountField: {
    update: (field, newContent) => ({
      type: LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE,
      payload: { field, newContent },
    }),
    sliderUpdate: (field, newContent) => ({
      type: LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE,
      payload: { field, newContent },
    }),
  },
  BillingField: {
    update: (field, newContent) => ({
      type: LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
      payload: { field, newContent },
    }),
  },
  FlagsField: {
    update: (field, newContent) => ({
      type: LOCAL.APP_STATE.FLAGS.UPDATE,
      payload: { field, newContent },
    }),
  },

  IdentityProperties: {
    update: (identityProperties) => ({
      type: LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE,
      payload: { identityProperties },
    }),
    updateField: (field, newContent) => ({
      type: LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE_FIELD,
      payload: { field, newContent },
    }),
  },

  ExtensionIntegration: {
    enable: (value: string) => ({
      type: LOCAL.APP_STATE.EXTENSION_INTEGRATION.ENABLE,
      payload: { value },
    }),
    disable: (value: string) => ({
      type: LOCAL.APP_STATE.EXTENSION_INTEGRATION.DISABLE,
      payload: { value },
    }),
  },

  // nested mutations - "lenses"
  Bodies: {
    clear: (state, source) => ({
      type: LOCAL.APP_STATE.BODIES.CLEAR,
      payload: { state, source },
    }),
  },
  SecondBody: {
    clear: () => ({
      type: LOCAL.APP_STATE.SECOND_BODY.CLEAR,
      payload: {},
    }),
  },
  Content: {
    update: (state, source) => ({
      type: LOCAL.APP_STATE.CONTENT.UPDATE,
      payload: { state, source },
    }),
  },
};

export default AppState;
