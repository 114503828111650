/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-nested-ternary */

import {
  take,
  actionChannel,
  delay,
  takeEvery,
  put,
  select,
  call,
} from "redux-saga/effects";
import { buffers } from "redux-saga";
import concatInterrupt from "./concat-interrupt";
import { ACTION } from "../../../actions";
import {
  completionConcat,
  flowriteModeDisable,
  loadingFieldDisable,
  loadingIndicatorDisable,
} from "../../utils/helpers";

function* watchConcats(): any {
  const buffer: any = buffers.expanding();
  const requestChan = yield actionChannel(
    ACTION.SAGAS.EVENT_STREAM.CONCAT,
    buffer
  );
  while (true) {
    const { payload } = yield take(requestChan);
    const interrupt: boolean = yield select(
      (state: any) => state.appState.interrupt
    );

    if (interrupt) {
      yield concatInterrupt(requestChan);
    }

    // concat to the "main" store
    // yield call(console.log, `BODYID ${bodyId}`);
    // yield call(console.log, `BODYID PAYLOAD ${payload.bodyId}`);
    yield completionConcat(payload.newString, payload.bodyId);

    // wait time between characters 8ms
    // buys us time to have sentences arrive from backend
    // resulting in the perceived continuous flow
    yield delay(7);

    yield takeEvery(
      ACTION.SAGAS.EVENT_STREAM.INTERRUPT,
      concatInterrupt,
      requestChan
    );

    // The pill acts as an indicator to verify for an end event
    // The buffer check allows us to validate that the channel is infact empty
    // After which we dispatch the event to disable the loading state

    // TODO: CHECK HERE THAT OTHER STREAMS HAVE FINISHED BEFORE
    // DISABLING

    if (payload.pill) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
        payload: {
          field: `active_stream_${payload.bodyId}`,
          newContent: false,
        },
      });
      yield loadingFieldDisable(payload.bodyId as 1 | 2 | 3);
      yield put({
        type: ACTION.SAGAS.STORAGE.UPDATE,
        payload: { bodyId: payload.bodyId },
      });
    }
    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );

    if (
      buffer.isEmpty() &&
      payload.pill &&
      !active_stream_1 &&
      !active_stream_2 &&
      !active_stream_3
    ) {
      yield loadingIndicatorDisable();
      yield flowriteModeDisable();
      // yield call(console.log, "finished");
    }
  }
}

export default watchConcats;
