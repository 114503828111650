const GENERATION_LIMIT_EXCEEDED = {
  UPDATE: `
          mutation updateGenerationLimitExceededFlag($uid: String!, $generation_limit_exceeded: Boolean) {
              update_db_flags(where: {id: {_eq: $uid}}, _set: {generation_limit_exceeded: $generation_limit_exceeded}) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
};

export default GENERATION_LIMIT_EXCEEDED;
