const VIP_INVITE = {
  UPDATE: `
    mutation MyMutation($uid: String!, $count: Int) {
        update_db_flags(where: {id: {_eq: $uid}}, _set: {vip_invite_count: $count}) {
          affected_rows
          returning {
            id
          }
        }
      }
    `,
};

export default VIP_INVITE;
