import React from "react";
import { Circle } from "../icons";

const ThemedSuspense = (): any => (
  <div className="flex items-center justify-center w-screen h-screen bg-gray-50 dark:bg-gray-900">
    <Circle className="w-10 h-10 text-gray-300 animate-spin dark:text-gray-700" />
  </div>
);

export default ThemedSuspense;
