const GALLERY_TEMPLATES = {
  READ: `
    query getTemplatesQuery {
        templates_gallery_templates {
          id
          template_id
          emoji
          intention
          name
          description
          recipient_type
          created_date
          modified_date
          template_type
          template_group
        }
      }
    `,
};

export default GALLERY_TEMPLATES;
