import DB from "../constants";
import createHasuraRequest from "../utils";

export type FeedbackCount = {
  update: (uid: string, token: string, feedback_count: number) => any;
};

const feedback_count: FeedbackCount = {
  update: async (uid, token, feedback_count) => {
    const request = createHasuraRequest(DB.FLAGS.FEEDBACK_COUNT.UPDATE, token);
    const parameters = {
      uid,
      feedback_count,
    };

    return await request(parameters);
  },
};

export { feedback_count };
