import { call, put } from "redux-saga/effects";
import AuthState from "../../../actions/local/auth";
import { ExtendedCognitoUser } from "./types";

const fetchAttributes = (user: ExtendedCognitoUser) => ({
  displayName: user.attributes.name,
  email: user.attributes.email,
  uid: user.attributes.sub,
  accessToken: user.signInUserSession.accessToken.jwtToken,
  idToken: user.signInUserSession.idToken.jwtToken,
  refreshToken: user.signInUserSession.refreshToken.token,
  cognitoUsername: user.username,
});

function* setCurrentUser(user: ExtendedCognitoUser) {
  try {
    const {
      email,
      uid,
      displayName,
      accessToken,
      idToken,
      refreshToken,
      cognitoUsername,
    } = yield call(fetchAttributes, user);

    yield put(
      AuthState.Credentials.set(
        email,
        displayName,
        uid,
        cognitoUsername,
        idToken,
        accessToken,
        refreshToken,
        user
      )
    );
  } catch (e) {
    yield call(console.log, e);
  }
}

/* random password generator */
function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  /* constant to fulfill password policy requirements */
  return Array.from(randomValues).map(intToHex).join("") + "A%1a";
}

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0");
}

export { fetchAttributes, setCurrentUser, getRandomString };
