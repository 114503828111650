import DB from "../constants";
import createHasuraRequest from "../utils";

export type ExtensionIntegrations = {
  update: (uid: string, token: string, disabled_sites: any) => any;
};

const extension_disabled_integrations: ExtensionIntegrations = {
  update: async (uid, token, disabled_sites) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.EXTENSION_DISABLED_INTEGRATIONS.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      extension_disabled_integrations: `{ ${disabled_sites} }`,
    };

    return await request(parameters);
  },
};

export { extension_disabled_integrations };
