/* eslint-disable @typescript-eslint/no-unsafe-return */
import ky from "ky";

type Arg = string | number | Record<string, unknown> | any[];
type ArgObj = { development: Arg; staging: Arg; production: Arg };

function getUrl() {
  return process.env.REACT_APP_HASURA_URL;
}

/* http requests to hasura */
async function hasuraRequest(json: any, token: string) {
  const response = await ky
    .post(getUrl() as string, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      json,
      timeout: 30000,
      retry: {
        limit: 2,
        statusCodes: [408],
      },
    })
    .json();
  return response;
}

/* wrapper function for http requests to hasura, returns function reference with bindings to the query type [Gql querystring] */
function createHasuraRequest(query: string, token: string): any {
  if (!token) {
    throw new Error(`Incorrect token passed to hasura ${token}`);
  }

  async function requestWrapper(parameters: any) {
    const json = parameters
      ? {
          query,
          variables: parameters,
        }
      : {
          query,
        };
    return await hasuraRequest(json, token);
  }

  return requestWrapper;
}

export default createHasuraRequest;
export { hasuraRequest };
