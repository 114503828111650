export type FlowriteApp = "flowrite-web-app";

export type BroadcastMessage = {
  type: "Logout";
  payload: any;
};

export enum BroadcastMessageType {
  logout = "Logout",
}
