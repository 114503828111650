const USE_CASE = {
  CREATE: "ACTION.LOCAL.USE_CASE.CREATE",
  UPDATE: "ACTION.LOCAL.USE_CASE.UPDATE",
  DELETE: "ACTION.LOCAL.USE_CASE.DELETE",
  ANY_FIELD: {
    UPDATE: "ACTION.LOCAL.USE_CASE.ANY_FIELD.UPDATE",
  },
};

export default USE_CASE;
