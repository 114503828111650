/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, select } from "redux-saga/effects";
import { ACTION } from "../../actions";
import sendEventStreamRequest from "./send-event-stream-request";

function* watchAndValidateRequest(action: any) {
  /*
  More local validations can be included here
   - check recipient
  */
  try {
    const { recipient } = yield select((state) => state.appState);
    if (recipient !== undefined || recipient !== null) {
      yield sendEventStreamRequest(action);
    } else {
      throw new Error("ERROR_EMPTY_RECIPIENT");
    }
  } catch (error) {
    if (error.message === "ERROR_EMPTY_RECIPIENT") {
      yield put({
        type: ACTION.ERROR.COMPONENT_ERROR.RECIPIENT_NAME.ANY_FIELD_ENABLE,
        payload: {
          field: "isEmpty",
        },
      });
    }
  }
}

export default watchAndValidateRequest;
