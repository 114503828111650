/* eslint-disable @typescript-eslint/no-explicit-any */
import SAGAS from "./constants";

export type SagasBroadcastChannelRepo = {
  postMessage: {
    logout: () => any;
  };
};

const BroadcastChannelState: SagasBroadcastChannelRepo = {
  postMessage: {
    logout: () => ({
      type: SAGAS.BROADCAST_CHANNEL.POST_MESSAGE.LOGOUT,
      payload: {},
    }),
  },
};

export default BroadcastChannelState;
