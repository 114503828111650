import DB from "../constants";
import createHasuraRequest from "../utils";

export type CheckList = {
  update: (uid: string, token: string, checkList: Array<string> | null) => any;
};

const check_list: CheckList = {
  update: async (uid, token, checkList) => {
    const request = createHasuraRequest(DB.FLAGS.CHECK_LIST.UPDATE, token);
    const parameters =
      checkList !== null
        ? {
            uid,
            check_list: `{ ${checkList} }`,
          }
        : { uid, check_list: null };

    return await request(parameters);
  },
};

export { check_list };
