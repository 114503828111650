import DB from "../constants";
import createHasuraRequest from "../utils";

export type UserTemplates = {
  read: (uid: string, token: string) => any;
};

const user_templates: UserTemplates = {
  read: async (uid, token) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.USER_TEMPLATES.READ,
      token
    );
    const parameters = {
      uid,
    };

    return await request(parameters);
  },
};

export { user_templates };
