/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { call } from "redux-saga/effects";
import {
  flowriteModeDisable,
  loadingIndicatorDisable,
  feedbackModeDisable,
  streamError,
  sendAppError,
  closeStream,
  allLoadingFieldsDisable,
} from "../../utils/helpers";

function* errorConsumer(source: any): any {
  try {
    // yield call(console.log, source);
    yield closeStream(source);
    yield streamError(); // these need to go to analytics or datadog or a logging service
    yield sendAppError("Stream Error", null);
    yield flowriteModeDisable();
    yield feedbackModeDisable();
    yield loadingIndicatorDisable();
    yield allLoadingFieldsDisable();
  } catch (error) {
    yield call(console.log, error);
  }
}

export default errorConsumer;
