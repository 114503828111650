import SAGAS from "./constants";

export type SagasGenerationHistoryRepo = {
  get: (offset?: number) => any;
};

const SagasFlagsState: SagasGenerationHistoryRepo = {
  get: (offset) => ({
    type: SAGAS.GENERATION_HISTORY.GET,
    payload: { offset },
  }),
};

export default SagasFlagsState;
