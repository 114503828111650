const ONBOARDING = {
  UPDATE: `
  mutation MyMutation(
      $uid: String!,
      $onboarding_mode: Boolean,
      $onboarding_step: Int,
      $steps_completed: Int,
      $onboarding_step_text: String,
      $step_completed_text: String,
    ) {
      update_db_flags(where: {id: {_eq: $uid}}, 
        _set: {
        onboarding_mode: $onboarding_mode, 
        onboarding_step: $onboarding_step, 
        steps_completed: $steps_completed,
        onboarding_step_text: $onboarding_step_text,
        step_completed_text: $step_completed_text,
      }) { 
        affected_rows
        returning {
          id
          onboarding_mode
          onboarding_step
          steps_completed
          onboarding_step_text
          step_completed_text
        }
      }
    }
  `,
};

export default ONBOARDING;
