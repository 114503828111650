/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from "redux-saga/effects";
import { ACTION } from "../../../../actions";
import {
  flowriteModeDisable,
  loadingIndicatorDisable,
  output,
  prepareSig,
  resetPromptWarningSecondBody,
  setGenId,
  setSeedId,
} from "../../../utils/helpers";

function* onSignature(source: any, data: any, bodyId: number): any {
  try {
    // CLOSE THE SOURCE ONLY AFTER ALL ARE CLOSED
    // source.close();

    // fetch the right sig from state
    const { settings } = yield select((state) => state.appState);
    const signature = yield call(prepareSig, settings);
    yield output(signature, false, bodyId);
    if (settings.flowrittenTag !== "") {
      yield output(`\n${settings.flowrittenTag}`, false, bodyId);
    }

    yield put({
      type: ACTION.SAGAS.EVENT_STREAM.CONCAT,
      payload: { newString: "", pill: true, bodyId },
    });

    // TODO:
    // THESE MIGHT NOT WORK TO OUR ADVANTAGE NOW THAT WE MIGHT HAVE OTHER STREAMS CONTINUING
    // EVEN IF ONE FINISHES
    // yield put({
    //   type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    //   payload: { field: `active_stream_${bodyId}`, newContent: false },
    // });

    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );
    if (!active_stream_1 && !active_stream_2 && !active_stream_3) {
      yield flowriteModeDisable();
      yield resetPromptWarningSecondBody();
    }

    // TODO:
    // CHECK WHAT TO DO WITH THESE NOW THAT WE HAVE THREE BODIES
    yield setSeedId(data);
    yield setGenId(data);
  } catch (error) {
    yield call(console.log, error);
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: `active_stream_${bodyId}`, newContent: false },
    });

    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );
    if (!active_stream_1 && !active_stream_2 && !active_stream_3) {
      yield flowriteModeDisable();
      yield loadingIndicatorDisable();
    }
  }
}

export default onSignature;
