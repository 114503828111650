import { put, takeEvery, call, all, select } from "typed-redux-saga";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import ky from "ky";
import { ACTION } from "../actions";
import { runMigration, getPriceId } from "../reducers/utils";
import db from "../../services/hasura";
import { MigratorFlag } from "../initial-state/app-state";
import {
  createAccountSettings,
  dbMigrationState,
  doesUserExist,
  getToken,
} from "./utils/helpers";
import {
  cleanCompanyDescription,
  cleanCompanyName,
  cleanRole,
} from "./utils/identities";
import apiRequests from "../../services/api/routes";
import { getExtensionId } from "../../utils/env-respective-return";
import logger from "../../logger";
import SagasAppState from "../actions/sagas/app-state";

const getNames = (auth: any) => {
  const { displayName } = auth;
  const names = displayName !== "" ? displayName.split(" ") : [];
  const firstName = names.length > 0 ? names[0] : "";
  const lastName = names.length > 1 ? names.slice(1).join(" ") : "";
  return { firstName, lastName };
};

const getReqRoute = () => {
  if (process.env.REACT_APP_FB_PROJECT_ID === "app-staging-mateusz") {
    return "req_staging_mateusz";
  }
  if (process.env.REACT_APP_FB_PROJECT_ID === "app-development-mateusz") {
    return "req_development_mateusz";
  }
  if (process.env.REACT_APP_FB_PROJECT_ID === "app-staging-rohit") {
    return "req_staging_rohit";
  }
  if (process.env.REACT_APP_FB_PROJECT_ID === "app-development-rohit") {
    return "req_development_rohit";
  }
  return `req_${process.env.REACT_APP_NODE_ENV}`;
};

enum MigratorResponse {
  USER_NOT_IN_FIREBASE = "USER_NOT_IN_FIREBASE",
  USER_EXISTS_IN_HASURA = "USER_EXISTS_IN_HASURA",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
}
/*
Account status after the migration call
stop user creation if the migration call has an error,
this stops the app from pushing default settings to the db
*/
enum AccountStatus {
  PROCEED_CREATE_USER = "PROCEED_CREATE_USER",
  STOP_CREATE_USER = "STOP_CREATE_USER",
  USER_FOUND = "USER_FOUND d",
}

function* requestMigration(): Generator<any> {
  try {
    const auth: any = yield select((state) => state.auth);
    yield dbMigrationState(MigratorFlag.REQUEST_PENDING);
    const response: any = yield call(runMigration, auth.uid, auth.email);
    if (response.error) {
      yield dbMigrationState(MigratorFlag.REQUEST_ERROR);

      /* stop user creation process if a network error occurs */
      return AccountStatus.STOP_CREATE_USER;
    } else if (
      /* If the user already exists or has just been created in hasura */
      response === MigratorResponse.USER_EXISTS_IN_HASURA ||
      response === MigratorResponse.UPLOAD_SUCCESS
    ) {
      yield dbMigrationState(MigratorFlag.USER_EXISTS);
      return AccountStatus.USER_FOUND;
      /* migration did not proceed as user data was not in firebase */
    } else if (response === MigratorResponse.USER_NOT_IN_FIREBASE) {
      yield dbMigrationState(MigratorFlag.USER_NOT_IN_FIREBASE);

      return AccountStatus.PROCEED_CREATE_USER;
    }
  } catch (e) {
    yield call(console.log, e);
    yield call(console.log, "Error in migration saga");
  }
}

function* updateLocalAccountSettings(user: any): any {
  try {
    const setting = user.data.db_account_settings[0];
    const flag = user.data.db_flags[0];
    const keyboard_shortcuts =
      user.data.db_account_settings[0].keyboard_shortcut;

    const settings = createAccountSettings(setting, flag, keyboard_shortcuts);
    if (user.data.db_billing[0]) {
      const { stripe_id } = user.data.db_billing[0];

      yield put({
        type: ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE,
        payload: { settings },
      });
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
        payload: { field: "stripe_customer_id", newContent: stripe_id },
      });
    }

    const token = yield getToken();
    const survey = yield call(db.ob_survey.read, setting.id, token);
    const surveyAnswers = survey.data.db_ob_survey[0];
    if (surveyAnswers) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.OB_SURVEY.UPDATE,
        payload: {
          data: {
            ...surveyAnswers,
            survey_usecase: JSON.parse(surveyAnswers.survey_usecase),
          },
        },
      });
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* getAccountSettingsFromHasura(uid: any): any {
  try {
    const token = yield getToken();

    const user = yield call(
      db.account_settings.read,
      uid,
      process.env.REACT_APP_NODE_ENV === "production"
        ? "production"
        : "staging",
      token
    );
    if (doesUserExist(user)) {
      yield call(updateLocalAccountSettings, user);
      return AccountStatus.USER_FOUND;
    } else {
      /* account settings not found, test migration */
      const status: AccountStatus = yield call(requestMigration);
      if (status === AccountStatus.USER_FOUND) {
        const user = yield call(
          db.account_settings.read,
          uid,
          process.env.REACT_APP_NODE_ENV === "production"
            ? "production"
            : "staging",
          token
        );
        yield call(updateLocalAccountSettings, user);
        return status;
      } else return status;
    }
  } catch (e) {
    yield call(console.log, "unable to load data from hasura");
    yield call(console.log, e);
    return false;
  }
}

function* onLogin(): any {
  try {
    const { auth } = yield select((state: any) => state);
    if (auth.uid) {
      /* fetch data from hasura */
      const status: AccountStatus = yield call(
        getAccountSettingsFromHasura,
        auth.uid
      );

      if (status === AccountStatus.PROCEED_CREATE_USER) {
        yield call(() => {
          window.analytics.identify(auth.uid);
          window.analytics.track("Sign up", {
            environment: process.env.REACT_APP_APP_ENV,
          });
        });

        yield put({ type: ACTION.SAGAS.APP_STATE.CREATE, payload: {} });
        yield put({
          type: ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.LOAD,
          payload: {},
        });
        yield put({
          type: ACTION.SAGAS.APP_STATE.IDENTIFY_CALL,
          payload: {},
        });
        yield put({
          type: ACTION.SAGAS.APP_STATE.EXTENSION_LOGIN,
          payload: {},
        });
        yield put({ type: ACTION.SAGAS.BILLING.PLANS.READ, payload: {} });

        /* if user found, proceed to loading data */
      } else if (status === AccountStatus.USER_FOUND) {
        window.analytics.identify(auth.uid);
        yield put({ type: ACTION.SAGAS.USE_CASES.LOAD, payload: {} });
        yield put({
          type: ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.LOAD,
          payload: {},
        });
        yield put({ type: ACTION.SAGAS.APP_STATE.READ, payload: {} });
        yield put({
          type: ACTION.SAGAS.APP_STATE.IDENTIFY_CALL,
          payload: {},
        });
        yield put({
          type: ACTION.SAGAS.APP_STATE.EXTENSION_LOGIN,
          payload: {},
        });
      }

      /*
      For status === STOP_CREATE_USER
      do nothing as migrationFlag = REQUEST_ERROR
      and error message is displayed
      */
    }
  } catch {
    yield call(console.log, "unable to close configList's visibility");
  }
}

const extensionId = getExtensionId();

function* extensionLogin(): any {
  try {
    const { auth } = yield select((state: any) => state);

    if (auth.uid) {
      const session = yield call([Auth, Auth.currentSession]);
      console.log("User Session", session);

      const { sendMessage }: any = chrome.runtime;
      const finalResponse = {
        type: "login",
        session,
      };
      yield call(sendMessage, extensionId, finalResponse);
    }
  } catch (e) {
    // yield call(console.log, "extension login error!");
    // yield call(console.log, e);
  }
}

function* extensionLogout(): any {
  try {
    const { sendMessage }: any = chrome.runtime;
    const finalResponse = {
      type: "logout",
    };
    yield call(sendMessage, extensionId, finalResponse);
  } catch (e) {
    // yield call(console.log, "extension login error!");
    // yield call(console.log, e);
  }
}

function* integrationOpen(): any {
  try {
    if (typeof chrome !== "undefined" && chrome !== null) {
      if (typeof chrome.runtime !== "undefined" && chrome.runtime !== null) {
        const { sendMessage }: any = chrome.runtime;
        const finalResponse = {
          type: "integration_open",
        };
        yield call(sendMessage, extensionId, finalResponse);
      }
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* onboardingFinish(): any {
  try {
    if (typeof chrome !== "undefined" && chrome !== null) {
      if (typeof chrome.runtime !== "undefined" && chrome.runtime !== null) {
        const { sendMessage }: any = chrome.runtime;
        const finalResponse = {
          type: "onboarding_finished",
        };
        yield call(sendMessage, extensionId, finalResponse);
      }
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* onReturn() {
  try {
    yield put({
      type: ACTION.LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.UPDATE,
      payload: {},
    });
  } catch {
    yield call(console.log, "unable to return to the previous prompt");
  }
}

export const fetchSafetyVals = async (
  sendable: any,
  item: string,
  token: string
) => {
  const output = await ky
    .post(
      `${process.env.REACT_APP_BACKEND_HOST}/api/flow/validate_${item}_input`,
      {
        json: sendable,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        timeout: 30000,
        retry: {
          limit: 2,
          statusCodes: [408],
        },
      }
    )
    .json();
  return output;
};

export const getNonSafe = (checkable: any) =>
  Object.keys(checkable)
    .filter((key: any) => checkable[key] !== "0")
    .map((key: any) => ({ [key]: checkable[key] }));

function* checkSettingsSafety(action: any): any {
  const { settings } = action.payload;
  yield call(console.log, settings);
  const {
    identity,
    identityProperties,
    firstName,
    lastName,
    selectedGreeting,
    selectedSignature,
  } = settings;
  const uid = yield select((state) => state.auth.uid);
  const token = yield getToken();

  if (token) {
    const safetyVals = yield call(
      fetchSafetyVals,
      {
        identity,
        identityProperties,
        first_name: firstName,
        last_name: lastName,
        greeting: selectedGreeting,
        signature: selectedSignature,
        uid,
      },
      "settings",
      token
    );
    const nonSafeItems = yield call(getNonSafe, safetyVals);
    const nonSafeItemsNested = yield call(
      getNonSafe,
      /* eslint-disable-next-line */
      safetyVals["identityProperties"]
    );
    const allNonSafeItems = nonSafeItems.concat(nonSafeItemsNested);

    yield put({
      type: ACTION.LOCAL.APP_STATE.WARNED_LIST.UPDATE,
      payload: { field: "safetyWarnedSettings", newArray: allNonSafeItems },
    });
  }
}

function* updateSettings(settings: any): any {
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();
    // replace to call hasura
    // yield call(
    //   rsf.database.update,
    //   `users/${userId}/account_settings/`,
    //   settings
    // );
    // yield call(console.log, "updateSettings", settings);

    const keyboardId = yield call(
      db.account_settings.keyboard_shortcuts.id.read,
      token,
      settings.keyboardShortcuts
    );
    // yield call(console.log, "getKeyboardShortcutsID to update");
    const keyId = keyboardId.data
      ? keyboardId.data.db_keyboard_shortcuts[0].id
      : "";
    // yield call(console.log, keyboardId);

    const res = yield call(
      db.account_settings.update,
      userId,
      token,
      keyId,
      settings
    );
    yield put(SagasAppState.extensionSettingsMessage());
    yield call(console.log, "updateSettings response", res);
  } catch (e) {
    yield call(console.log, e);
  }
}

function* onUpdate(action: any): any {
  try {
    const { settings } = action.payload;
    // yield call(console.log, settings);

    yield put({
      type: ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE,
      payload: { settings },
    });

    yield call(updateSettings, settings);
  } catch (e) {
    yield call(console.log, "error in updateSettings");
    yield call(console.log, e);
  }
}

function* updateAccountSettings(action: any): any {
  try {
    const { settings } = yield select((state) => state.appState);

    yield call(updateSettings, settings);
  } catch (e) {
    yield call(console.log, "error in updateSettings");
    yield call(console.log, e);
  }
}

function* updateIdentityProperties(action: any): any {
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();
    const { identityProperties } = yield select(
      (state) => state.appState.settings
    );
    const { role, companyName, companyDescription } = identityProperties;

    const processedValues = {
      processedRole: cleanRole(role),
      processedCompanyName: cleanCompanyName(companyName),
      processedCompanyDescription: cleanCompanyDescription(companyDescription),
    };

    const res = yield call(db.account_settings.identity.update, userId, token, {
      ...identityProperties,
      ...processedValues,
    });

    yield put(SagasAppState.extensionSettingsMessage());
    // yield call(console.log, "updateIdentityProperties response", res);
  } catch (e) {
    yield call(console.log, "error in updateIdentityProperties");
    yield call(console.log, e);
  }
}

function* updateTemplatesOrderWithDefaultUseCases(): any {
  try {
    const { uid } = yield select((state) => state.auth);
    const token = yield getToken();

    const defaultUseCases = [
      "Meeting accept",
      "Introduce yourself",
      "Request",
      "Sales outreach",
      "Project update",
      "Give feedback",
      "Meeting request",
    ];

    const galleyUcRes = yield call(db.templates.gallery_templates.read, token);
    const useCases = galleyUcRes.data.templates_gallery_templates
      ? galleyUcRes.data.templates_gallery_templates.map((item: any) => item)
      : [];

    const defaultSelectedTemplates = defaultUseCases.map((uc) =>
      useCases.find((tmp: any) => tmp.name === uc)
    );
    const allTemplates = defaultSelectedTemplates.concat(
      useCases.filter(
        (item: any) =>
          defaultSelectedTemplates.findIndex(
            (useCase) => useCase?.template_id === item.template_id
          ) === -1
      )
    );
    const order = allTemplates.map((uc: any) => uc.template_id);

    yield call(db.templates.templates_order.update, uid, order, token);
  } catch (e) {
    yield call(console.log, e);
  }
}

function* onCreate(): any {
  try {
    const { auth } = yield select((state) => state);
    const token = yield getToken();
    const { defaultSettings } = yield select((state) => state.appState);
    const names = yield call(getNames, auth);
    const defaultWithGoogleNames = {
      ...defaultSettings,
      ...names,
      email: auth.email,
      bootstrapped: true,
    };

    let clientId;
    ga(function (tracker: any) {
      clientId = tracker?.get("clientId");
    });

    yield put({
      type: ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE,
      payload: { settings: defaultWithGoogleNames },
    });

    // read invite-code table in order to get information regarding pricing model of the user
    /* <invite-code-system> */
    const result = yield call(
      db.invite_codes.customer.read,
      auth.uid,
      auth.email,
      token
    );

    const price_id = result.data.invite_codes[0]?.cohort_id;
    const payload_price_id = price_id ? price_id : getPriceId();
    // update local app state with result recieved from table
    // if no price_id is found, set price_id to default production price
    yield put({
      type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
      payload: {
        field: "price_id",
        newContent: payload_price_id,
      },
    });

    // stripe added
    yield put({ type: ACTION.SAGAS.BILLING.CUSTOMER.CREATE, payload: {} });

    const flowrite_id = uuidv4();

    const response = yield call(
      db.account_settings.create,
      auth.uid,
      token,
      defaultWithGoogleNames,
      flowrite_id,
      clientId
    );

    yield updateTemplatesOrderWithDefaultUseCases();

    yield put({ type: ACTION.SAGAS.BILLING.CUSTOMER.READ, payload: {} });
    yield put({ type: ACTION.SAGAS.BILLING.PLANS.READ, payload: {} });

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (error) {
    // here instead dispatch error message, could not push to firebase
    yield call(console.log, error);
  }
}

function* computedProcessedIdentityFields(values: {
  role: string;
  companyName: string;
  companyDescription: string;
}): any {
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();
    const { role, companyName, companyDescription } = values;

    const processedValues = {
      processedRole: cleanRole(role),
      processedCompanyName: cleanCompanyName(companyName),
      processedCompanyDescription: cleanCompanyDescription(companyDescription),
    };

    const response = yield call(
      db.account_settings.identity.updateProcessed,
      userId,
      token,
      processedValues
    );

    // yield call(console.log, "computedProcessedIdentityFields", response);

    yield put(SagasAppState.extensionSettingsMessage());

    return response?.data.update_db_account_settings.returning[0];
  } catch (err) {
    yield call(console.log, err);
  }
}

function* onRead(): any {
  try {
    const { auth } = yield select((state) => state);
    const token = yield getToken();

    const userSetUp = yield call(
      db.account_settings.read,
      auth.uid,
      process.env.REACT_APP_NODE_ENV === "production"
        ? "production"
        : "staging",
      token
    );

    const setting =
      userSetUp && userSetUp.data.db_account_settings
        ? userSetUp.data.db_account_settings[0]
        : [];
    const flag =
      userSetUp && userSetUp.data.db_flags ? userSetUp.data.db_flags[0] : [];
    const keyboard_shortcuts =
      userSetUp && userSetUp.data.db_account_settings[0].keyboard_shortcut
        ? userSetUp.data.db_account_settings[0].keyboard_shortcut
        : {};

    // if user has stripe_id
    if (userSetUp.data.db_billing[0]) {
      const { stripe_id } = userSetUp.data.db_billing[0];
      const { price_id } = userSetUp.data.db_billing[0];
      yield call(console.log, "data.db_billing is empty");

      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
        payload: { field: "stripe_customer_id", newContent: stripe_id },
      });

      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
        payload: { field: "price_id", newContent: price_id },
      });

      yield put({ type: ACTION.SAGAS.BILLING.CUSTOMER.READ, payload: {} });
      yield put({ type: ACTION.SAGAS.BILLING.PLANS.READ, payload: {} });
    } else {
      // error should not be thrown here
      // yield call(console.log, "data.db_billing is empty");
      yield put({ type: ACTION.SAGAS.BILLING.CUSTOMER.CREATE, payload: {} });
      yield put({ type: ACTION.SAGAS.BILLING.PLANS.READ, payload: {} });
      yield put(SagasAppState.extensionSettingsMessage());

      // yield put({ type: ACTION.SAGAS.BILLING.CUSTOMER.READ, payload: {} });
    }

    let identityProperties = {
      companyName: setting.company_name,
      companyDescription: setting.company_description,
      role: setting.role,
      processedCompanyName: setting.processed_company_name,
      processedCompanyDescription: setting.processed_company_description,
      processedRole: setting.processed_role,
      firstPreposition: setting.first_preposition,
      secondPreposition: setting.second_preposition,
    };

    if (
      !setting.processed_role ||
      !setting.processed_company_name ||
      !setting.processed_company_description
    ) {
      const processedValues = yield computedProcessedIdentityFields(
        identityProperties
      );
      // yield call(console.log, "processed identity properties", processedValues);
      if (processedValues) {
        identityProperties = {
          ...identityProperties,
          processedCompanyName: processedValues.processed_company_name,
          processedCompanyDescription:
            processedValues.processed_company_description,
          processedRole: processedValues.processed_role,
        };
      }
    }

    const settings = {
      ...createAccountSettings(setting, flag, keyboard_shortcuts),
      identityProperties,
    };

    yield put({
      type: ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE,
      payload: { settings },
    });
  } catch (error) {
    // here instead dispatch error message, could not read from firebase
    yield call(console.log, error);
  }
}

// function* handleRateLimitError = () => {
//   yield put({ type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE, pa})
// }

function* handleSafetyError(action: any) {
  const { body_label, safety_category } = action.payload;
  if (body_label === "first") {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: "promptWarningFirstBody", newContent: safety_category },
    });
  } else {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: {
        field: "promptWarningSecondBody",
        newContent: safety_category,
      },
    });
  }
}

/** Not used <write-page-removal> */

function* recognizeRecipient(): any {
  const { first_body } = yield select((state) => state.appState);
  if (first_body.length < 50) {
    return;
  }

  yield put({
    type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
    payload: {
      id: "recipientLoading",
      targetState: true,
    },
  });

  const recipientRecognitionResponse: any = yield call(() => {
    const response = ky
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/flow/recipient-recognition`,
        {
          json: { first_body },
          headers: { "Access-Control-Allow-Origin": "*" },
          timeout: 30000,
          retry: {
            limit: 2,
            statusCodes: [408],
          },
        }
      )
      .json();
    return response;
  });

  // here recognize what we received back from lambda
  if (
    "result" in recipientRecognitionResponse &&
    recipientRecognitionResponse.result.recipient_name !==
      "First body doesn't contain generated recipient name."
  ) {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: {
        field: "recipient",
        newContent: recipientRecognitionResponse.result.recipient_name,
      },
    });
  } else {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: "recipient", newContent: "" },
    });
  }

  //  stop loading
  yield put({
    type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
    payload: {
      id: "recipientLoading",
      targetState: false,
    },
  });
}

function* checkAdminRights(action: any): any {
  //  const { uid } = yield select((state) => state.auth);
  //  const { settings } = yield select((state) => state.appState);
  try {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: "role", newContent: "admin" },
    });
    // should it also update firebase?
  } catch (e) {
    yield call(console.log, "end of saga false");
    yield call(console.log, e);
  }
}

function* updateCheckListFlag(action: any): any {
  const { newContent } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();
    if (newContent !== null) {
      const res = yield call(
        db.flags.check_list.update,
        userId,
        token,
        newContent
      );
      // yield call(console.log, "result", res);
    } else {
      const res = yield call(db.flags.check_list.update, userId, token, null);
      // yield call(console.log, "null result", res);
    }

    yield put({
      type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE,
      payload: { field: "checkList", newContent },
    });

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* updateCompletionLength(action: any): any {
  try {
    const { newContent } = action.payload;
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    // yield call(console.log, "payload is", newContent);
    if (newContent !== null) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE,
        payload: { field: "completion_length", newContent },
      });

      const res = yield call(
        db.account_settings.completion_length.update,
        userId,
        token,
        newContent
      );
      // yield call(console.log, "result", res);

      yield put(SagasAppState.extensionSettingsMessage());
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* updateOnboardingFlags(action: any): any {
  const { data } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    const { flags } = yield select((state) => state.appState.settings);

    yield call(db.flags.onboarding.update, userId, token, {
      ...flags,
      ...data,
    });

    yield put({
      type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE_MULTIPLE,
      payload: { data },
    });

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* updateExtensionInstallFlag(action: any): any {
  const { newContent } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    yield put({
      type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE,
      payload: { field: "extension_install", newContent: newContent },
    });

    const res = yield call(
      db.flags.extension_install.update,
      userId,
      token,
      newContent
    );
    // yield call(console.log, res);

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* createVipInvite(action: any): any {
  const { uid } = yield select((state) => state.auth);
  const { settings } = yield select((state) => state.appState);
  const user_email = yield select((state) => state.appState.settings.email);
  const token = yield getToken();
  const { email } = action.payload;

  try {
    if (token) {
      if (typeof email === "string") {
        // yield call(console.log, email);
        yield call(
          apiRequests.flow.sendVipInvite,
          settings.email,
          email,
          settings.firstName,
          settings.lastName,
          token
        );
      } else {
        const emailChecked = email.filter((x: any) => x !== "");
        yield all([
          emailChecked.map((value: any) => {
            console.log("pass");
            console.log(value);

            return apiRequests.flow.sendVipInvite(
              settings.email,
              value,
              settings.firstName,
              settings.lastName,
              token
            );
          }),
        ]);
      }
    }

    const invite = () => {
      if (typeof email === "string") {
        return settings.flags.vipInviteCount + 1;
      } else {
        const emailChecked = email.filter((x: any) => x !== "");
        return settings.flags.vipInviteCount + emailChecked.length;
      }
    };
    const res: number = invite();

    if (!user_email.includes("@flowrite.com")) {
      // console.log("not a flowrite user");

      yield put({
        type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE,
        payload: { field: "vipInviteCount", newContent: res },
      });
      // hasura
      const resHasura = yield call(db.flags.vip_invite.update, uid, token, res);
      // yield call(console.log, "Hasura after db update", resHasura);
    }

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, "end of saga false");
    yield call(console.log, e);
  }
}

function* updateFirstUsageBumperFlag(action: any): any {
  const { newContent } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    yield put({
      type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE,
      payload: { field: "first_usage_bumper", newContent },
    });

    const res = yield call(
      db.flags.first_usage_bumper.update,
      userId,
      token,
      newContent
    );
    yield call(console.log, res);
    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* identifyUserForTracking(): any {
  try {
    const { uid } = yield select((state) => state.auth);
    const token = yield getToken();
    const { email, firstName, lastName } = yield select(
      (state) => state.appState.settings
    );

    if (uid && uid !== "" && token) {
      yield call(
        apiRequests.flow.logSegmentIdentify,
        {
          uid,
          email,
          "First name": firstName,
          "Last name": lastName,
          name: `${firstName}${lastName ? ` ${lastName}` : ""}`,
        },
        token
      );
    }
  } catch (e) {
    yield call(console.log, e);
    yield call(console.log, "Error in user identification for tracking");
  }
}

function* updateOnboardingReason(action: any): any {
  const { field, newContent } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    // yield call(
    //   console.log,
    //   "Hasura onboardingUpdate update sagas",
    //   field,
    //   newContent
    // );

    yield put({
      type: ACTION.LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE,
      payload: { field, newContent },
    });

    if (field === "usageReason") {
      const usg = yield call(
        db.account_settings.usage_reason.update,
        userId,
        token,
        newContent
      );

      // yield call(console.log, "Hasura after", usg);
    }

    if (field === "defaultUseCasesName") {
      const usg = yield call(
        db.account_settings.persona.update,
        userId,
        token,
        newContent
      );

      // yield call(console.log, "Hasura after", usg);
    }

    if (newContent !== undefined && field !== undefined) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.FLAGS.UPDATE,
        payload: { field, newContent },
      });
    }
    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* updateOnboardingSurvey(action: any): any {
  const { data } = action.payload;
  try {
    const userId = yield select((state) => state.auth.uid);
    const token = yield getToken();

    const res = yield call(db.ob_survey.read, userId, token);
    if (res.data.db_ob_survey.length > 0) {
      yield call(db.ob_survey.update, userId, data, token);
    } else {
      yield call(db.ob_survey.insert, userId, data, token);
    }
    yield put({
      type: ACTION.LOCAL.APP_STATE.OB_SURVEY.UPDATE,
      payload: { data },
    });
    yield put(SagasAppState.extensionSettingsMessage());
  } catch (e) {
    yield call(console.log, e);
  }
}

function* extensionUpdateSettingsMessage(): any {
  try {
    if (typeof chrome !== "undefined" && chrome !== null) {
      if (typeof chrome.runtime !== "undefined" && chrome.runtime !== null) {
        const { sendMessage }: any = chrome.runtime;
        const finalResponse = {
          type: "update_settings",
        };
        yield call(sendMessage, extensionId, finalResponse);
      }
    }
  } catch (e) {
    logger().log(`Error while sending message ${e}`);
  }
}

function* appStateSaga() {
  yield all([
    takeEvery(
      ACTION.SAGAS.APP_STATE.EXTENSION_UPDATE_SETTINGS_MESSAGE,
      extensionUpdateSettingsMessage
    ),
    takeEvery(ACTION.SAGAS.APP_STATE.IDENTIFY_CALL, identifyUserForTracking),
    takeEvery(
      ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_CHECKLIST,
      updateCheckListFlag
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_ONBOARDING,
      updateOnboardingFlags
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_EXTENSION_INSTALL,
      updateExtensionInstallFlag
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_FIRST_USAGE_BUMPER,
      updateFirstUsageBumperFlag
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.ACCOUNT_SETTINGS.UPDATE,
      updateAccountSettings
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.IDENTITY_PROPERTIES.UPDATE,
      updateIdentityProperties
    ),

    takeEvery(
      ACTION.SAGAS.APP_STATE.ONBOARDING.UPDATE_REASON,
      updateOnboardingReason
    ),
    takeEvery(
      ACTION.SAGAS.APP_STATE.ONBOARDING.UPDATE_SURVEY,
      updateOnboardingSurvey
    ),
    takeEvery(ACTION.SAGAS.APP_STATE.ONBOARDING_FINISHED, onboardingFinish),

    takeEvery(ACTION.SAGAS.APP_STATE.EXTENSION_LOGIN, extensionLogin),
    takeEvery(ACTION.SAGAS.APP_STATE.EXTENSION_LOGOUT, extensionLogout),
    takeEvery(ACTION.SAGAS.APP_STATE.INTEGRATION_OPEN, integrationOpen),

    takeEvery(ACTION.SAGAS.APP_STATE.ON_LOGIN, onLogin),
    // takeEvery(ACTION.SAGAS.APP_STATE.USER.CREATE, onUserCreate),
    takeEvery(ACTION.SAGAS.APP_STATE.RETURN_TO_PROMPT, onReturn),
    takeEvery(ACTION.SAGAS.APP_STATE.READ, onRead),
    takeEvery(ACTION.SAGAS.APP_STATE.UPDATE, onUpdate),
    takeEvery(
      ACTION.SAGAS.APP_STATE.COMPLETION_LENGTH.UPDATE,
      updateCompletionLength
    ),

    takeEvery(ACTION.SAGAS.APP_STATE.CREATE, onCreate),
    // takeEvery(ACTION.SAGAS.APP_STATE.RATE_LIMIT.ERROR, handleRateLimitError),
    takeEvery(ACTION.SAGAS.APP_STATE.SAFETY.ERROR, handleSafetyError),
    takeEvery(
      ACTION.SAGAS.APP_STATE.CHECK_SETTINGS_SAFETY,
      checkSettingsSafety
    ),
    takeEvery(ACTION.SAGAS.APP_STATE.CHECK_ADMIN_RIGHTS, checkAdminRights),
    takeEvery(ACTION.SAGAS.APP_STATE.VIP_INVITE, createVipInvite),
    takeEvery(ACTION.SAGAS.APP_STATE.RECIPIENT.GET, recognizeRecipient),
  ]);
}

export default appStateSaga;
