
const USE_CASE = {
  CREATE: "ACTION.SAGAS.USE_CASE.CREATE",
  DELETE: "ACTION.SAGAS.USE_CASE.DELETE",
  UPDATE: "ACTION.SAGAS.USE_CASE.UPDATE",
  CHECK_SAFETY: "ACTION.SAGAS.USE_CASE.CHECK_SAFETY",
  CHECK_SAFETY_EDIT_DIALOG: "ACTION.SAGAS.USE_CASE.CHECK_SAFETY_EDIT_DIALOG",
};

export default USE_CASE;
