import ONBOARDING from "./onboarding";
import CHECK_LIST from "./check_list";
import VIP_INVITE from "./vip_invite";
import FEEDBACK_COUNT from "./feedback_count";
import EXTENSION_INSTALL from "./extension_install";
import NEW_IDENTITY_ADDED from "./new_identity_added";
import FIRST_USAGE_BUMPER from "./first_usage_bumper";
import MIGUI_BUMPER_FLOW from "./migui_bumper_flow";
import EXISTING_USER_TFGF_BUMPER from "./existing_user_tfgf_bumper";
import ITERATEUX_BUMPER_FLOW from "./iterateux_bumper_flow";
import COPY_USED from "./copy_used";
import GENERATION_LIMIT_EXCEEDED from "./generation_limit_exceeded";
import GENERATION_COUNT from "./generation_count";
import SUGGESTIONS_ENABLED from "./suggestions_enabled";
import LEFT_OVER_GENERATION_LIMIT_FROM_OLD_PLAN from "./left_over_generation_limit_from_old_plan";

const FLAGS = {
  ONBOARDING,
  CHECK_LIST,
  VIP_INVITE,
  FEEDBACK_COUNT,
  EXTENSION_INSTALL,
  MIGUI_BUMPER_FLOW,
  ITERATEUX_BUMPER_FLOW,
  NEW_IDENTITY_ADDED,
  FIRST_USAGE_BUMPER,
  EXISTING_USER_TFGF_BUMPER,
  COPY_USED,
  GENERATION_LIMIT_EXCEEDED,
  GENERATION_COUNT,
  SUGGESTIONS_ENABLED,
  LEFT_OVER_GENERATION_LIMIT_FROM_OLD_PLAN,
};

export { FLAGS };
