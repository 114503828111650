import { onboarding, Onboarding } from "./onboarding";
import { check_list, CheckList } from "./check_list";
import { vip_invite, VIPInvite } from "./vip_invite";
import { feedback_count, FeedbackCount } from "./feedback_count";
import { extension_install, ExtensionInstall } from "./extension_install";
import { migui_bumper_flow, MiguiBumperFlow } from "./migui_bumper_flow";
import { new_identity_added, NewIdentityAdded } from "./new_identity_added";
import { first_usage_bumper, FirstUsageBumper } from "./first_usage_bumper";
import { suggestions_enabled, SuggestionsEnabled } from "./suggestions_enabled";
import {
  existing_user_tfgf_bumper,
  TFGFBumper,
} from "./existing_user_tfgf_bumper";
import {
  iterateux_bumper_flow,
  IterateUXBumperFlow,
} from "./iterateux_bumper_flow";
import { copy_used, CopyUsed } from "./copy_used";
import {
  generation_limit_exceeded,
  GenerationLimitExceeded,
} from "./generation_limit_exceeded";
import { generation_count, GenerationCount } from "./generation_count";
import {
  left_over_generation_limit_from_old_plan,
  LeftOverGenerationLimitFromOldPlan,
} from "./left_over_generation_limit_from_old_plan";

export type Flags = {
  onboarding: Onboarding;
  vip_invite: VIPInvite;
  check_list: CheckList;
  feedback_count: FeedbackCount;
  extension_install: ExtensionInstall;
  migui_bumper_flow: MiguiBumperFlow;
  new_identity_added: NewIdentityAdded;
  first_usage_bumper: FirstUsageBumper;
  existing_user_tfgf_bumper: TFGFBumper;
  iterateux_bumper_flow: IterateUXBumperFlow;
  copy_used: CopyUsed;
  generation_limit_exceeded: GenerationLimitExceeded;
  generation_count: GenerationCount;
  suggestions_enabled: SuggestionsEnabled;
  left_over_generation_limit_from_old_plan: LeftOverGenerationLimitFromOldPlan;
};

const flags: Flags = {
  onboarding,
  check_list,
  vip_invite,
  feedback_count,
  extension_install,
  migui_bumper_flow,
  new_identity_added,
  first_usage_bumper,
  existing_user_tfgf_bumper,
  iterateux_bumper_flow,
  copy_used,
  generation_limit_exceeded,
  generation_count,
  suggestions_enabled,
  left_over_generation_limit_from_old_plan,
};

export { flags };
