import { CognitoUser } from "@aws-amplify/auth";

type UserAttributes = {
  email: string;
  email_verified: boolean;
  identities: string;
  name: string;
  sub: string;
};

type TokenWrapper = {
  jwtToken: string;
  payload: any;
};

export type UserSession = {
  idToken: TokenWrapper;
  accessToken: TokenWrapper;
  clockDrift: any;
  refreshToken: {
    token: string;
  };
};

export interface ExtendedCognitoUser extends CognitoUser {
  attributes: UserAttributes;
  signInUserSession: UserSession;
  username: string;
}

export enum HubEvent {
  SIGN_IN = "signIn",
  SIGN_UP = "signUp",
  SIGN_IN_FAILURE = "signIn_failure",
  SIGN_OUT = "signOut",
  TOKEN_REFRESH = "tokenRefresh",
  TOKEN_REFRESH_FAILURE = "tokenRefresh_failure",
  CONFIGURED = "configured",
  PARSING_CALLBACK_URL = "parsingCallbackUrl",
}
