import { Auth } from "aws-amplify";
import { apply, call, put } from "redux-saga/effects";
import AuthState from "../../../../actions/local/auth";
import { fetchAttributes, setCurrentUser } from "../../utils/helpers";
import { ExtendedCognitoUser } from "../../utils/types";

function* onSignIn(payload: any): any {
  try {
    const user: ExtendedCognitoUser = yield apply(
      Auth,
      Auth.currentAuthenticatedUser,
      []
    );
    yield setCurrentUser(user);
    console.log("User on sign in", user);
  } catch (e) {
    yield call(console.log, e);
  }
}

export default onSignIn;
