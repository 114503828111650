import UseCases, { LocalUseCasesRepo } from "./use-cases";
import UseCase, { LocalUseCaseRepo } from "./use-case";
import AppState, { LocalAppStateRepo } from "./app-state";
import Auth, { LocalAuthStateRepo } from "./auth";
// import FlagsState, { LocalFlagsStateRepo } from "./flags";
import LocalStorageState, {
  LocalCompliationStorageRepo,
} from "./local-storage";
import GenerationHistory, {
  LocalGenerationHistoryRepo,
} from "./generation-history";

export type LocalRepo = {
  AppState: LocalAppStateRepo;
  UseCase: LocalUseCaseRepo;
  UseCases: LocalUseCasesRepo;
  Auth: LocalAuthStateRepo;
  // FlagsState: LocalFlagsStateRepo;
  LocalStorageState: LocalCompliationStorageRepo;
  GenerationHistory: LocalGenerationHistoryRepo;
};

const Local: LocalRepo = {
  AppState,
  UseCase,
  UseCases,
  Auth,
  // FlagsState,
  LocalStorageState,
  GenerationHistory,
};

export default Local;
