const TEMPLATES_ORDER = {
  READ: `
    query getTemplatesOrderQuery($uid: String) {
        db_users(where: {id: {_eq: $uid}}) {
            templates_order
        }
    }
    `,
  UPDATE: `
    mutation UpdateTemplatesOrder($uid: String, $templates_order: _text) {
        update_db_users(_set: {templates_order: $templates_order}, where: {id: {_eq: $uid}}) {
            affected_rows
        }
    }
    `,
};

export default TEMPLATES_ORDER;
