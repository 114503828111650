import { useLocation } from "react-router-dom";

/*
hook to fetch query string from the url
usage:
    const query = useQuery()    
    query.get("attribute")
*/

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default useQuery;
