import { call, select } from "redux-saga/effects";
import {
  createEventStreamRequest,
  createPreviewPromptRequest,
} from "../../reducers/utils";

/* Not used - write page removal */

import {
  feedbackModeEnable,
  flowriteModeDisable,
  startStream,
  streamError,
  saveTemplateName,
  updateDevData,
  appStateAnyFieldIntUpdate,
  interruptUpdate,
} from "../utils/helpers";

const trackNewGeneration = (payload: any) => {
  const { useCase } = payload;
  const caseExists = useCase !== null;
  if (caseExists) {
    const templateId = useCase.template_collection_id || "user template";
    const templateIntention = useCase.intention || "Flowrite";
    const generationTemplateName = useCase.name || "Flowrite";
    const userGroup = useCase.user_group || "Flowrite";
    const useCaseType = useCase.use_case_type || "Flowrite";

    // console.log(`templateId: |${templateId}|`);

    window.analytics.track("New Generation Web App", {
      templateId,
      environment: process.env.REACT_APP_APP_ENV,
      templateIntention,
      generationTemplateName,
      userGroup,
      useCaseType,
    });
  }
};

// creates the request to be sent to OpenAI GPT-3 endpoint
function* sendEventStreamRequest(action: any): any {
  try {
    const userId = yield select((state) => state.auth.uid);
    const appState = yield select((state) => state.appState);

    // guard for illegal length, taking into account both bodies we are sending
    if (appState.first_body.length + appState.second_body.length > 5000) {
      // yield call(console.log, "Max Length Exceeded");
      yield flowriteModeDisable();
      yield streamError();
    }

    // create the source using the state
    // note: lots of arguments passed -> probably not needed (?)
    // yield call(console.log, "send event");
    const source = yield call(
      createEventStreamRequest,
      action.payload.appState,
      action.payload.useCase,
      userId,
      action.payload.resend // check whether regeneration or not
    );

    /* saving template name for completion history feature */
    yield saveTemplateName(
      action.payload.useCase !== null ? action.payload.useCase.name : "Flowrite"
    );
    yield call(trackNewGeneration, action.payload);

    if (!appState.interrupt) {
      yield startStream(source);

      yield appStateAnyFieldIntUpdate(
        "completion_count",
        appState.completion_count + 1
      );
    } else {
      yield interruptUpdate(false);
    }

    if (appState.settings.developmentMode) {
      const preview = yield call(
        createPreviewPromptRequest,
        appState,
        action.payload.useCase,
        userId,
        action.payload.resend // check whether regeneration or not
      );
      if (preview) {
        yield updateDevData(preview);
      }
    }
    // error
  } catch (error) {
    yield call(console.log, error);
    yield flowriteModeDisable();
    yield feedbackModeEnable();
    yield streamError();
  }
}

export default sendEventStreamRequest;
