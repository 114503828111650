import USE_CASES from "./use-cases";
import USE_CASE from "./use-case";
import APP_STATE from "./app-state";
import REQUEST_ITEM from "./request-item";
// import FLAGS from "./flags";
import STORAGE from "./local-storage";
import AUTH from "./auth";
import GENERATION_HISTORY from "./generation-history";

const LOCAL = {
  APP_STATE,
  REQUEST_ITEM,
  USE_CASE,
  USE_CASES,
  // FLAGS,
  STORAGE,
  AUTH,
  GENERATION_HISTORY,
};

export default LOCAL;
