import DEFAULT_TEMPLATES from "./default_templates";
import GALLERY_TEMPLATES from "./gallery_templates";
import USER_TEMPLATES from "./user_templates";
import BULLET_SUGGESTIONS from "./bullet_suggestions";
import DOS_TEMPLATES from "./dos_templates";
import TEMPLATES_ORDER from "./templates_order";
import ITERATION_INSTRUCTIONS from "./iteration_instructions";

const TEMPLATES = {
  DEFAULT_TEMPLATES,
  GALLERY_TEMPLATES,
  USER_TEMPLATES,
  BULLET_SUGGESTIONS,
  DOS_TEMPLATES,
  TEMPLATES_ORDER,
  ITERATION_INSTRUCTIONS,
};

export { TEMPLATES };
