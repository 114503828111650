const INVITE_CODES = {
  CUSTOMER: {
    READ: `
    query GetPriceID($email: String_comparison_exp = {}) {
      invite_codes(where: {email: $email}) {
        email
        used
        waitlist_email
        cohort_id
        code
      }
    }
        `,
  },
};

export { INVITE_CODES };
