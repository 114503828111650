import SAGAS from "./constants";

export type SagasUseCaseRepo = {
  create(): any;
  delete(useCaseId: string): any;
  update(useCaseId: string): ActionCreate;
  checkSafety(): any;
  checkSafetyEditDialog(useCase: any): any;
};

export type ActionCreate = {
  type: string;
  payload: { useCaseId: string };
};

const UseCase: SagasUseCaseRepo = {
  create: () => ({
    type: SAGAS.USE_CASE.CREATE,
    payload: {},
  }),
  delete: (useCaseId) => ({
    type: SAGAS.USE_CASE.DELETE,
    payload: { useCaseId },
  }),
  update: (useCaseId) => ({
    type: SAGAS.USE_CASE.UPDATE,
    payload: { useCaseId },
  }),
  checkSafety: () => ({
    type: SAGAS.USE_CASE.CHECK_SAFETY,
    payload: {},
  }),
  checkSafetyEditDialog: (useCase) => ({
    type: SAGAS.USE_CASE.CHECK_SAFETY_EDIT_DIALOG,
    payload: { useCase },
  }),
 
};

export default UseCase;
