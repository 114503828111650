import DB from "../constants";
import createHasuraRequest from "../utils";

export type Template = {
  create: (uid: string, token: string, useCase: any) => any;
  update: (token: string, useCase: any) => any;
  delete: (token: string, template_id: string) => any;
  index: {
    update: (token: string, useCaseId: any, index: any) => any;
  };
};

const template: Template = {
  create: async (uid, token, useCase) => {
    const request = createHasuraRequest(DB.TEMPLATE.CREATE, token);
    const parameters = {
      template: {
        id: useCase.id,
        name: useCase.name,
        emoji: useCase.emoji ? useCase.emoji : "",
        intention: useCase.intention,
        description: useCase.description,
        created_date: new Date(),
        recipient_type: useCase.recipient_type,
        instructions: useCase.instructions,
        template_collection_id: useCase.template_id
          ? useCase.template_id
          : "user_template",
        template_group: useCase.template_group,
        template_type: useCase.template_type,
      },
      connection: {
        user_id: uid,
        template_id: useCase.id,
      },
    };

    return await request(parameters);
  },

  update: async (token, useCase) => {
    const request = createHasuraRequest(DB.TEMPLATE.UPDATE, token);
    const parameters = {
      id: useCase.id,
      name: useCase.name,
      emoji: useCase.emoji,
      intention: useCase.intention,
      modified_date: useCase.modified_date,
      recipient_type: useCase.recipient_type,
      instructions: useCase.instructions,
      template_group: useCase.template_group,
      template_type: useCase.template_type,
      template_collection_id: useCase.template_collection_id,
    };
    return await request(parameters);
  },

  delete: async (token, template_id) => {
    const request = createHasuraRequest(DB.TEMPLATE.DELETE, token);
    const parameters = {
      template_id,
    };
    return await request(parameters);
  },

  index: {
    update: async (token, useCaseId, index) => {
      const request = createHasuraRequest(DB.TEMPLATE.INDEX.UPDATE, token);
      const parameters = {
        id: useCaseId,
        index: index,
      };
      return await request(parameters);
    },
  },
};

export { template };
