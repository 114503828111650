/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */

import { all, call, put, putResolve, select } from "redux-saga/effects";
import { key } from "firebase-key";
import { ACTION } from "../../actions";
import { Auth } from "aws-amplify";
import { MigratorFlag } from "../../initial-state/app-state";
import {
  HasuraFlags,
  HasuraKeyboardShortcuts,
  HasuraSettings,
} from "../../../types/hasura";
import { UserSession } from "../auth/utils/types";
import { myFirebaseApp } from "../../..";

const toArr = (obj: any): any[] =>
  Object.keys(obj).map((key: any) => ({
    key: obj[key].id,
    value: { ...obj[key] },
  }));

const validateNewLineAndFullSopRegex = (input: string) => {
  /* eslint-disable-next-line no-control-regex */
  const regex = new RegExp("[\r\n.]+");
  return regex.test(input);
};

const sortByIndexFlat = (arr: any[]) =>
  arr.sort((a: any, b: any) => a.index - b.index);

const sortDescByIndexFlat = (arr: any[]) =>
  arr.sort((a: any, b: any) =>
    b.index !== undefined && a.index !== undefined ? b.index - a.index : -1
  );

const sortDescByCreatedAtDate = (arr: any[]) =>
  arr.sort(
    (a: any, b: any) =>
      new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
  );

function immutableMove(arr: any[], from: number, to: number) {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, []);
}

const createAccountSettings = (
  setting: HasuraSettings,
  flag: HasuraFlags,
  keyboard_shortcuts: HasuraKeyboardShortcuts
) => ({
  darkMode: setting.dark_mode,
  email: setting.email,
  creativity: setting.creativity,
  completion_length: setting.completion_length,
  temperature: setting.temperature,
  identity: "",
  identityProperties: {
    companyName: setting.company_name,
    companyDescription: setting.company_description,
    role: setting.role,
    firstPreposition: setting.first_preposition,
    secondPreposition: setting.second_preposition,
  },
  flags: {
    vipInviteCount: flag.vip_invite_count,
    feedbackCount: flag.feedback_count,
    onboarding_step: flag.onboarding_step,
    steps_completed: flag.steps_completed,
    onboarding_step_text: flag.onboarding_step_text,
    step_completed_text: flag.step_completed_text,
    new_identity_added: flag.new_identity_added,
    onboarding_mode: flag.onboarding_mode,
    checkList: flag.check_list,
    extension_install: flag.extension_install,
    migration_completed: flag.migration_completed,
    first_usage_bumper: flag.first_usage_bumper,
    generation_limit_exceeded: flag.generation_limit_exceeded,
    generation_count: flag.generation_count,
    left_over_generation_limit_from_old_plan: flag.left_over_generation_limit_from_old_plan,
  },
  firstName: setting.first_name,
  lastName: setting.lastname,
  bootstrapped: setting.bootstrapped,
  selectedGreeting: setting.selected_greeting,
  selectedSignature: setting.selected_signature,
  flowrittenTag: setting.flowritten_tag,
  keyboardShortcuts: {
    shiftLeft: keyboard_shortcuts.shift_left,
    shiftRight: keyboard_shortcuts.shift_right,
    shiftEnter: keyboard_shortcuts.shift_enter,
  },
  developmentMode: false,
  logOut: setting.log_out,
  usageReason: setting.usage_reason,
  defaultUseCasesName: setting.persona,
  extension_disabled_integrations:
    setting.extension_disabled_integrations || [],
});

const setSeedId = (data: any) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "seed_id", newContent: data.seed_id },
  });

const setGenId = (data: any) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "gen_id", newContent: data.gen_id },
  });

const feedbackModeEnable = () => ({
  type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
  payload: { field: "feedback", newContent: true },
});

const feedbackModeDisable = () => ({
  type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
  payload: { field: "feedback", newContent: false },
});

const updateDevData = (data: any) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "dev_data", newContent: data },
  });

const promptStateUpdate = (data: number) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "promptState", newContent: data },
  });

const interruptUpdate = (data: boolean) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "interrupt", newContent: data },
  });

const secondBodyUpdate = (data: any, number = 0) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: {
      field: number === 0 ? "second_body" : `second_body_${number}`,
      newContent: data,
    },
  });

const firstBodyUpdate = (data: string) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "first_body", newContent: data },
  });

const appStateAnyFieldIntUpdate = (field: string, data: number) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field, newContent: data },
  });

const prepareEnd = (data: any) =>
  all([
    flowriteModeDisable(),
    setSeedId(data),
    setGenId(data),
    feedbackModeEnable(),
  ]);

const stampIndices = (arr: any[]) =>
  arr.map((useCase: any, index: any) => ({
    ...useCase,
    index,
  }));

const toArrFlat = (obj: any): any[] =>
  Object.keys(obj).map((key: any) => obj[key]);

const filterUseCaseName = (arr: any[], nameArr: string[]): any[] =>
  arr.filter((x) => !nameArr.includes(x.name));

const removeImported = (galleryTemplates: any, templates: any) =>
  galleryTemplates.filter(
    (galleryTemplate: any) =>
      templates.findIndex(
        (template: any) =>
          galleryTemplate.template_id === template.template_collection_id
      ) === -1
  );

const decodeReceived = (data: any) =>
  data.text.replace(/\*\s/gi, "").replace(/ͻ/gi, " ").replace(/ƺ/gi, "\n");

const notDateTime = (e: any) =>
  e.data.split(/\d{4}-\d{2}-\d{2} \d{1,2}:\d{2}:\d{2}.\d{6}/).length < 2;

const notEmpty = (e: any) => e.data !== "";

const completionConcat = (char: string, bodyId = 1) =>
  putResolve({
    type: ACTION.LOCAL.APP_STATE.COMPLETION.CONCAT,
    payload: { newString: char, bodyId },
  });

const resetPromptWarningSecondBody = () => {
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: {
      field: "promptWarningSecondBody",
      newContent: "0",
    },
  });
};
const resetPromptWarningFirstBody = () => {
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: {
      field: "promptWarningFirstBody",
      newContent: "0",
    },
  });
};

const splitIntoChars = (text: string) => call((x: any) => x.split(""), text);

const outputChars = (chars: string[], pill = false, bodyId = 1) =>
  all(
    chars.map((char: string) =>
      putResolve({
        type: ACTION.SAGAS.EVENT_STREAM.CONCAT,
        payload: { newString: char, pill, bodyId },
      })
    )
  );

// output text into frontend
function* output(text: string, pill = false, bodyId: number): any {
  const loading = yield select((state) => state.appState.loading);
  if (loading) {
    const chars = yield splitIntoChars(text);
    yield outputChars(chars, pill, bodyId);
  }
}

const loadingIndicatorDisable = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.DISABLE,
    payload: { field: "loading" },
  });

const loadingIndicatorEnable = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.ENABLE,
    payload: { field: "loading" },
  });

function* loadingFieldEnable(field: 1 | 2 | 3) {
  yield put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: `loading_${field}`, newContent: true },
  });
}

function* loadingFieldDisable(field: 1 | 2 | 3) {
  yield put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: `loading_${field}`, newContent: false },
  });
}

function* allLoadingFieldsEnable() {
  yield all(
    [1, 2, 3].map((field) =>
      put({
        type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
        payload: { field: `loading_${field}`, newContent: true },
      })
    )
  );
}

function* allLoadingFieldsDisable() {
  yield all(
    [1, 2, 3].map((field) =>
      put({
        type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
        payload: { field: `loading_${field}`, newContent: false },
      })
    )
  );
}

function* disableAllActiveStreams() {
  yield all(
    [1, 2, 3].map((field) =>
      put({
        type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
        payload: { field: `active_stream_${field}`, newContent: false },
      })
    )
  );
}

const updateScore1 = (score_1: number) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "score_1", newContent: score_1 },
  });

const updateScore2 = (score_2: any) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "score_2", newContent: score_2 },
  });

const updateScore3 = (score_3: any) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "score_3", newContent: score_3 },
  });

const dbMigrationState = (state: MigratorFlag) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: {
      field: "db_migration_flag",
      newContent: state,
    },
  });

const getCompletionBody = () =>
  select((state) => state.appState.completion_body_1);

const isProperMessage = (messageEvent: any): boolean =>
  notEmpty(messageEvent) && notDateTime(messageEvent);

const closeStream = (source: any) => call((x) => x.close(), source);
const closeChannel = (channel: any) => call((x) => x.close(), channel);

const streamError = () => put({ type: ACTION.SAGAS.EVENT_STREAM.ERROR });
const sendRateLimitError = (expire: any) =>
  put({
    type: ACTION.SAGAS.APP_STATE.RATE_LIMIT.ERROR,
    payload: { expire },
  });
const sendSafetyError = (body_label: any, safety_category: any) =>
  put({
    type: ACTION.SAGAS.APP_STATE.SAFETY.ERROR,
    payload: { body_label, safety_category },
  });
const flowriteModeEnable = () =>
  put({ type: ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.ENABLE });
const flowriteModeDisable = () =>
  put({ type: ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.DISABLE });

const saveTemplateName = (tmpName: string) =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "template_name", newContent: tmpName },
  });

const sendEmptyGenerationError = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
    payload: { id: "emptyGenerationWarning", targetState: true },
  });

const sendAppError = (
  type: any = "Error",
  message: any = "An unkown error occured"
) => {
  window.analytics.track("Generation Error Frontend", {
    type: type === null ? "Stream Error" : type,
    message: message === null ? "An unknown error occured" : message,
    environment: process.env.REACT_APP_APP_ENV,
  });
  return put({
    type: ACTION.ERROR.APP_ERROR.SET,
    payload: {
      type: type === null ? "Error" : type,
      message: message === null ? "An unknown error occured" : message,
    },
  });
};

const startStream = (source: any) =>
  put({
    type: ACTION.SAGAS.EVENT_STREAM.START,
    payload: { source },
  });

const prepareSig = ({ selectedSignature, firstName, lastName }: any) => {
  const signature = selectedSignature || "Best,\n:first:";

  return `\n\n${signature
    .replace(":first:", firstName)
    .replace(":last:", lastName)}
  `;
};

const trimWhiteSpace = (decoded: string, data: any, indexed: number) => {
  if (data.index === indexed && data.type === "completion") {
    return decoded.replace(/^\s+/g, "");
  }
  return decoded;
};

const getUseCases = (templateSet: any, galleryTemplatesLocal: any): any => {
  if (templateSet) {
    const selectedTemplates = templateSet
      .map((item: any) => {
        const found = galleryTemplatesLocal.find((gt: any) => gt.name === item);
        if (found) {
          return {
            ...found,
            id: key(),
            template_collection_id: found.template_id,
          };
        }
        return null;
      })
      .filter(Boolean);
    return selectedTemplates;
  } else {
    return [];
  }
};

const resetPreviousBody = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
    payload: { field: "previous_second_body", newContent: "" },
  });

const clearGenerationSecondBodies = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.SECOND_BODY.CLEAR,
    payload: {},
  });

const clearCompletionBodies = () =>
  put({
    type: ACTION.LOCAL.APP_STATE.COMPLETION.CLEAR,
    payload: {},
  });

/* refreshes bodies to a fresh state */
function* resetBodies(): any {
  try {
    const prev = yield select((state) => state.appState.previous_second_body);
    yield secondBodyUpdate(prev);
    yield resetPreviousBody();
    yield clearGenerationSecondBodies();
    yield clearCompletionBodies();
  } catch (e) {
    yield call(console.log, e);
  }
}

function* timeoutError(source: any) {
  try {
    yield closeStream(source);
    yield sendAppError(
      "Timeout Error",
      "The generation timed out, please try again"
    );

    const { second_body_1, second_body_2, second_body_3 } = yield select(
      (state) => state.appState
    );
    /* go back if no output */
    if (
      second_body_1.length === 0 &&
      second_body_2.length === 0 &&
      second_body_3.length === 0
    ) {
      yield resetPromptWarningSecondBody();
      yield resetBodies();
      yield promptStateUpdate(0);
    }

    yield flowriteModeDisable();
    yield loadingIndicatorDisable();
    yield feedbackModeDisable();
    yield allLoadingFieldsDisable();
    yield disableAllActiveStreams();
  } catch (error) {
    yield call(console.log, error);
  }
}

const doesUserExist = (user: any) =>
  user !== undefined &&
  user.data.db_account_settings &&
  user.data.db_flags &&
  user.data.db_account_settings[0] &&
  user.data.db_flags[0];

function* getToken(): any {
  try {
    const userSession: UserSession = yield call([Auth, Auth.currentSession]);

    if ("idToken" in userSession) {
      return userSession.idToken.jwtToken;
    }
    return "";
  } catch (e) {
    yield call(console.log, "exception while fetching token");
    yield call(console.log, e);
    return "";
  }
}

const billingPriceUpdate = (value: boolean) =>
  put({
    type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
    payload: { field: "price_id", newContent: value },
  });

const billingLoadingUpdate = (value: boolean) =>
  put({
    type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
    payload: { field: "loading", newContent: value },
  });

export {
  getToken,
  doesUserExist,
  createAccountSettings,
  getUseCases,
  resetPromptWarningSecondBody,
  resetPromptWarningFirstBody,
  toArr,
  sendAppError,
  toArrFlat,
  filterUseCaseName,
  sortByIndexFlat,
  sortDescByIndexFlat,
  sortDescByCreatedAtDate,
  removeImported,
  immutableMove,
  stampIndices,
  decodeReceived,
  notDateTime,
  notEmpty,
  outputChars,
  splitIntoChars,
  completionConcat,
  getCompletionBody,
  isProperMessage,
  output,
  flowriteModeDisable,
  flowriteModeEnable,
  streamError,
  sendRateLimitError,
  startStream,
  closeStream,
  closeChannel,
  validateNewLineAndFullSopRegex,
  setSeedId,
  setGenId,
  feedbackModeEnable,
  feedbackModeDisable,
  prepareEnd,
  prepareSig,
  trimWhiteSpace,
  sendSafetyError,
  loadingIndicatorDisable,
  loadingIndicatorEnable,
  sendEmptyGenerationError,
  updateDevData,
  saveTemplateName,
  promptStateUpdate,
  secondBodyUpdate,
  firstBodyUpdate,
  appStateAnyFieldIntUpdate,
  dbMigrationState,
  updateScore1,
  updateScore2,
  updateScore3,
  interruptUpdate,
  loadingFieldEnable,
  loadingFieldDisable,
  allLoadingFieldsEnable,
  allLoadingFieldsDisable,
  billingLoadingUpdate,
  billingPriceUpdate,
  timeoutError,
};
