import { CompletionLength } from "../../constants/common";
import { ObSurveyFields } from "../../services/hasura/ob_survey";
import { OnboardingSteps } from "../../services/hasura/types";
import { UseCase } from "./use-cases";

/* eslint-disable @typescript-eslint/no-explicit-any */
type PromptWarning = "0" | "1" | "2";

type Visibilities = {
  configList: boolean;
  emojiPicker: boolean;
  configEditDialog: boolean;
  configAddDialog: boolean;
  sideBar: boolean;
  sideBarSubMenu: boolean;
  sideBarDropdownMenu: boolean;
  notificationsMenu: boolean;
  profileMenu: boolean;
  useCaseMenu: boolean;
  playground: boolean;
  inviteDialog: boolean;
  supportDialog: boolean;
  wrongInputToolTip: boolean;
  deleteWarning: boolean;
  saveConfirmation: boolean;
  emptyGenerationWarning: boolean;
  flowriteButtonList: boolean;
  extensionDialog: boolean;
  feedbackPopUp: boolean;
  feedbackPopUpOther: boolean;
  mailMaestroDialog:boolean;
};

type KeyboardShortcuts = {
  shiftNumber: boolean;
  shiftLeft: boolean;
  shiftRight: boolean;
  shiftEnter: boolean;
  shiftSpace: boolean;
  shiftUp: boolean;
};

type IdentityProperties = {
  companyName: string;
  companyDescription: string;
  role: string;
  processedCompanyName: string;
  processedCompanyDescription: string;
  processedRole: string;
  firstPreposition: string;
  secondPreposition: string;
};

export type Flags = {
  vipInviteCount: number;
  feedbackCount: number;
  onboarding_step: number;
  steps_completed: number;
  onboarding_step_text: string;
  step_completed_text: string | null;
  new_identity_added: boolean;
  onboarding_mode: boolean;
  checkList: string[];
  extension_install: boolean;
  migration_completed: boolean;
  first_usage_bumper: boolean;
  generation_limit_exceeded: boolean;
  generation_count: number;
  left_over_generation_limit_from_old_plan: number;
};

type Creativity = "Low" | "Medium" | "High";
type Length = "Low" | "Medium" | "High";

type AccountSettings = {
  darkMode: boolean;
  email: string;
  creativity: Creativity;
  completion_length: Length;
  identity: string;
  identityProperties: IdentityProperties;
  firstName: string;
  lastName: string;
  flags: Flags;
  bootstrapped: boolean;
  selectedGreeting: string;
  selectedSignature: string;
  flowrittenTag: string;
  keyboardShortcuts: KeyboardShortcuts;
  developmentMode: boolean;
  logOut: boolean;
  usageReason: string[];
  defaultUseCasesName: string;
  extension_disabled_integrations: string[];
  obSurvey: ObSurveyFields;
};

type Seed = 0;
type Regeneration = 1;
type Reply = 3;
type PromptState = Seed | Regeneration | number;
interface SafetyWarningObject {
  [key: string]: PromptWarning;
}
type WarnedList = SafetyWarningObject[];
type GenerationId = string;
type SeedId = string;

export type BillingPlanItem = {
  id: string;
  active: boolean;
  amount: number;
  interval: string;
  currency: string;
  nickname: string;
  product: string;
};
export type ReferralCoupon = {
  id: string;
  amount_off: string;
  created: number;
  currency: string;
  duration: string;
  duration_in_months: string;
  livemode: boolean;
  max_redemptions: number;
  metadata: any;
  name: string;
  percent_off: number;
  redeem_by: any;
  times_redeemed: number;
  valid: boolean;
};
export type BillingReferralDiscount = {
  coupon: ReferralCoupon;
  customer: string;
  end: number;
  invoice: string;
  invoice_item: any;
  promotion_code: string;
  start: number;
  subscription: any;
};

export type BillingReferralMetadata = {
  cello_ucc: string;
  productUserId: string;
};

export type BillingReferral = {
  discount: BillingReferralDiscount;
  metadata: BillingReferralMetadata;
};

export type PlanLimit = {
  id: string;
  generation_limit: number;
  interval: string;
  name: string;
  stripe_product_id: string;
  rank: number;
};

type ScheduledSubscriptionPhaseItem = {
  price: string;
  quantity: number;
};
export type ScheduledSubscriptionPhase = {
  billing_cycle_anchor: number;
  currency: string;
  end_date: number;
  items: ScheduledSubscriptionPhaseItem[];
  start_date: number;
};

export type ScheduledSubscription = {
  id: string;
  created: number;
  customer: string;
  end_behavior: string;
  phases: ScheduledSubscriptionPhase[];
  status: string;
};

export type BillingDetails = {
  loading: boolean;
  stripe_customer_id: string;
  default_currency: string;
  subscription_id: string;
  price_id: string;
  plans: BillingPlanItem[];
  customer_plan: BillingPlanItem;
  plan_limit: PlanLimit;
  referral: BillingReferral;
  scheduled_subscription: ScheduledSubscription;
  status: string;
  cohort_id: string;
  trial_end: number;
  trial_start: number;
  trial_days: number;
  current_period_end: number;
  current_period_start: number;
  cancel_at_period_end: boolean;
};

export enum MigratorFlag {
  USER_NOT_IN_FIREBASE = "USER_NOT_IN_FIREBASE",
  USER_EXISTS = "USER_EXISTS",
  REQUEST_PENDING = "REQUEST_PENDING",
  REQUEST_NOT_INITIATED = "REQUEST_NOT_INITIATED",
  REQUEST_ERROR = "REQUEST_ERROR",
}

export type AppState = {
  db_migration_flag: MigratorFlag;
  loading: boolean;
  flowriting: boolean;
  feedback: string;
  gen_id: GenerationId;
  seed_id: SeedId;
  flowrite_mode: string;
  active_stream_1: boolean;
  active_stream_2: boolean;
  active_stream_3: boolean;
  loading_1: boolean;
  loading_2: boolean;
  loading_3: boolean;
  previousUseCase: UseCase | null;
  promptWarningFirstBody: PromptWarning;
  promptFirstBodyCharacterLimit: boolean;
  promptCharacterLimit: boolean;
  promptWarningSecondBody: PromptWarning;
  safetyWarnedSentences: WarnedList;
  safetyWarnedSettings: WarnedList;
  safetyWarnedTemplateFields: WarnedList;
  coolOffMode: boolean;
  coolOffSeconds: number;
  promptState: PromptState;
  reply: boolean;
  signature: boolean;
  inline_reply: boolean;
  signature_as_string: string;
  inline_reply_as_string: string;
  beginning_content_to_flush: string;
  prompt_preview: string;
  receivedCompletion: string;
  recipient: string;
  template_name: string;
  completion_body: string;
  completion_body_1: string;
  completion_body_2: string;
  completion_body_3: string;
  helper_prefix: string;
  previous_second_body: string;
  first_body: string;
  score_1: number;
  score_2: number;
  score_3: number;
  second_body: string;
  second_body_1: string;
  second_body_2: string;
  second_body_3: string;
  auto_suggestion: boolean;
  role: string;
  dev_data: any;
  completion_length: string;
  useCaseId: string;
  completion_count: number;
  regeneration: boolean;
  visibilities: Visibilities;
  defaultSettings: AccountSettings;
  settings: AccountSettings;
  billing: BillingDetails;
};

const appState: any = {
  // generic appState not in firebase
  db_migration_flag: MigratorFlag.REQUEST_NOT_INITIATED,
  loading: false,
  flowriting: false,
  reply: false,
  feedback: "",
  promptState: 0,
  active_stream_1: false,
  active_stream_2: false,
  active_stream_3: false,
  loading_1: false,
  loading_2: false,
  loading_3: false,
  flowrite_mode: "message",
  previousUseCase: null,
  promptWarningFirstBody: "0",
  promptCharacterLimit: false,
  promptFirstBodyCharacterLimit: false,
  promptWarningSecondBody: "0",
  safetyWarnedSentences: [],
  safetyWarnedSettings: [],
  safetyWarnedTemplateFields: [],
  coolOffMode: false,
  coolOffSeconds: 0,
  seed_id: "", // -> continued: highlight yellow and pink
  gen_id: "",
  beginning_content_to_flush: "",
  prompt_preview: "",
  receivedCompletion: "",
  recipient: "",
  score_1: 0,
  score_2: 0,
  score_3: 0,
  completion_body: "",
  completion_body_1: "",
  completion_body_2: "",
  completion_body_3: "",
  helper_prefix: "",
  previous_second_body: "",
  first_body: "",
  second_body: "",
  second_body_1: "",
  second_body_2: "",
  second_body_3: "",
  auto_suggestion: false,
  role: "",
  dev_data: "",
  template_name: "",
  useCaseId: "",
  completion_count: 0,
  regeneration: false,
  // make these typed states
  visibilities: {
    configList: false,
    emojiPicker: false,
    configEditDialog: false,
    configAddDialog: false,
    sideBar: false,
    sideBarSubMenu: false,
    sideBarDropdownMenu: false,
    notificationsMenu: false,
    profileMenu: false,
    useCaseMenu: true,
    playground: false,
    inviteDialog: false,
    supportDialog: false,
    deleteWarning: false,
    saveConfirmation: false,
    wrongInputToolTip: false,
    templateCollection: false,
    emptyGenerationWarning: false,
    flowriteButtonList: false,
    extensionDialog: false,
    feedbackPopUp: false,
    feedbackPopUpOther: false,
    recipientLoading: false,
    mailMaestroDialog: false,
  },

  // bootstrap settings
  defaultSettings: {
    darkMode: false, // extract from Chrome settings
    email: "",
    identity: "",
    creativity: "Medium",
    completion_length: CompletionLength.EXPANDED,
    temperature: 5,
    identityProperties: {
      companyName: "",
      companyDescription: "",
      role: "",
      firstPreposition: "at",
      secondPreposition: "which is",
    },
    flags: {
      vipInviteCount: 0,
      feedbackCount: 0,
      onboarding_step: 1,
      steps_completed: 0,
      onboarding_step_text: OnboardingSteps.Install,
      step_completed_text: null,
      new_identity_added: true,
      onboarding_mode: true,
      checkList: ["setup"],
      extension_install: false,
      migration_completed: true,
      first_usage_bumper: false,
      generation_limit_exceeded: false,
      generation_count: 0,
      left_over_generation_limit_from_old_plan: 0,
    },
    firstName: "",
    lastName: "",
    bootstrapped: false,
    selectedGreeting: `Hi :recipient:,`,
    selectedSignature: `Best,\n:first:`,
    flowrittenTag: `Flowritten`,
    keyboardShortcuts: {
      shiftNumber: true,
      shiftLeft: true,
      shiftRight: true,
      shiftEnter: true,
      shiftSpace: true,
      shiftUp: true,
    },
    developmentMode: false,
    logOut: false,
    usageReason: [],
    defaultUseCasesName: "",
    extension_disabled_integrations: [],
    obSurvey: {},
  },

  // loaded from firebase, user specific settings
  settings: {
    darkMode: false,
    email: "",
    creativity: "Medium",
    completion_length: CompletionLength.EXPANDED,
    temperature: 5,
    identity: "",
    identityProperties: {
      companyName: "",
      companyDescription: "",
      role: "",
      processedCompanyName: "",
      processedCompanyDescription: "",
      processedRole: "",
      firstPreposition: "at",
      secondPreposition: "which is",
    },
    flags: {
      vipInviteCount: 0,
      feedbackCount: 0,
      onboarding_step: 1,
      steps_completed: 0,
      onboarding_step_text: OnboardingSteps.Install,
      step_completed_text: null,
      new_identity_added: true,
      onboarding_mode: false,
      checkList: ["setup"],
      extension_install: false,
      migration_completed: false,
      first_usage_bumper: false,
    },
    firstName: "",
    lastName: "",
    bootstrapped: false,
    selectedGreeting: `Hi :recipient:,`,
    selectedSignature: `Best,\n:first:`,
    flowrittenTag: `Flowritten`,
    keyboardShortcuts: {
      shiftNumber: true,
      shiftLeft: true,
      shiftRight: true,
      shiftEnter: true,
      shiftSpace: true,
      shiftUp: true,
    },
    developmentMode: false,
    logOut: false,
    usageReason: [],
    defaultUseCasesName: "",
    extension_disabled_integrations: [],
    obSurvey: {},
  },
  billing: {
    loading: true,
    stripe_customer_id: "",
    subscription_id: "",
    default_currency: "",
    price_id: "",
    plans: [],
    customer_plan: {
      id: "",
      active: false,
      amount: 0,
      currency: "",
      interval: "",
    },
    plan_limit: {
      id: "",
      generation_limit: null,
      interval: "",
      name: "",
      stripe_product_id: "",
      rank: 0,
    },
    referral: {
      discount: null,
      metadata: {},
    },
    scheduled_subscription: {},
    status: "",
    cohort_id: "",
    trial_end: 0,
    trial_start: 0,
    trial_days: 14,
    current_period_end: 0,
    current_period_start: 0,
  },
};

export default appState;
