import DB from "../constants";
import createHasuraRequest from "../utils";

type FeedbackData = {
  option: string;
  feedback: string;
  gen_id: string;
  seed_id: string;
};

export type Feedback = {
  create: (data: FeedbackData, token: string) => any;
};

const feedback: Feedback = {
  create: async (data, token) => {
    const request = createHasuraRequest(DB.GENERATION.FEEDBACK.CREATE, token);
    const parameters = {
      objects: {
        ...data,
      },
    };
    return await request(parameters);
  },
};

export { feedback };
