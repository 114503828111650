import { delay, put } from "redux-saga/effects";
import AuthState from "../../../../../../actions/local/auth";

function* onDefaultException(exception: any) {
  /* data trigger on failure */
  /* display error message */
  console.log("Exception in hub: sign-in-failure: ", exception);

  yield put(
    AuthState.Credentials.failure(
      "An error occurred while authenticating! Redirecting to login."
    )
  );
  yield delay(2000);
  /* reset state and redirect to login to attempt again */
  yield put(AuthState.Credentials.setEmpty());
}

export default onDefaultException;
