import DB from "../constants";
import createHasuraRequest from "../utils";

export type SuggestionsEnabled = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const suggestions_enabled: SuggestionsEnabled = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.SUGGESTIONS_ENABLED.UPDATE,
      token
    );
    const parameters = {
      uid,
      suggestions_enabled: newContent,
    };
    return await request(parameters);
  },
};

export { suggestions_enabled };
