export const CHARACTER_LIMIT = 250;

export enum CompletionLength {
  STANDARD = "Standard",
  EXPANDED = "Expanded",
}

export enum EXTENSION_ID {
  PRODUCTION = "djgmcffbbnhhlehfnfdhjnimphikngne",
  DEV_STAGING = "jkmdeffdfnbljonaiegfjjcfokdbkgnl",
}
