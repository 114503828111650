/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */

import { takeEvery, call, all, select, put } from "typed-redux-saga";
import db from "../../services/hasura";
import stripe from "../../services/api/routes/billing";
import SAGAS from "../actions/sagas/constants";
import { billingLoadingUpdate, getToken } from "./utils/helpers";
import {
  fetchCountry,
  getAllActiveStripeProducts,
  getPriceId,
} from "../reducers/utils";

import { ACTION } from "../actions";
import logger from "../../logger";

function* createCustomer(action: any): any {
  try {
    const uid: string = yield select((state) => state.auth.uid);
    const token: string = yield getToken();
    const { email, firstName, lastName } = yield select(
      (state) => state.appState.settings
    );

    const { price_id } = yield select((state) => state.appState.billing);
    // default price in stripe (for production)

    const payload_price_id = price_id ? price_id : getPriceId();

    const name = `${firstName} ${lastName}`;

    const ipDetails = yield call(fetchCountry);

    const indian_user =
      ipDetails && ipDetails.country_code2 === "IN" ? true : false;

    if (!uid || !email || !payload_price_id || !name) {
      yield call(console.log, `Missing info in billing createCustomer saga`);
      return;
    }
    const extended_trial = sessionStorage.getItem("extended_trial") === "true";
    const response = yield call(
      stripe.customer.create,
      uid,
      name,
      token,
      email,
      payload_price_id,
      indian_user,
      extended_trial
    );

    yield put({
      type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
      payload: {
        field: "stripe_customer_id",
        newContent: response.customer.id,
      },
    });
    yield put({
      type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
      payload: {
        field: "subscription_id",
        newContent: response.subscription.id,
      },
    });
    yield put({
      type: ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE,
      payload: { field: "plan_id", newContent: response.subscription.plan.id },
    });

    sessionStorage.removeItem("extended_trial");
  } catch (error) {
    yield call(console.log, `Error in billing createCustomer saga, ${error}`);
  }
}

function* readCustomer(): any {
  try {
    const uid: string = yield select((state) => state.auth.uid);
    const token: string = yield getToken();

    yield billingLoadingUpdate(true);

    const userBilling = yield call(db.billing.read, uid, token);
    // console.log("userBilling", userBilling);

    if (userBilling.data.db_billing[0]) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING.GENERATION_COUNT.UPDATE,
        payload: { data: userBilling.data.db_billing[0] },
      });
      const { stripe_id, subscription_id } = userBilling.data.db_billing[0];

      // yield call(console.log, `readCustomer stripe_customer_id`);
      // yield call(console.log, stripe_id);
      // yield call(console.log, `readCustomer subscription_id`);
      // yield call(console.log, subscription_id);

      const response = yield call(
        stripe.customer.read,
        uid,
        stripe_id,
        subscription_id,
        token
      );

      // console.log(response, "response readCustomer");
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING.UPDATE,
        payload: { data: response },
      });
    }
    yield billingLoadingUpdate(false);
  } catch (error) {
    yield call(console.log, `Error in billing readCustomer saga, ${error}`);
    yield billingLoadingUpdate(false);
  }
}

function* portalSessionCreate(): any {
  try {
    const token: string = yield getToken();
    const uid: string = yield select((state) => state.auth.uid);
    const { stripe_customer_id } = yield select(
      (state) => state.appState.billing
    );

    // yield call(console.log, `portalSessionCreate`);
    const response = yield call(
      stripe.customer.portalSession.create,
      uid,
      stripe_customer_id,
      token
    );
    // yield call(console.log, `portalSessionCreate end`, response);

    const win = window.open(response, "_self");
    if (win) {
      win.focus();
    }
  } catch (error) {
    yield call(
      console.log,
      `Error in billing portalSessionCreate saga, ${error}`
    );
  }
}

function* checkoutSessionCreate(action: any): any {
  const { price_id, return_url } = action.payload;
  try {
    const token: string = yield getToken();
    const { stripe_customer_id } = yield select(
      (state) => state.appState.billing
    );
    const uid: string = yield select((state) => state.auth.uid);

    if (token && stripe_customer_id) {
      const response = yield call(
        stripe.customer.checkoutSession.create,
        uid,
        stripe_customer_id,
        price_id,
        return_url,
        token
      );

      /* Add rollbar logging */
      if (
        typeof response === "object" &&
        "code" in response &&
        response.code === "invalid_stripe_id"
      )
        return;
      
      const url = typeof response === "string" ? response : response.url;
      const win = window.open(url, "_self");
      if (win) {
        win.focus();
      }
    }
  } catch (error) {
    yield call(
      console.log,
      `Error in billing checkoutSessionCreate saga, ${error}`
    );
  }
}

function* readPlans(): any {
  try {
    const token: string = yield getToken();
    const { stripe_customer_id } = yield select(
      (state) => state.appState.billing
    );

    const response = yield call(
      stripe.customer.plans.read,
      stripe_customer_id,
      token
    );

    const activeStripeProducts = getAllActiveStripeProducts();

    const plans = [];
    for (let i = 0; i < response.plans.data.length; i++) {
      if (activeStripeProducts.includes(response.plans.data[i].product)) {
        plans.push(response.plans.data[i]);
      }
    }
    if (plans.length > 0) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING.PLANS.UPDATE,
        payload: { data: plans },
      });
    }
  } catch (error) {
    yield call(console.log, `Error in billing readPlans saga, ${error}`);
  }
}

// function* readPlan(): any {
//   try {
//     const uid: string = yield select((state) => state.auth.uid);
//     const token: string = yield getToken();
//     const { stripe_customer_id, price_id } = yield select(
//       (state) => state.appState.billing
//     );

//     yield call(console.log, `readPlan`);

//     const response = yield call(
//       stripe.customer.plan.read,
//       uid,
//       stripe_customer_id,
//       price_id,
//       token
//     );

//     yield call(console.log, `readPlan response`, response);
//     yield call(console.log, `readPlan response`, response.plan);
//     yield put({
//       type: ACTION.LOCAL.APP_STATE.BILLING.PLAN.UPDATE,
//       payload: { data: response.plan },
//     });
//   } catch (error) {
//     yield call(console.log, `Error in billing readPlans saga, ${error}`);
//   }
// }

function* checkoutSuccess(action: any): any {
  const { session_id } = action.payload;
  try {
    const token: string = yield getToken();
    const uid: string = yield select((state) => state.auth.uid);
    const stripe_customer_id: string = yield select(
      (state) => state.appState.billing.stripe_customer_id
    );

    yield billingLoadingUpdate(true);

    // yield call(console.log, `checkoutSuccess`);
    // yield call(console.log, "session_id", session_id);

    yield call(
      stripe.customer.stripeId.update,
      uid,
      stripe_customer_id,
      session_id,
      token
    );

    yield readCustomer();
  } catch (error) {
    yield call(console.log, `Error in billing updateStripeId saga, ${error}`);
  }
}

function* checkSubscription(): any {
  try {
    const token: string = yield getToken();
    const uid: string = yield select((state) => state.auth.uid);
    const { stripe_customer_id } = yield select(
      (state) => state.appState.billing
    );

    const response = yield call(
      stripe.customer.checkSubscription,
      uid,
      stripe_customer_id,
      token
    );

    // yield call(console.log, `checkSubscription response`, response);
    if (response) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING.CHECK_SUBSCRIPTION,
        payload: { data: response },
      });
    }
  } catch (error) {
    yield call(
      console.log,
      `Error in billing checkSubscription saga, ${error}`
    );
  }
}

function* createScheduledSubscription(action: any): any {
  try {
    const { price_id } = action.payload;
    const token: string = yield getToken();
    const uid: string = yield select((state) => state.auth.uid);
    const { stripe_customer_id } = yield select(
      (state) => state.appState.billing
    );
    const response = yield call(
      stripe.customer.createScheduledSubscription,
      uid,
      stripe_customer_id,
      price_id,
      token
    );
    if (response) {
      yield put({
        type: ACTION.LOCAL.APP_STATE.BILLING.CREATE_SCHEDULED_SUBSCRIPTION,
        payload: { data: response },
      });
    }
  } catch (e) {
    yield call(
      console.log,
      `Error in billing createScheduledSubscription saga, ${e}`
    );
  }
}

function* billingSaga(): any {
  yield all([
    takeEvery(SAGAS.BILLING.CUSTOMER.CREATE, createCustomer),
    takeEvery(SAGAS.BILLING.CUSTOMER.READ, readCustomer),
    takeEvery(SAGAS.BILLING.PORTAL_SESSION.CREATE, portalSessionCreate),
    takeEvery(SAGAS.BILLING.CHECKOUT_SESSION.CREATE, checkoutSessionCreate),
    takeEvery(SAGAS.BILLING.PLANS.READ, readPlans),
    takeEvery(SAGAS.BILLING.CUSTOMER.CHECKOUT_SUCCESS, checkoutSuccess),
    takeEvery(SAGAS.BILLING.CUSTOMER.CHECK_SUBSCRIPTION, checkSubscription),
    takeEvery(
      SAGAS.BILLING.CUSTOMER.CREATE_SCHEDULED_SUBSCRIPTION,
      createScheduledSubscription
    ),
  ]);
}

export default billingSaga;
