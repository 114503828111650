import { makeRequest } from "../../helpers";
import apiRoutes from "../constants";

export type AuthRequests = {
  bouncer: (email: string, uid: string) => any;
};

const authRequests: AuthRequests = {
  bouncer: (email, uid) =>
      makeRequest(apiRoutes.auth.bouncer, { email, uid}).unauthenticated(),
};

export default authRequests;
