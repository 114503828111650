const EVENT_STREAM = {
  REQUEST: {
    CREATE: "ACTION.SAGAS.EVENT_STREAM.REQUEST.CREATE",
    SEND: "ACTION.SAGAS.EVENT_STREAM.REQUEST.SEND",
    RESEND: "ACTION.SAGAS.EVENT_STREAM.REQUEST.RESEND",
  },
  ONBOARDING: {
    CREATE: "ACTION.SAGAS.EVENT_STREAM.ONBOARDING.CREATE",
  },
  MULTI_GENERATION: {
    CREATE: "ACTION.SAGAS.EVENT_STREAM.MULTI_GENERATION.CREATE",
  },
  STOP: "ACTION.SAGAS.EVENT_STREAM.STOP",
  CONCAT_1: "ACTION.SAGAS.EVENT_STREAM.CONCAT_1",
  CONCAT_2: "ACTION.SAGAS.EVENT_STREAM.CONCAT_2",
  CONCAT_3: "ACTION.SAGAS.EVENT_STREAM.CONCAT_3",
  CONCAT: "ACTION.SAGAS.EVENT_STREAM.CONCAT",
  START: "ACTION.SAGAS.EVENT_STREAM.START",
  SSE_REQUEST: "ACTION.SAGAS.EVENT_STREAM.SSE_REQUEST",
  ERROR: "ACTION.SAGAS.EVENT_STREAM.ERROR",
  INTERRUPT: "ACTION.SAGAS.EVENT_STREAM.INTERRUPT",
};

export default EVENT_STREAM;
