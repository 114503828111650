const COPY_USED = {
  UPDATE: `
          mutation updateCopyUsedFlag($uid: String!, $copy_used: Boolean) {
              update_db_flags(where: {id: {_eq: $uid}}, _set: {copy_used: $copy_used}) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
};

export default COPY_USED;
