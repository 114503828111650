import onCompletion from "./on-completion";
import onEnd from "./on-end";
import onError from "./on-error";
import onInterrupt from "./on-interrupt";
import onCustomStop from "./on-custom-stop-word";
import onSignature from "./on-signature";
import onStopWord from "./on-stopword";
import onRateLimitError from "./on-rate-limit-error";
import onOnboarding from "../../on-onboarding";

const handlers = {
  onCompletion,
  onCustomStop,
  onEnd,
  onError,
  onInterrupt,
  onSignature,
  onStopWord,
  onRateLimitError,
  onOnboarding,
};

export default handlers;
