/* eslint-disable import/no-cycle */
import UseCases, { SagasUseCasesRepo } from "./use-cases";
import UseCase, { SagasUseCaseRepo } from "./use-case";
import EventStream, { SagasEventStreamRepo } from "./event-stream";
import AppState, { SagasAppStateRepo } from "./app-state";
import Flags, { SagasFlagsRepo } from "./flags";
import Billing, { SagasBillingRepo } from "./billing";
import StorageState, { SagasStorageStateRepo } from "./local-storage";
import Auth, { SagasAuthStateRepo } from "./auth";
import BroadcastChannel, {
  SagasBroadcastChannelRepo,
} from "./broadcast-channel";
import GenerationHistory, {
  SagasGenerationHistoryRepo,
} from "./generation-history";

export type SagasRepo = {
  UseCase: SagasUseCaseRepo;
  UseCases: SagasUseCasesRepo;
  EventStream: SagasEventStreamRepo;
  AppState: SagasAppStateRepo;
  Flags: SagasFlagsRepo;
  StorageState: SagasStorageStateRepo;
  Billing: SagasBillingRepo;
  Auth: SagasAuthStateRepo;
  BroadcastChannel: SagasBroadcastChannelRepo;
  GenerationHistory: SagasGenerationHistoryRepo;
};

const Sagas: SagasRepo = {
  UseCase,
  UseCases,
  EventStream,
  AppState,
  Flags,
  StorageState,
  Billing,
  Auth,
  BroadcastChannel,
  GenerationHistory,
};

export default Sagas;
