/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select } from "redux-saga/effects";
import {
  getCompletionBody,
  isProperMessage,
  output,
} from "../../utils/helpers";
import createGreeting from "../../utils/greeting";
import handlers from "./handlers";
import onSafetyError from "./handlers/on-safety-error";
import onError from "./handlers/on-error";
import { ACTION } from "../../../actions";

const {
  onCompletion,
  onSignature,
  onEnd,
  onInterrupt,
  onCustomStop,
} = handlers;

function* messageConsumer(source: any, payload: any): any {
  try {
    // create the essentials
    const completionBody = yield getCompletionBody();

    // if completionBody is empty we must be in the beginning
    // and the message received is the first one
    // therefore wait 1.5s before starting output
    // to buy us some time

    // we ignore messages that aren't proper: ones that randomly have timestamps as payload
    // (no idea where they are from) and empty messages
    if (isProperMessage(payload)) {
      // run the received encoded-for-transport text through replace function
      // in order to decode it back to original
      // we do this because the SSE transport seems to lose
      // spaces and newline characters
      // parse the received data
      const data = yield call(JSON.parse, payload.data);

      if (completionBody.length === 0 && data.type.includes("stream")) {
        const appState = yield select((state) => state.appState);
        const greeting: string = yield createGreeting(appState);
        yield output(greeting, false, 1);
        yield output(greeting, false, 2);
        yield output(greeting, false, 3);
        yield put({
          type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
          payload: { field: "active_stream_1", newContent: true },
        });
        yield put({
          type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
          payload: { field: "active_stream_2", newContent: true },
        });
        yield put({
          type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
          payload: { field: "active_stream_3", newContent: true },
        });
      }

      switch (data.type) {
        case "stream1":
          yield onCompletion(data, 1);
          break;

        case "stream2":
          yield onCompletion(data, 2);
          break;

        case "stream3":
          yield onCompletion(data, 3);
          break;

        case "signature1":
          yield onSignature(source, data, 1);
          break;

        case "signature2":
          yield onSignature(source, data, 2);
          break;

        case "signature3":
          yield onSignature(source, data, 3);
          break;

        case "error":
          yield onError(source, data);
          break;

        case "safety_error":
          yield onSafetyError(source, data);
          break;

        case "custom_stop_error1":
          yield onCustomStop(source, data, 1);
          break;

        case "custom_stop_error2":
          yield onCustomStop(source, data, 2);
          break;

        case "custom_stop_error3":
          yield onCustomStop(source, data, 3);
          break;

        case "end1":
          yield onEnd(source, data, 1);
          break;

        case "end2":
          yield onEnd(source, data, 2);
          break;

        case "end3":
          yield onEnd(source, data, 3);
          break;

        default:
          yield onInterrupt(source);
      }
    }
  } catch (error) {
    yield call(console.log, error);
  }
}

export default messageConsumer;
