import SAGAS from "./constants";

export type SagasStorageStateRepo = {
  update(): any;
};

const StorageState: SagasStorageStateRepo = {
  update: () => ({
    type: SAGAS.STORAGE.UPDATE,
    payload: {},
  }),
};

export default StorageState;
