import { all, takeEvery } from "redux-saga/effects";
import { ACTION } from "../../actions";
import answerAuthChallenge from "./processors/answer-auth-challenge";
import getCurrentUser from "./processors/get-current-user";
import passwordlessSignIn from "./processors/passwordless-sign-in";
import passwordlessSignUp from "./processors/passwordless-sign-up";

function* authSaga() {
  yield all([
    takeEvery(ACTION.SAGAS.AUTH.CREDENTIALS.GET_CURRENT, getCurrentUser),
    takeEvery(
      ACTION.SAGAS.AUTH.CREDENTIALS.PASSWORDLESS_SIGN_IN,
      passwordlessSignIn
    ),
    takeEvery(
      ACTION.SAGAS.AUTH.CREDENTIALS.PASSWORDLESS_SIGN_UP,
      passwordlessSignUp
    ),
    takeEvery(
      ACTION.SAGAS.AUTH.CREDENTIALS.ANSWER_AUTH_CHALLENGE,
      answerAuthChallenge
    ),
  ]);
}

export default authSaga;
