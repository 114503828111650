/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, select } from "redux-saga/effects";
import {
  completionConcat,
  loadingIndicatorDisable,
  resetPromptWarningFirstBody,
  resetPromptWarningSecondBody,
  firstBodyUpdate,
  promptStateUpdate,
} from "../utils/helpers";
import { delay } from "redux-saga/effects";

function* streamGeneration(streamString: string, appState: any) {
  const tmpCompanyName = appState.settings.identityProperties.companyName;
  const tmpFirstName = appState.settings.firstName;
  const tmpRole = appState.settings.identityProperties.role;

  const tmpCompanyCheck = tmpCompanyName
    ? streamString
        .replace(":company:", " from " + tmpCompanyName)
        .replace(":company:", tmpCompanyName)
    : streamString.replace(":company:", "").replace(":company:", "I");

  const strPersonilized = tmpCompanyCheck
    .replace("[firstname]", tmpFirstName)
    .replace(":role:", tmpRole)
    .replace("[firstname]", tmpFirstName);

  for (let i = 0; i < strPersonilized.length; i++) {
    yield completionConcat(strPersonilized[i], 1);
    yield delay(6);
  }
}

function* addFirstBody(appState: any) {
  const tmpFirstName = appState.settings.firstName;

  const standartVersion =
    "Hi [firstname],\n\nHere's just a friendly reminder to review the document I sent you a couple of days ago. We'd love to kick off the collaboration starting next week.\n\nLet me know if there are any questions to be clarified.\n\nBest,\nJamie";
  const strPersonilized = standartVersion.replace("[firstname]", tmpFirstName);

  yield firstBodyUpdate(strPersonilized);
}

function* onOnboarding() {
  const { appState } = yield select((state) => state);

  // console.log("onOnboarding");
  // console.log(appState.second_body);

  const firstGeneration: string =
    "Hi Tim,\n\nIt's [firstname]:company:. I hope you are doing well!\n\nI came across your new product recently and wanted to reach out. I’d love to learn more about what you’re building and see if there’s anything :company: can do to help.\n\nI was wondering if you might be available for a call next Thursday at 10 am? If not, let me know when would work best for you.\n\nAll the best,\n[firstname]\nFlowritten";
  const secondGeneration: string =
    "Hi Tim,\n\n[firstname] here:company:. I came across your new product and it sounded super interesting – congrats on the recent launch! I would love to learn more about the idea and the plans for the upcoming months.\n\nWould you be up for a brief video call on Thursday next week at around 10 am?\n\nAll the best,\n[firstname]\nFlowritten";
  const replyGeneration: string =
    "Hi Jamie,\n\nThanks for the reminder. I apologize for not being able to get back to you sooner.\n\nI will take a look at the document as soon as possible and get back to you with any questions or comments.\n\nThanks for your patience in waiting for my response.\n\nBest,\n[firstname]";

  // console.log("onOnboarding");
  // console.log(appState.promptState);

  yield delay(207);
  if (appState.reply) {
    yield call(streamGeneration, replyGeneration, appState);
    yield promptStateUpdate(5);
  } else if (appState.promptState === 1) {
    yield call(streamGeneration, firstGeneration, appState);
  } else if (appState.promptState === 2) {
    yield call(streamGeneration, secondGeneration, appState);
    yield promptStateUpdate(2);
    yield addFirstBody(appState);
  }

  // concat to the "main" store with sagas working

  // wait time between characters 8ms
  // buys us time to have sentences arrive from backend
  // resulting in the perceived continuous flow

  // INTERRUPT,

  // The pill acts as an indicator to verify for an end event
  // The buffer check allows us to validate that the channel is infact empty
  // After which we dispatch the event to disable the loading state
  // if (buffer.length === i) {

  // yield call(console.log, "buffer is empty congrats");
  yield resetPromptWarningSecondBody();
  yield resetPromptWarningFirstBody();
  yield loadingIndicatorDisable();
  //}
  // }
}
export default onOnboarding;
