import authRequests, { AuthRequests } from "./auth";
import billingRequests, { BillingRequests } from "./billing";
import flowRequests, { FlowRequests } from "./flow";

export type ApiRequests = {
  billing: BillingRequests;
  flow: FlowRequests;
  auth: AuthRequests;
};

const apiRequests: ApiRequests = {
  billing: billingRequests,
  flow: flowRequests,
  auth: authRequests,
};

export default apiRequests;
