const punctuation = "!'#$%&\"()*+,-/:;<=>?@[\\]^_.`{|}~";

const initialCleanUp = (text: string) => {
  // remove leading and trailing spaces
  text = text.trim();
  // remove leading punctuation
  text = text.replace(new RegExp(`^[${punctuation}\\s]+`, "g"), "");
  // remove trailing punctuation
  text = text.replace(new RegExp(`[${punctuation}\\s]+$`, "g"), "");
  return text;
};

export const cleanRole = (text: string) => {
  let result = "";

  text = initialCleanUp(text.replace(/and/g, ","));
  // convert all symbols to commas
  text = text.replace(new RegExp(`[${punctuation}]`, "g"), ",");
  // limit to 3 roles
  if (text.includes(",")) {
    const splitText = text.split(",");
    // keep instances only
    if (splitText.length >= 3) {
      const role1 = splitText[0].trim();
      const role2 = splitText[1].trim();
      const role3 = splitText[2].trim();
      result = role1 + ", " + role2 + " and " + role3;
    } else if (splitText.length === 2) {
      const role1 = splitText[0].trim();
      const role2 = splitText[1].trim();
      result = role1 + " and " + role2;
    } else result = text;
  } else result = text;

  return result;
};

export const cleanCompanyDescription = (text: string) => {
  text = initialCleanUp(text);
  // convert all punctuation except , and . to space
  text = text.replace(/[!'#$%&\-"()*+/:;<=>?@[\\\]^_`{|}~]/g, " ");
  // remove multiple spaces
  text = text.replace(/\s+/g, " ");

  return text;
};

export const cleanCompanyName = (text: string) => {
  text = initialCleanUp(text);
  // remove all punctuation except .
  text = text.replace(/[!'#$%&\-"()*+/:;<=>?@[\\\]^_`{|}~,]/g, "");

  return text;
};
