const USE_CASES = {
  GALLERY_TEMPLATES: {
    LOAD: "ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.LOAD",
    IMPORT: "ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.IMPORT",
    DEFAULT_IMPORT: "ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.DEFAULT_IMPORT",
    OB_IMPORT: "ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.OB_IMPORT",
  },
  LOAD: "ACTION.SAGAS.USE_CASES.LOAD",
  CREATE: "ACTION.SAGAS.USE_CASES.CREATE",
  UPDATE: "ACTION.SAGAS.USE_CASES.UPDATE",
  REORDER: "ACTION.SAGAS.USE_CASES.REORDER",
};

export default USE_CASES;
