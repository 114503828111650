const COMPLETION_LENGTH = {
  UPDATE: `
          mutation lenUpdate($id: String, $completion_length: String) {
            update_db_account_settings(_set: {completion_length: $completion_length}, where: {id: {_eq: $id}}) {
              affected_rows
              returning {
                completion_length
                id
              }
            }
          }
            `,
};

export default COMPLETION_LENGTH;
