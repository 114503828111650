import DB from "../constants";
import createHasuraRequest from "../utils";

export type VIPInvite = {
  update: (uid: string, token: string, count: number) => any;
};

const vip_invite: VIPInvite = {
  update: async (uid, token, count) => {
    const request = createHasuraRequest(DB.FLAGS.VIP_INVITE.UPDATE, token);
    const parameters = {
      uid,
      count,
    };
    return await request(parameters);
  },
};

export { vip_invite };
