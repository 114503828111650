import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { call, apply } from "redux-saga/effects";

function* onFederationReAttempt(payload: any): any {
  try {
    /* 
    new user trying to sign up with federated identity, account linking requires a reattempt of the sign-in flow 
    https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
    */
    if (
      payload.data.url
        .toLowerCase()
        .includes("already+found+an+entry+for+username")
    ) {
      yield apply(Auth, Auth.federatedSignIn as any, [
        { provider: CognitoHostedUIIdentityProvider.Google },
      ]);
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

export default onFederationReAttempt;
