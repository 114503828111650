import DB from "../constants";
import createHasuraRequest from "../utils";

export type FirstUsageBumper = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const first_usage_bumper: FirstUsageBumper = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.FIRST_USAGE_BUMPER.UPDATE,
      token
    );
    const parameters = {
      uid,
      first_usage_bumper: newContent,
    };
    return await request(parameters);
  },
};

export { first_usage_bumper };
