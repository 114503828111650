import { Reducer } from "redux";
import { ACTION } from "../actions";
import authState, { AuthState, AuthStatus } from "../initial-state/auth";

const authReducer = (state: AuthState = authState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    /* toggle between these three credential states */
    case ACTION.LOCAL.AUTH.CREDENTIALS.SET: {
      const {
        email,
        displayName,
        uid,
        cognitoUsername,
        idToken,
        refreshToken,
        accessToken,
        cognitoUser,
      } = payload;
      return {
        ...state,
        status: AuthStatus.SIGNED_IN,
        isLoaded: true,
        isLoading: false,
        isEmpty: false,
        hasFailed: false,
        sign_up_user: false,
        email,
        displayName,
        uid,
        cognitoUsername,
        idToken,
        refreshToken,
        accessToken,
        cognitoUser,
        failureReason: "",
      };
    }

    /* on mount initial state before an attempt for fetching the authenticated user */
    case ACTION.LOCAL.AUTH.CREDENTIALS.RESET: {
      return {
        ...authState,
      };
    }

    /* sign in attempt has failed. Display error message */
    case ACTION.LOCAL.AUTH.CREDENTIALS.FAILURE: {
      const { failureReason } = payload;
      /* default to an empty initial state for all other attributes */
      return {
        ...authState,
        status: AuthStatus.SIGN_IN_FAILURE,
        hasFailed: true,
        isLoaded: true,
        isLoading: false,
        failureReason,
      };
    }
    /* auth is being attempted */
    case ACTION.LOCAL.AUTH.CREDENTIALS.LOADING: {
      return {
        ...state,
        status: AuthStatus.LOADING,
        isLoading: true,
        isLoaded: false,
        hasFailed: false,
      };
    }

    /* signed out state, redirection to login */
    case ACTION.LOCAL.AUTH.CREDENTIALS.SET_EMPTY: {
      return {
        ...authState,
        isLoaded: true,
        isEmpty: true,
        isLoading: false,
        hasFailed: false,
      };
    }

    case ACTION.LOCAL.AUTH.CREDENTIALS.SET_SIGN_UP_USER: {
      const { newContent } = payload;
      return {
        ...state,
        sign_up_user: newContent,
      };
    }

    case ACTION.LOCAL.AUTH.ANY_FIELD_UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }

    case ACTION.LOCAL.AUTH.RESET_PASSWORDLESS_SIGN_IN: {
      return {
        ...state,
        cognitoUser: null,
        code_attempt: 0,
        incorrect_code: false,
        code_sent: false,
        expired_code: false,
        code: "",
        network_error: false,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
