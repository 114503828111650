/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Reducer } from "redux";
import randomEmoji from "random-unicode-emoji";
import useCases from "../initial-state/use-cases";
import { ACTION } from "../actions";

const useCasesReducer = (state: any = useCases, action: any): Reducer => {
  switch (action.type) {
    // I think the update does not depend on anything so
    // we might as well couple these too
    case ACTION.LOCAL.USE_CASES.SINK: {
      return {
        ...state,
        useCasesLocal: action.payload.useCases,
      };
    }
    case ACTION.LOCAL.USE_CASES.UPDATE: {
      const { useCasesArray } = action.payload;
      return {
        ...state,
        useCasesLocal: useCasesArray,
      };
    }

    case ACTION.LOCAL.USE_CASES.GALLERY_TEMPLATES.UPDATE: {
      const { useCasesArray } = action.payload;
      return {
        ...state,
        galleryTemplatesLocal: useCasesArray,
      };
    }

    case ACTION.LOCAL.USE_CASE.UPDATE: {
      const { newArray } = action.payload;
      return {
        ...state,
        useCasesLocal: newArray,
      };
    }

    case ACTION.LOCAL.USE_CASE.ANY_FIELD.UPDATE: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        [field]: newContent,
      };
    }

    // SAGAS probably should not be here..
    case ACTION.LOCAL.APP_STATE.CONFIG_ADD_DIALOG.OPEN: {
      return {
        ...state,
        tmpEmoji: randomEmoji.random({ count: 1 }),
        tmpName: "",
        tmpRecipientType: "",
        tmpIntention: "",
        tmpInstructions: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.OPEN: {
      const {
        emoji,
        name,
        recipient_type,
        instructions,
        intention,
        description,
        template_collection_id,
        template_id
      } = action.payload.useCase;
      return {
        ...state,
        tmpEmoji: emoji,
        tmpName: name,
        tmpRecipientType: recipient_type,
        tmpInstructions: instructions,
        tmpIntention: intention,
        tmpDescription: description,
        template_collection_id: template_collection_id,
        template_id: template_id,
      };
    }

    case ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.CLOSE: {
      return {
        ...state,
        tmpEmoji: "",
        tmpName: "",
        tmpRecipientType: "",
        tmpIntention: "",
        tmpDescription: "",
      };
    }

    default:
      return state;
  }
};

export default useCasesReducer;
