import { call } from "redux-saga/effects";
import {
  onNetworkException,
  onDefaultException,
  onUnauthorizedException,
} from "./exceptions";

function* onSignInFailure(payload: any): any {
  try {
    if ("code" in payload.data)
      switch (payload.data.code) {
        /* Incorrect OTP answered - custom_challenge flow fails */
        case "NotAuthorizedException": {
          yield onUnauthorizedException(payload.data);
          break;
        }

        /* new user trying to sign in, account linking handled */
        case "UserNotFoundException": {
          break;
        }

        /* Network failure */
        case "NetworkError": {
          yield onNetworkException(payload.data);
          break;
        }

        default: {
          yield onDefaultException(payload.data);
        }
      }
    else yield onDefaultException(payload.data);
  } catch (e) {
    yield call(console.log, e);
  }
}

export default onSignInFailure;
