import { all } from "redux-saga/effects";
import broadcastChannelPublish from "./pub";
import broadcastChannelSubscribe from "./sub";

/*
Reference:
https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
*/
function* broadcastChannelSaga(): any {
  yield all([broadcastChannelSubscribe(), broadcastChannelPublish()]);
}

export default broadcastChannelSaga;
