import DB from "../constants";
import createHasuraRequest from "../utils";

export type IterateUXBumperFlow = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const iterateux_bumper_flow: IterateUXBumperFlow = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.ITERATEUX_BUMPER_FLOW.UPDATE,
      token
    );
    const parameters = {
      uid,
      iterateux_bumper_flow: newContent,
    };
    return await request(parameters);
  },
};

export { iterateux_bumper_flow };
