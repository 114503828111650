import { Auth } from "aws-amplify";
import { SSE } from "sse.js";
import ky from "ky";
import logger from "../../logger";

export const defaultBackendURL = `${process.env.REACT_APP_BACKEND_HOST}/api/`;

/* ky instantiated with default config */
const api = ky.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  prefixUrl: defaultBackendURL,
  timeout: 30000,
  retry: {
    limit: 2,
    statusCodes: [408],
  },
});

const stream = (payload: string, token: string) =>
  new SSE(defaultBackendURL + "flow/new_stream", {
    headers: {
      "Content-Type": "application/json",
      Accept: "text/event-stream",
      mode: "cors",
      crossDomain: "true",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    payload,
  });

export { api, stream };
