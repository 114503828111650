import REMOTE from "./constants";

export type RemoteUseCasesRepo = {
  create(): any;
  read(): any;
  sync(useCaseId: any): any;
}

const UseCases: RemoteUseCasesRepo = {
  create: () => ({
    type: REMOTE.USE_CASES.CREATE,
    payload: {},
  }),
  read: () => ({
    type: REMOTE.USE_CASES.READ,
    payload: {},
  }),
  sync: (useCaseId) => ({
    type: REMOTE.USE_CASE.UPDATE,
    payload: { useCaseId },
  }),
};

export default UseCases;