const BULLET_SUGGESTIONS = {
  READ: `
    query getBulletSuggestions($template_id: String, $mode: String) {
      templates_bullet_suggestions(where: {
        gallery_template_id: {_eq: $template_id}, 
        _or: [
          {mode: {_eq: $mode}}, 
          {mode: {_is_null: true}}
        ]
      }) {
        bullets
      }
    }
  `,
};

export default BULLET_SUGGESTIONS;
