const USE_CASES = {
  GALLERY_TEMPLATES: {
    CREATE: "ACTION.LOCAL.USE_CASES.GALLERY_TEMPLATES.CREATE",
    UPDATE: "ACTION.LOCAL.USE_CASES.GALLERY_TEMPLATES.UPDATE",
  },
  CREATE: "ACTION.LOCAL.USE_CASES.CREATE",
  SINK: "ACTION.LOCAL.USE_CASES.SINK",
  DELETE: "ACTION.LOCAL.USE_CASES.DELETE",
  UPDATE: "ACTION.LOCAL.USE_CASES.UPDATE",
  REORDER: "ACTION.LOCAL.USE_CASES.REORDER",
};

export default USE_CASES;
