import DB from "../constants";
import createHasuraRequest from "../utils";

export type GenerationCount = {
  update: (uid: string, token: string, newContent: number) => any;
};

const generation_count: GenerationCount = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(DB.FLAGS.GENERATION_COUNT.UPDATE, token);
    const parameters = {
      uid,
      generation_count: newContent,
    };
    return await request(parameters);
  },
};

export { generation_count };
