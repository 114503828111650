export type UseCase = {
  id: string;
  name: string;
  emoji: any;
  intention: string;
  recipient_type: string;
  instructions: string;
};

export enum OnboardingSteps {
  Install = "install",
  Appearance = "appearance",
  Role = "role",
  Questions = "questions",
  Tutorial = "tutorial",
  Identity = "identity",
  Templates = "templates",
  Pricing = "pricing",
  Integrations = "integrations",
}
