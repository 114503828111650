const logger = () => {
  const logList = (logs: string[]) => logs.forEach((log) => console.log(log));
  const isDev = process.env.REACT_APP_NODE_ENV !== "production";

  return {
    log: (...logs: string[]) => isDev && logList(logs),
    logGroup: (groupHeader: string = "", ...logs: string[]) => {
      if (isDev) {
        console.group(groupHeader);
        logList(logs);
        console.groupEnd();
      }
    },
  };
};

export default logger;
