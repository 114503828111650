import DB from "../constants";
import createHasuraRequest from "../utils";

export type TFGFBumper = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const existing_user_tfgf_bumper: TFGFBumper = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.EXISTING_USER_TFGF_BUMPER.UPDATE,
      token
    );
    const parameters = {
      uid,
      existing_user_tfgf_bumper: newContent,
    };
    return await request(parameters);
  },
};

export { existing_user_tfgf_bumper };
