import DB from "../constants";
import createHasuraRequest from "../utils";

export type CopyUsed = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const copy_used: CopyUsed = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(DB.FLAGS.COPY_USED.UPDATE, token);
    const parameters = {
      uid,
      copy_used: newContent,
    };
    return await request(parameters);
  },
};

export { copy_used };
