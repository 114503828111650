/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { call } from "redux-saga/effects";
import {
  flowriteModeDisable,
  streamError,
  sendAppError,
  closeStream,
  feedbackModeDisable,
  loadingIndicatorDisable,
  allLoadingFieldsDisable,
} from "../../../utils/helpers";

function* onError(source: any, data: any) {
  try {
    yield closeStream(source);
    yield streamError(); // these need to go to analytics or datadog or a logging service
    yield sendAppError("Stream Error", data.content);
    yield flowriteModeDisable();
    yield loadingIndicatorDisable();
    yield allLoadingFieldsDisable();
    yield feedbackModeDisable();
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onError;
