import { combineReducers } from "redux";
import componentErrorsReducer from "./component-error";
import appErrorReducer from "./app-error";

const errorReducer = combineReducers<any>({
  component: componentErrorsReducer,
  app: appErrorReducer,
});

export default errorReducer;
