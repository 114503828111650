import DB from "../constants";
import createHasuraRequest from "../utils";

export type GenerationLimitExceeded = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const generation_limit_exceeded: GenerationLimitExceeded = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(DB.FLAGS.GENERATION_LIMIT_EXCEEDED.UPDATE, token);
    const parameters = {
      uid,
      generation_limit_exceeded: newContent,
    };
    return await request(parameters);
  },
};

export { generation_limit_exceeded };
