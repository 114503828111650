const EXTENSION_INSTALL = {
  UPDATE: `
    mutation MyMutation($uid: String!, $extension_install: Boolean) {
        update_db_flags(where: {id: {_eq: $uid}}, _set: {extension_install: $extension_install}) {
          affected_rows
          returning {
            id
          }
        }
      }
    `,
};

export default EXTENSION_INSTALL;
