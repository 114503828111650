const DOS_TEMPLATES = {
  READ: `
    query GetDosTemplatesQuery {
        templates_dos_templates(distinct_on: gallery_template_id) {
            gallery_template_id
            category
        }
    }
    `,
};

export default DOS_TEMPLATES;
