import { customer, Customer } from "./customer";

export type BillingRequests = {
  customer: Customer;
};

const billingRequests = {
  customer,
};

export default billingRequests;
