import DB from "../constants";
import createHasuraRequest from "../utils";

export type Identity = {
  update: (
    uid: string,
    token: string,
    identityProperties: {
      role: string;
      companyName: string;
      companyDescription: string;
      processedRole: string;
      processedCompanyName: string;
      processedCompanyDescription: string;
      firstPreposition: string;
      secondPreposition: string;
    }
  ) => any;
  updateProcessed: (
    uid: string,
    token: string,
    identityProperties: {
      processedRole: string;
      processedCompanyName: string;
      processedCompanyDescription: string;
    }
  ) => any;
};

const identity: Identity = {
  update: async (uid, token, identityProperties) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.IDENTITY.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      role: identityProperties.role,
      company_name: identityProperties.companyName,
      company_description: identityProperties.companyDescription,
      processed_company_name: identityProperties.processedCompanyName,
      processed_company_description:
        identityProperties.processedCompanyDescription,
      processed_role: identityProperties.processedRole,
      first_preposition: identityProperties.firstPreposition,
      second_preposition: identityProperties.secondPreposition,
    };

    return await request(parameters);
  },
  updateProcessed: async (uid, token, identityProperties) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.IDENTITY.UPDATE_PROCESSED,
      token
    );
    const parameters = {
      id: uid,
      processed_role: identityProperties.processedRole,
      processed_company_name: identityProperties.processedCompanyName,
      processed_company_description:
        identityProperties.processedCompanyDescription,
    };

    return await request(parameters);
  },
};

export { identity };
