import { put, call, select, all, takeEvery } from "redux-saga/effects";
import { key } from "firebase-key";
import {
  toArrFlat,
  sortByIndexFlat,
  stampIndices,
  getUseCases,
  filterUseCaseName,
  sortDescByIndexFlat,
  getToken,
  removeImported,
} from "./utils/helpers";
import { ACTION } from "../actions";
import db from "../../services/hasura";
import SagasAppState from "../actions/sagas/app-state";

function* reorder(action: any): any {
  try {
    const { useCasesArray } = action.payload;
    const token: any = yield getToken();
    const uid = yield select((state) => state.auth.uid);
    const newOrder = useCasesArray.map(
      (item: any) => item.template_id || item.id
    );

    if (!token) return;

    yield put({
      type: ACTION.LOCAL.USE_CASES.UPDATE,
      payload: { useCasesArray },
    });

    const response: any = yield call(
      db.templates.templates_order.update,
      uid,
      newOrder,
      token
    );
  } catch (e) {
    yield call(console.log, e);
  }
}

// update template_group and template_type for user templates
function* update(useCases: any[]): any {
  try {
    const { galleryTemplatesLocal } = yield select((state) => state.useCases);
    const token = yield getToken();
    const updatedUseCases = yield all(
      useCases.map((useCase: any) => {
        if (!useCase.template_group) {
          const foundTemplate = galleryTemplatesLocal.find(
            (t: any) => t.template_id === useCase.template_collection_id
          );
          if (foundTemplate) {
            const updatedTemplate = {
              ...useCase,
              template_group: foundTemplate.template_group,
              template_type: foundTemplate.template_type,
            };
            const res = call(db.template.update, token, updatedTemplate);
            // console.log("updateTemplate", res);
            return updatedTemplate;
          }
          return useCase;
        }
        return useCase;
      })
    );
    yield put(SagasAppState.extensionSettingsMessage());

    return updatedUseCases;
  } catch (error) {
    yield call(console.log, error);
  }
}

function* readUserTemplates(): any {
  try {
    const { uid } = yield select((state) => state.auth);
    const token = yield getToken();

    const dataFromHasura = yield call(
      db.templates.user_templates.read,
      uid,
      token
    );

    const userTemplates = dataFromHasura.data.db_users_templates
      ? dataFromHasura.data.db_users_templates.map((node: any) => node.template)
      : [];

    if (userTemplates.length > 0) {
      const useCases = userTemplates.slice();
      const newArray = yield call(toArrFlat, useCases);
      // update template_group and template_type for user templates
      const updated = yield update(newArray);
      const sortedArray = yield call(sortDescByIndexFlat, updated);
      return sortedArray;
    }
    return [];
  } catch (error) {
    // here instead dispatch error message, could not push to firebase
    yield call(console.log, error);
  }
}

function* importGalleryUseCases(action: any): any {
  try {
    const { galleryTemplatesLocal, useCasesLocal } = yield select(
      (state) => state.useCases
    );
    const { uid } = yield select((state) => state.auth);
    const token = yield getToken();
    const { selected } = action.payload;
    const { tmpName, tmpEmoji } = yield select((state) => state.useCases);
    let setIndex = 0;
    const newKey = yield call(key);
    const newArray: any = [];
    const selectedTemplates = yield call(
      (x) =>
        x
          .filter((item: any, index: number) =>
            selected.includes(item.template_id)
          )
          .map((item: any, index: number) => {
            const newItem = item;
            newItem.id = newKey;
            newItem.name = tmpName;
            newItem.emoji = tmpEmoji;
            newItem.template_collection_id = item.template_id;
            newItem.index = (useCasesLocal.length as number) + index;
            return newItem;
          }),
      galleryTemplatesLocal
    );

    // new
    const saveTemplateNamesHasura = yield all(
      selectedTemplates.map((item: any, index: number) => {
        const newItem = item.name;
        return newItem;
      })
    );

    // yield call(console.log, "saveTemplateNamesHasura", saveTemplateNamesHasura);
    // yield call(console.log, "selectedTemplates", selectedTemplates);

    const templateOrder: any = yield call(
      db.templates.templates_order.read,
      uid,
      token
    );

    const some = yield all(
      selectedTemplates.map((item: any) => {
        // console.log("template-create", item);
        const res = call(db.template.create, uid, token, item);
        return res;
      })
    );

    templateOrder.data.db_users[0].templates_order.map((t: any, i: any) => {
      if (selectedTemplates[0].template_id === t) {
        newArray[i] = some[0].data.insert_db_templates.returning[0].id;
      } else {
        newArray[i] = t;
      }
    });

    yield call(db.templates.templates_order.update, uid, newArray, token);

    window.analytics.track("Collection Templates Imported", {
      environment: process.env.REACT_APP_APP_ENV,
      templatesImported: selectedTemplates,
      templateNames: saveTemplateNamesHasura,
    });

    const filteredUseCaseLocal = useCasesLocal.filter(
      (useCase: any, index: number) => {
        if (useCase.template_id === selectedTemplates[0].template_id) {
          setIndex = index;
        }
        return useCase.template_id !== selectedTemplates[0].template_id;
      }
    );

    // replace old template_id with new value
    selectedTemplates[0].template_id =
      some[0].data.insert_db_users_templates.returning[0].template_id;
   filteredUseCaseLocal.splice(
      setIndex,
      0,
      selectedTemplates[0]
    );

    const arrayToBind = yield call(stampIndices, filteredUseCaseLocal);

    yield put({
      type: ACTION.LOCAL.USE_CASES.UPDATE,
      payload: { useCasesArray: arrayToBind },
    });

    yield put({
      type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
      payload: { id: "templateCollection", targetState: false },
    });

    yield put(SagasAppState.extensionSettingsMessage());
  } catch (error) {
    yield call(console.log, error);
  }
}

function* defaultImportUseCases(action: any): any {
  try {
    const { galleryTemplatesLocal } = yield select((state) => state.useCases);
    const token = yield getToken();
    const { selected } = action.payload;

    // yield call(console.log, "defaultImportUseCases", selected);

    const templateSet = yield call(
      db.templates.default_templates.read,
      token,
      selected
    );
    // yield call(console.log, "templateSet", templateSet);

    const templateSetHasura: Array<string> =
      templateSet?.data.templates_default_template_set[0];

    let templates = (templateSetHasura as any)?.templates;
    if (templates && templates.length === 0) {
      templates = Object.keys(templateSetHasura).map(
        (key: any) => templateSetHasura[key]
      );
    }
    // yield call(console.log, "templates", templates);

    const selectedTemplates = yield call(
      getUseCases,
      templates,
      galleryTemplatesLocal
    );

    // yield call(console.log, "selectedTemplates", selectedTemplates);

    const arrayToBind = yield call(stampIndices, selectedTemplates);
    yield put({
      type: ACTION.LOCAL.USE_CASES.UPDATE,
      payload: { useCasesArray: arrayToBind },
    });
  } catch (error) {
    yield call(console.log, error);
  }
}

function* readGalleryUseCases(): any {
  try {
    const token = yield getToken();
    // replace to call hasura
    const loadedCases = yield call(db.templates.gallery_templates.read, token);

    const useCases = loadedCases.data.templates_gallery_templates
      ? loadedCases.data.templates_gallery_templates.map((item: any) => item)
      : [];

    if (useCases.length > 0) {
      const newArray = yield call(toArrFlat, useCases);
      const useCasesArray = yield call(sortByIndexFlat, newArray);
      // Hide 'Generic reply' and 'Generic cold email' templates
      const arrayToBind = yield call(filterUseCaseName, useCasesArray, [
        "Generic reply",
        "Generic cold email",
      ]);
      yield put({
        type: ACTION.LOCAL.USE_CASES.GALLERY_TEMPLATES.UPDATE,
        payload: { useCasesArray: arrayToBind },
      });
      return arrayToBind;
    }
    return [];
  } catch (error) {
    // here instead dispatch error message, could not push to firebase
    yield call(console.log, error);
  }
}

function* readAllTemplates(): any {
  try {
    const { uid } = yield select((state) => state.auth);
    const { galleryTemplatesLocal } = yield select((state) => state.useCases);
    const token = yield getToken();

    if (!token) return;

    const orderRes = yield call(db.templates.templates_order.read, uid, token);
    const order = orderRes.data.db_users[0]?.templates_order;

    const userTemplates = yield readUserTemplates();
    let galleryTemplates = galleryTemplatesLocal;

    if (galleryTemplates.length === 0) {
      galleryTemplates = yield readGalleryUseCases();
    }
    galleryTemplates = yield call(
      removeImported,
      galleryTemplates,
      userTemplates
    );

    const allTemplates = yield call(
      (x) => x.concat(galleryTemplates),
      userTemplates
    );

    if (order && order.length === allTemplates.length) {
      // order template list based on templates_order
      const orderedList: any[] = [];
      yield call((x) => {
        x.forEach((id: string) => {
          const item = allTemplates.find(
            (item: any) => item.template_id === id || item.id === id
          );
          orderedList.push(item);
        });
      }, order);
      yield put({
        type: ACTION.LOCAL.USE_CASES.UPDATE,
        payload: { useCasesArray: orderedList },
      });
    } else {
      yield reorder({ payload: { useCasesArray: allTemplates } });
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* onboardingImportUseCases(action: any): any {
  try {
    const { uid } = yield select((state) => state.auth);
    const token = yield getToken();
    const { selected } = action.payload;

    const useCases = yield call(db.templates.user_templates.read, uid, token);
    if (useCases.data.db_users_templates.length > 0) {
      yield all(
        useCases.data.db_users_templates.map(({ template }: any) =>
          call(db.template.delete, token, template.id)
        )
      );
    }

    yield importGalleryUseCases({
      ...action,
      payload: { ...action.payload, selected: [...selected] },
    });
    yield put(SagasAppState.extensionSettingsMessage());
  } catch (error) {
    yield call(console.log, error);
  }
}

function* useCasesSaga() {
  yield all([
    takeEvery(ACTION.SAGAS.USE_CASES.REORDER, reorder),
    takeEvery(ACTION.SAGAS.USE_CASES.LOAD, readAllTemplates),
    // takeEvery(ACTION.SAGAS.USE_CASES.CREATE, create),
    // takeEvery(ACTION.SAGAS.USE_CASES.UPDATE, update),
    takeEvery(
      ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.LOAD,
      readGalleryUseCases
    ),
    takeEvery(
      ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.IMPORT,
      importGalleryUseCases
    ),
    takeEvery(
      ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.DEFAULT_IMPORT,
      defaultImportUseCases
    ),
    takeEvery(
      ACTION.SAGAS.USE_CASES.GALLERY_TEMPLATES.OB_IMPORT,
      onboardingImportUseCases
    ),
  ]);
}

export default useCasesSaga;
