/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { all, call, select, takeEvery } from "redux-saga/effects";
import { ACTION } from "../actions";
import { oneGeneration, storage } from "../initial-state/local-storage";
import { saveCompilationToStorage } from "./utils/storage-helpers";


function* updateStorageGenerations(action: any) {
  try {
    const { appState } = yield select((state) => state);

    const local = storage.getItem("generations");
    const { bodyId } = action.payload;
    const generations: oneGeneration[] = bodyId
      ? [
          {
            gen_id: appState.gen_id,
            seed_id: appState.seed_id,
            templateName: appState.template_name,
            text: appState[`second_body_${bodyId}`],
            recipient: appState.recipient,
          },
        ]
      : Array(3)
          .fill({})
          .map((_, index) => ({
            gen_id: appState.gen_id,
            seed_id: appState.seed_id,
            templateName: appState.template_name,
            text: appState[`second_body_${index + 1}`],
            recipient: appState.recipient,
          }));

    if (local === null) {
      storage.setItem("generations", generations);
      yield saveCompilationToStorage(generations);
    } else {
      const finalGens = generations.concat(local);
      storage.setItem("generations", finalGens);
      yield saveCompilationToStorage(finalGens);
    }
  } catch (e) {
    yield call(console.log, e);
  }
}

function* localStorageSaga() {
  yield all([
    // takeEvery(ACTION.SAGAS.STORAGE.LOAD, localStorageStateSagas.loadStorage),
    takeEvery(ACTION.SAGAS.STORAGE.UPDATE, updateStorageGenerations),
  ]);
}

export default localStorageSaga;
