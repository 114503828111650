const FIRST_USAGE_BUMPER = {
  UPDATE: `
      mutation MyMutation($uid: String!, $first_usage_bumper: Boolean) {
          update_db_flags(where: {id: {_eq: $uid}}, _set: {first_usage_bumper: $first_usage_bumper}) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
};

export default FIRST_USAGE_BUMPER;
