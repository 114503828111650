/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import useCasesReducer from "./use-cases";
import appStateReducer from "./app-state";
import locationReducer from "./location";
import errorReducer from "./errors";
//import flagsReducer from "./flags";
import localStorageReducer from "./local-storage";
import generationHistoryReducer from "./generation-history";
import authReducer from "./auth";

export const createRootReducer = (history: any): any =>
  combineReducers<any>({
    router: connectRouter(history),
    useCases: useCasesReducer,
    appState: appStateReducer,
    location: locationReducer,
    errors: errorReducer,
    auth: authReducer,
    // flags: flagsReducer,
    localStorage: localStorageReducer,
    generationHistory: generationHistoryReducer,
  });

export default createRootReducer;
