// eslint-disable-next-line import/no-cycle
import LOCAL from "./constants";

export type LocalUseCaseRepo = {
  create(useCase: any): any;
  update(newArray: any[]): any;
  update2(field: string, subField: string, newContent: string): any;
  delete(useCaseId: string): any;
  AnyField: {
    update(field: string, newContent: string | null): any;
  };
};

const UseCase: LocalUseCaseRepo = {
  create: (useCaseId) => ({
    type: LOCAL.USE_CASE.CREATE,
    payload: { useCaseId },
  }),
  update: (newArray) => ({
    type: LOCAL.USE_CASE.UPDATE,
    payload: { newArray },
  }),
  update2: (field, subField, newContent) => ({
    type: LOCAL.USE_CASE.UPDATE,
    payload: { field, subField, newContent },
  }),
  delete: (useCaseId) => ({
    type: LOCAL.USE_CASE.DELETE,
    payload: { useCaseId },
  }),
  AnyField: {
    update: (field, newContent) => ({
      type: LOCAL.USE_CASE.ANY_FIELD.UPDATE,
      payload: { field, newContent },
    }),
  },
};

export default UseCase;
