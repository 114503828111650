export enum AuthStatus {
  SIGNED_IN = "SIGNED_IN",
  LOADING = "LOADING",
  SIGN_IN_FAILURE = "SIGN_IN_FAILURE",
  SIGNED_OUT = "SIGNED_OUT",
}

/* auth compound state representations and their meaning */
/*
  isLoaded = true
  isEmpty = true
  hasFailed = false
  
  Auth has successfully been called but the user is not signed in
  Called on mount of the app to fetch the current auth user
  */

/*
  isLoaded = true
  isEmpty = false
  hasFailed = false
  
  Auth has been called and a logged in identity is found
  the user is loaded to the app, and logged in
  */

/*
  isLoading = true
  isLoaded = false
  hasFailed = false
  
  Auth call is pending
  */

/*
  hasFailed = true
  isLoaded = true
  isEmpty = true
  
  Auth has been attempted but has failed in the sign-in
  */

export type AuthState = {
  status: AuthStatus;
  isLoading: boolean;
  isLoaded: boolean;
  isEmpty: boolean;
  hasFailed: boolean;
  email: string;
  /* cognito assigned username */
  cognitoUsername: string;
  /* cognito sub = uid; unique */
  uid: string;
  cognitoUser: any;
  /* contains identity information including custom claims */
  idToken: string;
  /* access right information for AWS Resources */
  accessToken: string;
  refreshToken: string;
  /* full name fetched from google federation */
  displayName: string;
  /* */
  failureReason: string;
  /* new user sign up, no credentials exist before */
  sign_up_user: boolean;
  /* cognito-idp signin failed due to a network error */
  network_error: boolean;
  /* login code */
  code_sent: boolean;
  code_attempt: number;
  incorrect_code: boolean;
  expired_code: boolean;
  code: any;
};

const authState: AuthState = {
  status: AuthStatus.SIGNED_OUT,
  isLoaded: false,
  isLoading: false,
  isEmpty: true,
  hasFailed: false,
  email: "",
  cognitoUsername: "",
  uid: "",
  cognitoUser: null,
  idToken: "",
  accessToken: "",
  refreshToken: "",
  displayName: "",
  failureReason: "",
  network_error: false,
  sign_up_user: false,
  code_sent: false,
  code_attempt: 0,
  incorrect_code: false,
  expired_code: false,
  code: "",
};
export default authState;
