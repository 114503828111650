import { settings, Settings } from "./settings";
import { persona, Persona } from "./persona";
import { usage_reason, UsageReason } from "./usage_reason";
import { completion_length, CompletionLength } from "./completion_length";
import { keyboard_shortcuts, KeyboardShortcuts } from "./keyboard_shortcuts";
import {
  extension_disabled_integrations,
  ExtensionIntegrations,
} from "./extension_disabled_integrations";
import { identity, Identity } from "./identity";
import { test_mode, TestMode } from "./test_mode";

export interface AccountSettings extends Settings {
  persona: Persona;
  usage_reason: UsageReason;
  completion_length: CompletionLength;
  keyboard_shortcuts: KeyboardShortcuts;
  extension_disabled_integrations: ExtensionIntegrations;
  identity: Identity;
  test_mode: TestMode;
}

const account_settings: AccountSettings = {
  ...settings,
  persona,
  usage_reason,
  completion_length,
  keyboard_shortcuts,
  extension_disabled_integrations,
  identity,
  test_mode,
};

export { account_settings };
