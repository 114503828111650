/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./tailwind.output.css";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import firebase from "firebase/app";
import ReduxSagaFirebase from "redux-saga-firebase";
import ThemedSuspense from "./components/themed-suspense";
import { store, rrfProps } from "./redux/store";

import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";

import { firebase as firebaseConfig } from "./firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import Auth from "./auth";

/* configure amplify */
Amplify.configure(awsConfig);

export const myFirebaseApp = (firebase as any).initializeApp(firebaseConfig);
(firebase as any).analytics();
const rsf = new ReduxSagaFirebase(myFirebaseApp);

ReactDOM.render(
  <Provider store={store}>
    {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
    <Suspense fallback={<ThemedSuspense />}>
      <Auth />
    </Suspense>
    {/* </ReactReduxFirebaseProvider> */}
  </Provider>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
declare global {
    interface Window {
        cello: any;
        CelloAttribution: any;
    }
}
export default rsf;
