/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import useQuery from "./hooks/use-query";
import AppState, { LocalAppStateRepo } from "./redux/actions/local/app-state";
import Sagas from "./redux/actions/sagas";
import { SagasAppStateRepo } from "./redux/actions/sagas/app-state";
import { OnboardingSteps } from "./services/hasura/types";

export interface RedirectRouteProps {
  onboardingMode: boolean;
  onboardingStep: string;
  stepCompleted: string;
  children: any;
  updateExtensionInstallFlag: SagasAppStateRepo["updateExtensionInstallFlag"];
  updateOnboardingFlags: SagasAppStateRepo["updateOnboardingFlags"];
  extensionLogin: SagasAppStateRepo["extensionLogin"];
  toggleVisibility: LocalAppStateRepo["Visibility"]["toggle"];
}

const RedirectRoute: FunctionComponent<RedirectRouteProps> = ({
  onboardingMode,
  onboardingStep,
  stepCompleted,
  children,
  extensionLogin,
  toggleVisibility,
  updateExtensionInstallFlag,
  updateOnboardingFlags,
}: RedirectRouteProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    const type = query.get("type");

    switch (type) {
      case "extension_install": {
        extensionLogin();
        updateExtensionInstallFlag(true);
        if (onboardingMode && onboardingStep === OnboardingSteps.Install) {
          if (!stepCompleted)
            updateOnboardingFlags({
              onboarding_step_text: OnboardingSteps.Appearance,
              step_completed_text: OnboardingSteps.Install,
            });
          else
            updateOnboardingFlags({
              onboarding_step_text: OnboardingSteps.Appearance,
            });

          window.analytics.track("Onboarding DarkLightModeSetUp Opened", {
            environment: process.env.REACT_APP_APP_ENV,
          });
        } else {
          toggleVisibility("extensionDialog", true);
          query.append("action", "install_popup_open");
        }

        window.analytics.track("Extension Installed", {
          environment: process.env.REACT_APP_APP_ENV,
        });
        break;
      }
      case "extension_login": {
        extensionLogin();
        updateExtensionInstallFlag(true);
        break;
      }
      case "skip_extension_install": {
        updateExtensionInstallFlag(false);
        if (onboardingMode && onboardingStep === OnboardingSteps.Install) {
            updateOnboardingFlags({
              onboarding_step_text: OnboardingSteps.Appearance,
            });

          window.analytics.track("Onboarding DarkLightModeSetUp Opened", {
            environment: process.env.REACT_APP_APP_ENV,
          });
        } else {
          toggleVisibility("extensionDialog", true);
          query.append("action", "install_popup_open");
        }
        window.analytics.track("Skipped Extension Install", {
          environment: process.env.REACT_APP_APP_ENV,
        });
        break;
      }
      case "chat": {
        if (window.Intercom) {
          window.Intercom("show");
        } else {
          const interval = setInterval(() => {
            if (window.Intercom) {
              window.Intercom("show");
              clearInterval(interval);
            }
          }, 200);
        }
        break;
      }
      case "vip_invite": {
        toggleVisibility("inviteDialog", true);
        query.append("action", "invite_popup_open");
        break;
      }
      default:
        break;
    }

    if (type) {
      query.delete("type");
      history.replace({
        search: query.toString(),
      });
    }
  }, [query]);

  useEffect(() => {
    const action = query.get("action");

    switch (action) {
      case "install_popup_open": {
        toggleVisibility("extensionDialog", true);
        break;
      }

      case "invite_popup_open": {
        toggleVisibility("inviteDialog", true);
        break;
      }
    }
  }, []);

  /* add all declarative app redirects here */
  /*
  - push onboarding if not OB'd yet
  - push write if OB'd
  */
  return (
    <>
      {pathname === "/" &&
        (onboardingMode
          ? history.replace("/onboarding")
          : history.replace("/write"))}
      {!pathname.includes("onboarding") &&
        onboardingMode &&
        history.replace("/onboarding")}
      {pathname.includes("onboarding") &&
        !onboardingMode &&
        history.replace("/write")}
      <>{children}</>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  onboardingMode: state.appState.settings.flags.onboarding_mode,
  onboardingStep: state.appState.settings.flags.onboarding_step_text,
  stepCompleted: state.appState.settings.flags.step_completed_text,
});

const mapDispatchToProps = {
  toggleVisibility: AppState.Visibility.toggle,
  extensionLogin: Sagas.AppState.extensionLogin,
  updateExtensionInstallFlag: Sagas.AppState.updateExtensionInstallFlag,
  updateOnboardingFlags: Sagas.AppState.updateOnboardingFlags,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectRoute);
