const EXISTING_USER_TFGF_BUMPER = {
  UPDATE: `
        mutation MyMutation($uid: String!, $existing_user_tfgf_bumper: Boolean) {
            update_db_flags(where: {id: {_eq: $uid}}, _set: {existing_user_tfgf_bumper: $existing_user_tfgf_bumper}) {
              affected_rows
              returning {
                id
              }
            }
          }
        `,
};

export default EXISTING_USER_TFGF_BUMPER;
