const SUGGESTIONS_ENABLED = {
  UPDATE: `
      mutation updateSuggestionsEnabled($uid: String!, $suggestions_enabled: Boolean) {
          update_db_flags(where: {id: {_eq: $uid}}, _set: {suggestions_enabled: $suggestions_enabled}) {
              affected_rows
              returning {
                  id
              }
          }
      }
      `,
};

export default SUGGESTIONS_ENABLED;
