import DB from "../constants";
import createHasuraRequest from "../utils";

export type GalleryTemplates = {
  read: (token: string) => any;
};

const gallery_templates: GalleryTemplates = {
  read: async (token) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.GALLERY_TEMPLATES.READ,
      token
    );

    return await request({});
  },
};

export { gallery_templates };
