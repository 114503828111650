const PERSONA = {
  UPDATE: `
      mutation updatePersona($id: String, $persona: String) {
        update_db_account_settings(_set: {persona: $persona}, where: {id: {_eq: $id}}) {
          affected_rows
          returning {
            persona
            usage_reason
            id
          }
        }
      }
      `,
};

export default PERSONA;
