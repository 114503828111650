/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable arrow-body-style */
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import useQuery from "../hooks/use-query";
import { Circle } from "../icons";
import logger from "../logger";
import Sagas from "../redux/actions/sagas";
import { SagasAppStateRepo } from "../redux/actions/sagas/app-state";
import { getExtensionId } from "../utils/env-respective-return";

export type ExtensionUninstallProps = {
  children: React.ReactNode;
  auth: any;
  updateExtensionInstallFlag: SagasAppStateRepo["updateExtensionInstallFlag"];
};

const setUninstallUID = (uid: string) =>
  localStorage.setItem("uninstall_uid", uid);

const getUninstallUID = () => localStorage.getItem("uninstall_uid");

const removeUninstallUID = () => localStorage.removeItem("uninstall_uid");

const trackUninstall = (uid: string) => {
  window.analytics.identify(uid);
  window.analytics.track("Extension Uninstalled", {
    environment: process.env.REACT_APP_APP_ENV,
  });
};

const extensionId = getExtensionId();

const ExtensionUninstall: FunctionComponent<ExtensionUninstallProps> = ({
  children,
  auth,
  updateExtensionInstallFlag,
}) => {
  const query = useQuery();
  const [triggeredOnce, setTrigger] = useState(true);

  useEffect(() => {
    logger().log("extesnion uninstall useEffect called");
    const type = query.get("type");

    if (type && type === "extension_uninstall") {
      const uninstall_uid = query.get("uid");
      const uninstall_email = query.get("email") || "";

      logger().log("extension uninstall redirection");

      if (uninstall_uid) {
        logger().log(`Uninstall uid from query params: ${uninstall_uid}`);
        if (
          auth.isLoaded &&
          !auth.isEmpty &&
          auth.uid !== "" &&
          triggeredOnce
        ) {
          /* logged in currently */
          logger().log("The web app is logged in");
          if (uninstall_uid === auth.uid) {
            logger().log("The uids match, initiating actions.");
            /* the same user account */
            trackUninstall(uninstall_uid);
            updateExtensionInstallFlag(false);
          } else {
            /*
            Edge case
            maybe a different user account
            we don't want to request Hasura, permissions are limited
            store for future auth with the same credentials
            */
            logger().log("The uids are different, storing id for the future.");
            trackUninstall(uninstall_uid);
            setUninstallUID(uninstall_uid);
          }

          setTrigger(false);
        } else if (auth.isLoaded && auth.isEmpty && triggeredOnce) {
          /* not logged in currently */
          logger().log("The web app is not logged in, storing for the future");
          trackUninstall(uninstall_uid);
          setUninstallUID(uninstall_uid);
          setTrigger(false);
        }

        /* open typeform survey after correct actions have been taken */
        window.open(
          `https://bdwk08hru1x.typeform.com/to/${
            process.env.REACT_APP_NODE_ENV === "production"
              ? "ZWH7RtT2"
              : "RFwRZakf"
          }#uid=${uninstall_uid}&email=${uninstall_email}`,
          "_self"
        );
      }
    } else {
      logger().log("URL is not an uninstall redirection");
      /* general case for all mounts of this component after login */
      if (auth.isLoaded && !auth.isEmpty && auth.uid !== "") {
        const stored_uninstall_uid = getUninstallUID();
        logger().log(
          `The web app is logged in, retrieving any stored uninstall id: ${stored_uninstall_uid} `
        );

        /* Edge case: if the web app is logged out but the extension has a redirection */
        /* occurs on the next login of the web app */
        if (
          stored_uninstall_uid !== null &&
          auth.uid === stored_uninstall_uid &&
          triggeredOnce
        ) {
          logger().log(
            "A previous uninstall id matches the current user, checking if the extension has been reinstalled in the meantime"
          );

          /* but first let's recheck if in the meantime the extension has not been installed again */
          /* chrome.runtime.sendMessage is not available for localhost */
          process.env.REACT_APP_NODE_ENV !== "development" &&
            chrome.runtime.sendMessage(
              extensionId,
              { type: "ping" },
              (response) => {
                if (chrome.runtime.lastError) {
                  logger().log(
                    `Pinged the extension but there is no response, initiating extension_uninstall actions`
                  );
                  updateExtensionInstallFlag(false);
                  removeUninstallUID();
                  setTrigger(false);
                }

                logger().log(`extension response: ${response}`);

                if ("type" in response && response.type === "pong") {
                  logger().log(
                    `Pinged the extension it is installed again, removing redundant uninstall uid`
                  );
                  removeUninstallUID();
                  setTrigger(false);
                }
              }
            );
        }
      }
    }
  }, [query, auth.isLoaded, auth.isEmpty, auth.uid, triggeredOnce]);

  if (query.get("type") && query.get("type") === "extension_uninstall") {
    return (
      <>
        <div className="flex items-center justify-center w-screen h-screen bg-gray-50">
          <Circle className="w-10 h-10 text-gray-300 animate-spin dark:text-gray-700" />
        </div>
      </>
    );
  }
  return <>{children}</>;
  // eslint-disable-next-line no-else-return
};

const mapState = (state: any) => ({
  auth: state.auth,
});

const mapDispatch = {
  updateExtensionInstallFlag: Sagas.AppState.updateExtensionInstallFlag,
};

export default connect(mapState, mapDispatch)(ExtensionUninstall);
