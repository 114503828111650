import Local, { LocalRepo } from "./local";
import Remote, { RemoteRepo } from "./remote";
import Sagas, { SagasRepo } from "./sagas";
import LOCAL from "./local/constants";
import REMOTE from "./remote/constants";
import SAGAS from "./sagas/constants";
import ERROR from "./errors/constants";

export type ActionRepo = {
  Local: LocalRepo;
  Remote: RemoteRepo;
  Sagas: SagasRepo;
};

const Action = {
  Local,
  Remote,
  Sagas,
};

export const ACTION = {
  LOCAL,
  REMOTE,
  SAGAS,
  ERROR,
};

export default Action;
