/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { Reducer } from "redux";
import { ACTION } from "../actions/index";
import appState, { BillingPlanItem } from "../initial-state/app-state";

const appStateReducer = (state: any = appState, action: any): Reducer => {
  switch (action.type) {
    case ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE: {
      const { id, targetState } = action.payload;
      return {
        ...state,
        visibilities: {
          ...state.visibilities,
          [id]: targetState,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.OPEN: {
      const { useCaseId, id, targetState } = action.payload;
      return {
        ...state,
        useCaseId,
        visibilities: {
          ...state.visibilities,
          [id]: targetState,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.CONFIG_EDIT.UPDATE: {
      const { id, targetState } = action.payload;
      return {
        ...state,
        useCaseId: "",
        visibilities: {
          ...state.visibilities,
          [id]: targetState,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.CONFIG_ADD_DIALOG.OPEN: {
      return {
        ...state,
        visibilities: {
          ...state.visibilities,
          configAddDialog: true,
          configList: false,
        },
        useCaseId: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.ACTIVE_USE_CASE.UPDATE: {
      const { id } = action.payload;
      return {
        ...state,
        activeUseCase: id,
        useCaseId: id,
      };
    }

    case ACTION.LOCAL.APP_STATE.DARK_MODE.ENABLE: {
      return {
        ...state,
        settings: { ...state.settings, darkMode: true },
      };
    }

    case ACTION.LOCAL.APP_STATE.DARK_MODE.DISABLE: {
      return {
        ...state,
        settings: { ...state.settings, darkMode: false },
      };
    }

    case ACTION.LOCAL.APP_STATE.KEYBOARD_SHORTCUT.ENABLE: {
      const { field } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          keyboardShortcuts: {
            ...state.settings.keyboardShortcuts,
            [field]: true,
          },
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.KEYBOARD_SHORTCUT.DISABLE: {
      const { field } = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          keyboardShortcuts: {
            ...state.settings.keyboardShortcuts,
            [field]: false,
          },
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.REPLY_MODE.ENABLE: {
      return {
        ...state,
        reply: true,
      };
    }
    case ACTION.LOCAL.APP_STATE.REPLY_MODE.DISABLE: {
      return {
        ...state,
        reply: false,
      };
    }

    case ACTION.LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.ENABLE: {
      return {
        ...state,
        promptCharacterLimit: true,
      };
    }
    case ACTION.LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.DISABLE: {
      return {
        ...state,
        promptCharacterLimit: false,
      };
    }

    case ACTION.SAGAS.EVENT_STREAM.REQUEST.SEND: {
      return {
        ...state,
        previous_second_body: state.second_body,
      };
    }

    // case ACTION.SAGAS.EVENT_STREAM.REQUEST.RESEND: {
    //   return {
    //     ...state,
    //     previous_second_body:
    //       state.previous_second_body === ""
    //         ? state.second_body
    //         : state.previous_second_body,
    //   };
    // }

    case ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.ENABLE: {
      // const { field } = action.payload;
      console.log("something feedback happened");
      return {
        ...state,
        flowriting: true,
        feedback: true,
        promptState: 1,
        second_body: "",
        completion_body: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.DISABLE: {
      //   const { field } = action.payload;

      return {
        ...state,
        flowriting: false,
        feedback: false,
        beginning_content_to_flush: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.WARNED_LIST.UPDATE: {
      const { field, newArray } = action.payload;
      return {
        ...state,
        [field]: newArray,
      };
    }

    case ACTION.LOCAL.APP_STATE.WARNED_LIST.CLEAR: {
      const { field } = action.payload;
      return {
        ...state,
        [field]: [],
      };
    }

    case ACTION.LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.CLEAR: {
      return {
        ...state,
        previous_second_body: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.UPDATE: {
      return {
        ...state,
        second_body: state.previous_second_body,
      };
    }

    case ACTION.LOCAL.APP_STATE.BODIES.CLEAR: {
      return {
        ...state,
        first_body: "",
        second_body: "",
        completion_body: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.SECOND_BODY.CLEAR: {
      return {
        ...state,
        second_body_1: "",
        second_body_2: "",
        second_body_3: "",
      };
    }

    case ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        [field]: newContent,
      };
    }

    case ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.ENABLE: {
      const { field } = action.payload;
      return {
        ...state,
        [field]: true,
      };
    }

    case ACTION.LOCAL.APP_STATE.PROMPT_STATE.FORWARD: {
      return {
        ...state,
        promptState: state.promptState + 1,
      };
    }
    case ACTION.LOCAL.APP_STATE.PROMPT_STATE.BACKWARD: {
      return {
        ...state,
        promptState: state.promptState > 0 ? state.promptState - 1 : 0,
      };
    }

    case ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.DISABLE: {
      const { field } = action.payload;
      return {
        ...state,
        [field]: false,
      };
    }

    case ACTION.LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE: {
      const { identityProperties } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          identityProperties,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE_FIELD: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          identityProperties: {
            ...state.settings.identityProperties,
            [field]: newContent,
          },
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          [field]: newContent,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.GENERATION_COUNT.UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        billing: {
          ...state.billing,
          plan_limit: data.PLAN_INTERVAL,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.UPDATE: {
      const {
        customer,
        price_id,
        subscription,
        trial_days,
        scheduled_subscription,
      } = action.payload.data;
      const {
        plan,
        status,
        trial_end,
        trial_start,
        current_period_end,
        current_period_start,
        cancel_at_period_end,
      } = subscription || {};
      const { active, amount, currency, interval } = plan || {};

      return {
        ...state,
        billing: {
          ...state.billing,
          stripe_customer_id: customer?.id,
          default_currency: customer.default_currency,
          subscription_id: subscription?.id,
          price_id: price_id || state.billing.price_id,
          customer_plan: {
            id: plan?.id,
            active,
            amount: (amount || 0) / 100,
            currency,
            interval,
          },
          referral: {
            discount: customer?.discount,
            metadata: customer?.metadata,
          },
          scheduled_subscription: {
            ...scheduled_subscription,
          },
          status,
          trial_end,
          trial_start,
          trial_days,
          current_period_end,
          current_period_start,
          cancel_at_period_end,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.CHECK_SUBSCRIPTION: {
      const { subscription } = action.payload;
      const {
        plan,
        status,
        trial_end,
        trial_start,
        current_period_end,
        current_period_start,
        cancel_at_period_end,
      } = subscription || {};
      const { active, amount, currency, interval } = plan || {};
      return {
        ...state,
        billing: {
          ...state.billing,
          subscription_id: subscription?.id,
          customer_plan: {
            id: plan?.id,
            active,
            amount: (amount || 0) / 100,
            currency,
            interval,
          },
          status,
          trial_end,
          trial_start,
          current_period_end,
          current_period_start,
          cancel_at_period_end,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.CREATE_SCHEDULED_SUBSCRIPTION: {
      const { subscription } = action.payload.data;
      return {
        ...state,
        billing: {
          ...state.billing,
          scheduled_subscription: subscription,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.PLANS.UPDATE: {
      const { data } = action.payload;
      const plans = data.map((d: BillingPlanItem) => ({
        id: d.id,
        active: d.active,
        amount: d.amount / 100,
        currency: d.currency,
        interval: d.interval,
        nickname: d.nickname,
        product: d.product,
      }));

      return {
        ...state,
        billing: {
          ...state.billing,
          plans,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING.PLAN.UPDATE: {
      const { id, active, amount, currency, interval } = action.payload.data;

      const plan = {
        id: id,
        active: active,
        amount: amount / 100,
        currency: currency,
        interval: interval,
      };
      console.log("plan .APP_STATE.BILLING.PLAN", plan);
      return {
        ...state,
        billing: {
          ...state.billing,
          plan,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        billing: {
          ...state.billing,
          [field]: newContent,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.FLAGS.UPDATE: {
      const { field, newContent } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          flags: {
            ...state.settings.flags,
            [field]: newContent,
          },
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.FLAGS.UPDATE_MULTIPLE: {
      const { data } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          flags: {
            ...state.settings.flags,
            ...data,
          },
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.OB_SURVEY.UPDATE: {
      const { data } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          obSurvey: data,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.EXTENSION_INTEGRATION.ENABLE: {
      const { value } = action.payload;
      const { extension_disabled_integrations } = state.settings;
      const index = extension_disabled_integrations.indexOf(value);
      const newArr = extension_disabled_integrations.slice();
      if (index > -1) {
        newArr.splice(index, 1);
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          extension_disabled_integrations: newArr,
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.EXTENSION_INTEGRATION.DISABLE: {
      const { value } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          extension_disabled_integrations: [
            ...state.settings.extension_disabled_integrations,
            value,
          ],
        },
      };
    }

    case ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE: {
      const { settings } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          ...settings,
        },
      };
    }

    // case ACTION.SAGAS.EVENT_STREAM.START: {
    //   return {
    //     ...state,
    // flowriting: true,
    // completion_body: "",
    // second_body: "",
    //   };
    // }

    case ACTION.SAGAS.EVENT_STREAM.ONBOARDING.CREATE: {
      // const { newString } = action.payload;
      return {
        ...state,
        //   completion_body: state.completion_body + newString,
        //   second_body: state.second_body + newString,
      };
    }

    case ACTION.LOCAL.APP_STATE.COMPLETION.CONCAT: {
      const { newString, bodyId } = action.payload;
      const updatedString = (
        state[`completion_body_${bodyId}`] + newString
      ).replaceAll(/([.][.])/g, ".");

      return {
        ...state,
        [`completion_body_${bodyId}`]: updatedString,
        [`second_body_${bodyId}`]: updatedString,
      };
    }

    case ACTION.LOCAL.APP_STATE.COMPLETION.CLEAR: {
      return {
        ...state,
        completion_body: "",
        completion_body_1: "",
        completion_body_2: "",
        completion_body_3: "",
      };
    }

    default:
      return state;
  }
};

export default appStateReducer;
