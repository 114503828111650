import { user_templates, UserTemplates } from "./user_templates";
import { gallery_templates, GalleryTemplates } from "./gallery_templates";
import { default_templates, DefaultTemplates } from "./default_templates";
import { bullet_suggestions, BulletSuggestions } from "./bullet_suggestions";
import { dos_templates, DosTemplates } from "./dos_templates";
import { templates_order, TemplatesOrder } from "./templates_order";
import {
  iteration_instructions,
  IterationInstructions,
} from "./iteration_instructions";

export type Templates = {
  user_templates: UserTemplates;
  default_templates: DefaultTemplates;
  gallery_templates: GalleryTemplates;
  bullet_suggestions: BulletSuggestions;
  dos_templates: DosTemplates;
  templates_order: TemplatesOrder;
  iteration_instructions: IterationInstructions;
};

const templates: Templates = {
  user_templates,
  default_templates,
  gallery_templates,
  bullet_suggestions,
  dos_templates,
  templates_order,
  iteration_instructions,
};

export { templates };
