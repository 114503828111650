/*
A singular existence of this error can occur at a given point in time.
Therefore, this largely represents an application level error such as a network request that has failed.
*/

export type AppErrorBodyType = "Stream Error" | string;

export type AppError = {
  status: boolean;
  body: {
    type: AppErrorBodyType;
    message: string;
    timestamp: Date | undefined;
  };
};

const appError: any = {
  status: false,
  body: {
    type: "",
    message: "",
    timestamp: 0,
  },
};

export default appError;
