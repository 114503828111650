/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { flush, call } from "redux-saga/effects";

function* concatInterrupt(channel: any) {
  try {
    yield flush(channel);
  } catch (e) {
    yield call(console.log, e);
  }
}

export default concatInterrupt;
