import { ACCOUNT_SETTINGS } from "./account_settings";
import { TEMPLATES } from "./templates";
import { TEMPLATE } from "./template";
import { FLAGS } from "./flags";
import { GENERATION } from "./generation";
import { BILLING } from "./billing";
import { INVITE_CODES } from "./invite_codes";
import { OB_SURVEY } from "./ob_survey";
import { STREAM_OPTIONS } from "./stream_options";

/*
Structured as CRUD operations
*/

const DB = {
  ACCOUNT_SETTINGS,
  STREAM_OPTIONS,
  TEMPLATES,
  TEMPLATE,
  FLAGS,
  GENERATION,
  BILLING,
  INVITE_CODES,
  OB_SURVEY,
};

export default DB;
