/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import DB from "../constants";
import createHasuraRequest from "../utils";

export type Billing = {
  read: (uid: string, token: string) => any;
};

const billing: Billing = {
  read: async (uid, token) => {
    const request = createHasuraRequest(DB.BILLING.READ, token);
    const parameters = {
      uid,
    };
    return await request(parameters);
  },
};

export { billing };
