const USAGE_REASON = {
  UPDATE: `
  mutation updateUR($id: String, $usage_reason: _text) {
    update_db_account_settings(_set: {usage_reason: $usage_reason}, where: {id: {_eq: $id}}) {
      affected_rows
      returning {
        usage_reason
        persona
        id
      }
    }
  }
        `,
};

export default USAGE_REASON;
