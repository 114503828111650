/* eslint-disable import/prefer-default-export */
const STREAM_OPTIONS = {
  READ: `
      query ReadStreamOptions ($stream_type: String!){
          stream_options(where: {stream_type: {_eq:$stream_type}}) {
              stream_type
              engine_1
              engine_2
              engine_3
              function_1
              function_2
              function_3
          }
      }
      `,
};

export { STREAM_OPTIONS };
