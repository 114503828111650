/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { Reducer } from "redux";
import { ACTION } from "../actions/index";
import { compliationStorage, storage } from "../initial-state/local-storage";

const localStorageReducer = (
  state: any = compliationStorage,
  action: any
): Reducer => {
  switch (action.type) {
    case ACTION.LOCAL.STORAGE.LOAD: {
      const local = storage.getItem("generations");
      return {
        ...state,
        history: local,
      };
    }

    case ACTION.LOCAL.STORAGE.UPDATE: {
      const { generations } = action.payload;
      if (generations)
        return {
          ...state,
          history: generations,
        };

      return { ...state };
    }

    default:
      return state;
  }
};

export default localStorageReducer;
