/* eslint-disable @typescript-eslint/no-explicit-any */
import LOCAL from "./constants";

type UpdateFlagCallback = (newState: boolean) => any;

const createUpdateFlagCallback = (
  flag: "IS_LOADED" | "IS_LOADING" | "IS_EMPTY"
): UpdateFlagCallback => (newState) => ({
  type: LOCAL.AUTH.UPDATE_FLAG[flag],
  payload: { newState },
});

export type LocalAuthStateRepo = {
  Credentials: {
    set: (
      email: string,
      displayName: string,
      uid: string,
      cognitoUsername: string,
      idToken: string,
      accessToken: string,
      refreshToken: string,
      cognitoUser: any
    ) => any;
    reset: () => any;
    failure: (failureReason: string) => any;
    loading: () => any;
    setEmpty: () => any;
    setSignUpUser: (newContent: boolean) => any;
  };
  UpdateFlag: {
    isLoading: UpdateFlagCallback;
    isLoaded: UpdateFlagCallback;
    isEmpty: UpdateFlagCallback;
  };
  anyFieldUpdate: (field: string, newContent: any) => any;
  resetPasswordlessSignIn: () => any;
};

const AuthState: LocalAuthStateRepo = {
  Credentials: {
    set: (
      email,
      displayName,
      uid,
      cognitoUsername,
      idToken,
      refreshToken,
      accessToken,
      cognitoUser
    ) => ({
      type: LOCAL.AUTH.CREDENTIALS.SET,
      payload: {
        email,
        displayName,
        uid,
        cognitoUsername,
        idToken,
        refreshToken,
        accessToken,
        cognitoUser,
      },
    }),
    reset: () => ({
      type: LOCAL.AUTH.CREDENTIALS.RESET,
      payload: {},
    }),
    failure: (failureReason) => ({
      type: LOCAL.AUTH.CREDENTIALS.FAILURE,
      payload: { failureReason },
    }),
    loading: () => ({
      type: LOCAL.AUTH.CREDENTIALS.LOADING,
      payload: {},
    }),
    setEmpty: () => ({
      type: LOCAL.AUTH.CREDENTIALS.SET_EMPTY,
      payload: {},
    }),
    setSignUpUser: (newContent) => ({
      type: LOCAL.AUTH.CREDENTIALS.SET_SIGN_UP_USER,
      payload: { newContent },
    }),
  },
  UpdateFlag: {
    isEmpty: createUpdateFlagCallback("IS_EMPTY"),
    isLoaded: createUpdateFlagCallback("IS_LOADED"),
    isLoading: createUpdateFlagCallback("IS_LOADING"),
  },
  anyFieldUpdate: (field, newContent) => ({
    type: LOCAL.AUTH.ANY_FIELD_UPDATE,
    payload: { field, newContent },
  }),
  /* on third wrong attempt of code input, reset */
  resetPasswordlessSignIn: () => ({
    type: LOCAL.AUTH.RESET_PASSWORDLESS_SIGN_IN,
    payload: {},
  }),
};

export default AuthState;
