export type GenerationHistoryItem = {
  id: string;
  iter_id?: string;
  transformed_output: string;
  recipient: string;
  template_name: string;
  created_at: Date;
  reply?: boolean;
  first_body_language?: string;
  second_body_language?: string;
};

export type GenerationHistory = {
  data: GenerationHistoryItem[];
};

const generationHistory: GenerationHistory = {
  data: [],
};

export default generationHistory;
