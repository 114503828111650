import { prependRoute } from "./utils";

const flowRoutes = {
  time_saving: "time-saving",
  recipient_recognition: "recipient-recognition",
  preview_prompt: "preview_prompt",
  save_use_case: "save_use_case",
  identify_user: "identify_user",
  send_vip_invite: "send_vip_invite",
  instant_access: "instant-access",
  log_event_tracking: "log-event-tracking",
  log_segment_identify: "log-segment-identify",
  get_referral_token: "get-referral-token",
};

export const flow = prependRoute("flow/", flowRoutes);
