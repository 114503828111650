import { feedback, Feedback } from "./feeback";
import { history, History } from "./history";

export type Generation = {
  feedback: Feedback;
  history: History;
};

const generation: Generation = {
  feedback,
  history,
};

export { generation };
