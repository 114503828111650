const ITERATEUX_BUMPER_FLOW = {
  UPDATE: `
    mutation updateIterateUXBumper($uid: String!, $iterateux_bumper_flow: Boolean) {
        update_db_flags(where: {id: {_eq: $uid}}, _set: {iterateux_bumper_flow: $iterateux_bumper_flow}) {
            affected_rows
            returning {
                id
            }
        }
    }
    `,
};

export default ITERATEUX_BUMPER_FLOW;
