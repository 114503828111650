const COMPONENT_ERROR = {
  RECIPIENT_NAME: {
    ANY_FIELD_ENABLE: "ERROR_COMPONENT_ERROR_RECIPIENT_NAME_ANY_FIELD_ENABLE",
    ANY_FIELD_DISABLE: "ERROR_COMPONENT_ERROR_RECIPIENT_NAME_ANY_FIELD_DISABLE",
  },
  RECIPIENT_DESCRIPTION: {
    ANY_FIELD_ENABLE:
      "ERROR_COMPONENT_ERROR_RECIPIENT_DESCRIPTION_ANY_FIELD_ENABLE",
    ANY_FIELD_DISABLE:
      "ERROR_COMPONENT_ERROR_RECIPIENT_DESCRIPTION_ANY_FIELD_DISABLE",
  },
  INTENTION: {
    ANY_FIELD_ENABLE: "ERROR_COMPONENT_ERROR_INTENTION_ANY_FIELD_ENABLE",
    ANY_FIELD_DISABLE: "ERROR_COMPONENT_ERROR_INTENTION_ANY_FIELD_DISABLE",
  },
};

export default COMPONENT_ERROR;
