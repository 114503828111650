import SAGAS from "./constants";

export type SagasUseCasesRepo = {
  load(): any;
  create(): any;
  update(useCasesArray: any[]): any;
  reorder(useCasesArray: any[]): any;
  Gallery: {
    load(): any;
    import(selected: string[]): any;
    defaultImport(selected: string): any;
    obImport(selected: string[]): any;
  };
};

const UseCases: SagasUseCasesRepo = {
  create: () => ({
    type: SAGAS.USE_CASES.CREATE,
    payload: {},
  }),
  update: (useCasesArray) => ({
    type: SAGAS.USE_CASES.UPDATE,
    payload: { useCasesArray },
  }),
  load: () => ({
    type: SAGAS.USE_CASES.LOAD,
    payload: {},
  }),
  Gallery: {
    load: () => ({
      type: SAGAS.USE_CASES.GALLERY_TEMPLATES.LOAD,
      payload: {},
    }),
    import: (selected) => ({
      type: SAGAS.USE_CASES.GALLERY_TEMPLATES.IMPORT,
      payload: { selected },
    }),
    defaultImport: (selected) => ({
      type: SAGAS.USE_CASES.GALLERY_TEMPLATES.DEFAULT_IMPORT,
      payload: { selected },
    }),
    obImport: (selected) => ({
      type: SAGAS.USE_CASES.GALLERY_TEMPLATES.OB_IMPORT,
      payload: { selected },
    }),
  },
  reorder: (useCasesArray) => ({
    type: SAGAS.USE_CASES.REORDER,
    payload: { useCasesArray },
  }),
};

export default UseCases;
