import { call, take } from "redux-saga/effects";
import { HubEvent } from "../utils/types";
import authSubscription from "./channel";
import onFederationReAttempt from "./handlers/on-federation-reattempt";
import onSignIn from "./handlers/on-signin";
import onSignInFailure from "./handlers/on-signin-failure/index";
import onSignOut from "./handlers/on-signout";

function* authListener(): any {
  const channel = yield call(authSubscription);

  try {
    while (true) {
      const { payload } = yield take(channel);
      // console.log("PAYLOAD IN HUB", payload);

      switch (payload.event) {
        case HubEvent.SIGN_IN: {
          yield onSignIn(payload);
          break;
        }
        case HubEvent.SIGN_UP: {
          /* add data triggers */
          yield onSignIn(payload);
          break;
          break;
        }
        case HubEvent.SIGN_OUT: {
          yield onSignOut();
          break;
        }
        case HubEvent.SIGN_IN_FAILURE: {
          yield onSignInFailure(payload);
          break;
        }
        case HubEvent.PARSING_CALLBACK_URL: {
          yield onFederationReAttempt(payload);
          break;
        }
        case HubEvent.CONFIGURED: {
          console.info("Auth configured!");
          break;
        }
        default:
        // console.log("Other Auth Event", payload);
      }
    }
  } catch (e) {
    yield call(console.log, e);
    channel.close();
  }
}

export default authListener;
