const prefix = "ACTION.SAGAS.AUTH";

const AUTH = {
  CREDENTIALS: {
    GET_CURRENT: `${prefix}.CREDENTIALS.GET_CURRENT`,
    PASSWORDLESS_SIGN_UP: `${prefix}.CREDENTIALS.PASSWORDLESS_SIGN_UP`,
    PASSWORDLESS_SIGN_IN: `${prefix}.CREDENTIALS.PASSWORDLESS_SIGN_IN`,
    /* triggered on code input from the user */
    ANSWER_AUTH_CHALLENGE: `${prefix}.CREDENTIALS.ANSWER_AUTH_CHALLENGE`,
  },
};

export default AUTH;
