import DB from "../constants";
import createHasuraRequest from "../utils";

export type ExtensionInstall = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const extension_install: ExtensionInstall = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.EXTENSION_INSTALL.UPDATE,
      token
    );
    const parameters = {
      uid,
      extension_install: newContent,
    };
    return await request(parameters);
  },
};

export { extension_install };
