const SETTINGS = {
  CREATE: `
    mutation createUser($objects: [db_account_settings_insert_input!]!, $user: [db_users_insert_input!]!,
        $flags: [db_flags_insert_input!]!, 
      ) {
        insert_db_account_settings(objects: $objects) {
          affected_rows
        }
        insert_db_users(objects: $user) {
          affected_rows
        }
        insert_db_flags(objects: $flags) {
          affected_rows
        }
      }
    `,
  READ: `
    query settQuery($uid: String!, $environment: String!) {
      db_account_settings(where: {id: {_eq: $uid}}) {
        bootstrapped
        company_description
        company_name
        completion_length
        creativity
        dark_mode
        development_mode
        email
        first_name
        first_preposition
        flowritten_tag
        id
        keyboard_shortcuts_id
        lastname
        log_out
        role
        second_preposition
        selected_greeting
        selected_signature
        temperature
        persona
        usage_reason
        extension_disabled_integrations
        processed_role
        processed_company_name
        processed_company_description
        keyboard_shortcut {
          id
          shift_enter
          shift_left
          shift_right
        }
      }
      db_flags(where: {id:{ _eq: $uid}}) {
        check_list
        extension_install
        feedback_count
        id
        new_identity_added
        onboarding_mode
        onboarding_step
        steps_completed
        onboarding_step_text
        step_completed_text
        vip_invite_count
        migration_completed
        first_usage_bumper
        existing_user_tfgf_bumper
        migui_bumper_flow
        iterateux_bumper_flow
        copy_used
        generation_limit_exceeded
        generation_count
        left_over_generation_limit_from_old_plan
        suggestions_enabled
      }
      db_billing(where: {uid:  {_eq:$uid}}) {
        id
        plan_id
        price_id
        stripe_id
        subscription_id
        uid
        PLAN_INTERVAL { 
          id
          name
          interval
          generation_limit
          stripe_product_id
          rank
        }
      }
      kill_switch(where: {environment: {_eq: $environment}}) {
        active
        environment
        title
        description
        updated_at
      }
    }
    `,
  UPDATE: `
    mutation updateSettings (
      $id: String,
      $email: String,
      $first_name: String,
      $creativity: String,
      $completion_length: String,
      $lastname: String,
      $role: String,
      $company_name: String,
      $first_preposition: String,
      $second_preposition: String,
      $company_description: String,
      $selected_greeting: String,
      $selected_signature: String,
      $temperature: Int,
      $log_out: Boolean,
      $flowritten_tag: String,
      $development_mode: Boolean,
      $dark_mode: Boolean,
      $keyboard_shortcuts_id: String,
      $usage_reason: _text,
      $persona: String,
      $bootstrapped: Boolean,
      $processed_role: String,
      $processed_company_name: String,
      $processed_company_description: String,
      $extension_disabled_integrations: _text
   )
   {
    update_db_account_settings(where: {id: {_eq: $id}}, _set: {
      id: $id,
      email: $email,
      first_name: $first_name,
      creativity: $creativity,
      completion_length: $completion_length,
      lastname: $lastname,
      role: $role,
      company_name: $company_name,
      first_preposition: $first_preposition,
      second_preposition: $second_preposition,
      company_description: $company_description,
      selected_greeting: $selected_greeting,
      selected_signature: $selected_signature,
      temperature: $temperature,
      log_out: $log_out,
      flowritten_tag: $flowritten_tag,
      development_mode: $development_mode,
      dark_mode: $dark_mode,
      usage_reason: $usage_reason,
      persona: $persona,
      bootstrapped: $bootstrapped,
      keyboard_shortcuts_id: $keyboard_shortcuts_id,
      processed_role: $processed_role,
      processed_company_name: $processed_company_name,
      processed_company_description: $processed_company_description,
      extension_disabled_integrations: $extension_disabled_integrations
    }) {
      returning {
        id
      }
      affected_rows
    }
  }
  `,
};

export default SETTINGS;
