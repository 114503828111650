const ITERATION_INSTRUCTIONS = {
  READ: `
      query getInstructions {
        templates_iteration_instructions {
          id
          text
        }
      }
    `,
};

export default ITERATION_INSTRUCTIONS;
