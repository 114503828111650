/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { FunctionComponent } from "react";
import { Redirect, Route } from "react-router";
import { connect } from "react-redux";
import { Circle } from "./icons";

const AuthIsLoaded: FunctionComponent<any> = (props: any) => {
  const { auth, children, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(location) => {
        if (
          (!auth.isLoaded && auth.isEmpty && !auth.hasFailed) ||
          auth.isLoading
        )
          return (
            <div className="flex items-center justify-center w-screen h-screen bg-gray-50 dark:bg-gray-900">
              <Circle className="w-10 h-10 text-gray-300 animate-spin dark:text-gray-700" />
            </div>
          );
        // else if (auth.isLoaded && !auth.isEmpty && !auth.hasFailed)
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/write",
        //       }}
        //     />
        //   );
        return children;
      }}
    />
  );
};

const mapStateToProps = (state: any, props: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AuthIsLoaded);
