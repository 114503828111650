/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactComponent as ThumbsDown } from "./thumbsdown.svg";
import { ReactComponent as ThumbsUp } from "./thumbsup.svg";
import { ReactComponent as VideoIcon } from "./video.svg";
import { ReactComponent as FormsIcon } from "./forms.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as ModalsIcon } from "./modals.svg";
import { ReactComponent as PagesIcon } from "./pages.svg";
import { ReactComponent as HandIcon } from "./hand.svg";
import { ReactComponent as HeartIcon } from "./heart.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as ForbiddenIcon } from "./forbidden.svg";
import { ReactComponent as GithubIcon } from "./github.svg";
import { ReactComponent as TwitterIcon } from "./twitter.svg";
import { ReactComponent as AnnotationIcon } from "./annotation.svg";
import { ReactComponent as DocumentText } from "./document-text.svg";
import { ReactComponent as ChatIcon } from "./chat.svg";
import { ReactComponent as ChatSolidIcon } from "./chatSolid.svg";
import { ReactComponent as CheckIcon } from "./check.svg";
import { ReactComponent as CheckIcon2 } from "./check-2.svg";
import { ReactComponent as PeopleIcon } from "./people.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as MoonIcon } from "./moon.svg";
import { ReactComponent as Megaphone } from "./megaphone.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as DropdownIcon } from "./dropdown.svg";
import { ReactComponent as OutlinePersonIcon } from "./outlinePerson.svg";
import { ReactComponent as OutlineCogIcon } from "./outlineCog.svg";
import { ReactComponent as OutlineLogoutIcon } from "./outlineLogout.svg";
import { ReactComponent as Cog } from "./cog.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Flowrite } from "./frite.svg";
import { ReactComponent as At } from "./at.svg";
import { ReactComponent as Duplicate } from "./duplicate.svg";
import { ReactComponent as Templates } from "./templates.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as Pen } from "./pen.svg";
import { ReactComponent as PenLight } from "./pen_light.svg";
import { ReactComponent as Question } from "./question.svg";
import { ReactComponent as Collection } from "./collection.svg";
import { ReactComponent as LinkedinIcon } from "./linkedin.svg";
import { ReactComponent as Length1 } from "./length-1.svg";
import { ReactComponent as Length2 } from "./length-2.svg";
import { ReactComponent as Length3 } from "./length-3.svg";
import { ReactComponent as Creativity1 } from "./creativity-1.svg";
import { ReactComponent as Creativity2 } from "./creativity-2.svg";
import { ReactComponent as Creativity3 } from "./creativity-3.svg";
import { ReactComponent as PlusCircle } from "./plus-circle.svg";
import { ReactComponent as DragDots } from "./drag-dots.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as Rewind } from "./rewind.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Exclamation } from "./exclamation.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as Chart } from "./chart.svg";
import { ReactComponent as ExternalLink } from "./external-link.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as QuestionMarkCircle } from "./question-mark-circle.svg";
import { ReactComponent as Usage } from "./usage.svg";

export {
  ThumbsDown,
  ThumbsUp,
  VideoIcon,
  FormsIcon,
  HomeIcon,
  ModalsIcon,
  PagesIcon,
  HandIcon,
  HeartIcon,
  EditIcon,
  TrashIcon,
  ForbiddenIcon,
  GithubIcon,
  TwitterIcon,
  AnnotationIcon,
  DocumentText,
  ChatIcon,
  ChatSolidIcon,
  CheckIcon,
  CheckIcon2,
  PeopleIcon,
  SearchIcon,
  MoonIcon,
  Megaphone,
  MenuIcon,
  DropdownIcon,
  OutlinePersonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
  Cog,
  Circle,
  Flowrite,
  At,
  Duplicate,
  Templates,
  Email,
  Pen,
  PenLight,
  Question,
  Collection,
  LinkedinIcon,
  Length1,
  Length2,
  Length3,
  Creativity1,
  Creativity2,
  Creativity3,
  PlusCircle,
  DragDots,
  Refresh,
  Rewind,
  Close,
  Exclamation,
  ChevronLeft,
  ChevronRight,
  Chart,
  ExternalLink,
  ArrowLeft,
  ArrowRight,
  QuestionMarkCircle,
  Usage,
};
