import { all, takeEvery } from "redux-saga/effects";
import { ACTION } from "../../../actions";
import onLogout from "./messages/on-logout";

/*
Broadcast channel acts as a pub-sub between different tabs belonging to the same origin
This acts as a method of communication between flowrite JS threads running in different browsing contexts
*/
function* broadcastChannelPublish(): any {
  yield all([
    takeEvery(ACTION.SAGAS.BROADCAST_CHANNEL.POST_MESSAGE.LOGOUT, onLogout),
  ]);
}

export default broadcastChannelPublish;
