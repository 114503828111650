/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable */
import { SSE } from "sse.js";
import ky from "ky";
import returnByEnv from "../../utils/env-respective-return";
import { AITId } from "../initial-state/use-cases";
import logger from "../../logger";
import { Auth } from "aws-amplify";
import { BillingPlanName } from "../../utils/billing";

const encodeForTransfer = (msg: string) => {
  msg.replace(/ͻ/gi, " ").replace(/ƺ/gi, "\n");
};

const customThrow = (resp: Response) => {
  throw new Error(`HTTP error! status: ${resp.status}`);
};

const makeSendable = (
  appState: any,
  useCase: any,
  userId: any,
  resend: boolean
) => {
  // check these in the backend -> if manually inserted
  const {
    recipient,
    first_body,
    previous_second_body,
    second_body,
    reply,
    seed_id,
    flowrite_mode,
    gen_id,
    developmentMode,
  } = appState;

  // to backend, no need to pass
  const {
    firstName,
    lastName,
    creativity,
    identity,
    completion_length,
  } = appState.settings;

  // console.log("raw format", useCase);

  const uC = useCase;

  /* saveTemplateName(uC.name); */

  // MAYBE USER_ID TO MAKE THE REQUEST

  // ENFORCE TYPES HERE
  const sendable = {
    recipient: recipient === "" ? "<name>" : recipient,
    first_body,
    second_body: resend ? previous_second_body : second_body,
    reply,
    creativity,
    completion_length,
    template_id: uC !== null ? uC.id : null,
    recipient_type: uC !== null ? uC.recipientType : null,
    intention: uC !== null ? uC.intention : null,
    instructions: uC !== null ? uC.instructions : null,
    identity,
    company_name: appState.settings.identityProperties.companyName,
    first_name: firstName,
    last_name: lastName,
    uid: userId,
    automatic_template: uC.template_collection_id === AITId,
    seed_id: resend ? seed_id : null,
    regeneration: resend,
    generation_count: 3,
    flowrite_mode: flowrite_mode !== null ? flowrite_mode : "email",
  };

  return JSON.stringify(sendable);
};

const requestHeaders = {
  default: {
    "Content-Type": "application/json",
    Accept: "application/json",
    mode: "cors",
    method: "post",
    crossDomain: "true",
    "Access-Control-Allow-Origin": "*",
  },
  sse: {
    "Content-Type": "application/json",
    Accept: "text/event-stream, application/json",
    mode: "cors",
    crossDomain: "true",
    "Access-Control-Allow-Origin": "*",
  },
};

const createEventStreamRequest = (
  appState: any,
  useCase: any,
  userId: any,
  resend: boolean
): any => {
  try {
    const source = new SSE(
      `${process.env.REACT_APP_BACKEND_HOST}/api/flow/new_stream`,
      {
        headers: {
          ...requestHeaders.sse,
        },
        method: "post",
        payload: makeSendable(appState, useCase, userId, resend),
      }
    );

    return source;
  } catch (e) {
    console.log(e);
  }
};

const makePreviewPromptSendable = (
  appState: any,
  useCase: any,
  userId: any,
  resend: boolean
) => {
  // check these in the backend -> if manually inserted
  const {
    recipient,
    first_body,
    previous_second_body,
    second_body,
    reply,
    seed_id,
    gen_id,
  } = appState;

  // to backend, no need to pass
  const {
    firstName,
    lastName,
    identity,
    //  creativity,
    completion_length,
    developmentMode,
  } = appState.settings;

  const getUseCase = (useCase: any) => {
    console.log("got it");
    if (developmentMode) {
      console.log("yessss");
      return useCase;
    }
    if (useCase !== null) {
      return useCase.content;
    }
    return null;
  };

  const uC = useCase;

  // MAYBE USER_ID TO MAKE THE REQUEST
  const generation_count = 3;
  // ENFORCE TYPES HERE
  const sendable = {
    recipient: recipient === "" ? "<name>" : recipient,
    generation_count,
    first_body,
    second_body: resend ? previous_second_body : second_body,
    reply,
    //  creativity,
    completion_length,
    //    use_case_id: uC !== null ? uC.id : null,
    recipient_type: uC !== null ? uC.recipientType : null,
    intention: uC !== null ? uC.intention : null,
    instructions: uC !== null ? uC.instructions : null,
    identity,
    first_name: firstName,
    last_name: lastName,
    uid: userId,
    automatic_template: uC === null,
    seed_id: resend ? seed_id : null,
    regeneration: resend,
  };
  return sendable;
};

const defaultReq = {
  headers: { "Access-Control-Allow-Origin": "*" },
  timeout: 30000,
  retry: {
    limit: 2,
    statusCodes: [408],
  },
};

// to array -> Object.keys(obj).map((key: any) => obj[key]);

const getInfoFromObject = (checkable: any) =>
  Object.keys(checkable)
    .filter((key: any) => key !== "stop")
    .map((key: any) => ({
      [key]: checkable[key],
    }));

const getPromptObject = (checkable: any) => {
  const prompt = checkable["prompt"];
  const promptStop = checkable["completion_settings"]["stop"];
  const anObject = { prompt: prompt.concat(promptStop) };
  return anObject;
};

/* not used <write-page-removal> */
const createPreviewPromptRequest = async (
  appState: any,
  useCase: any,
  userId: any,
  resend: boolean
) => {
  try {
    const test = makePreviewPromptSendable(appState, useCase, userId, resend);
    // console.log("testing starts");
    // console.log(test);

    const output = await ky
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/flow/preview_prompt`, {
        json: test,
        ...defaultReq,
      })
      .json();

    // needs to be rewritten, unsafe type
    const tmpObj = output as any;
    const testOne = getInfoFromObject(tmpObj["completion_settings"]);
    const obj = getPromptObject(output);

    return testOne.concat(obj);
  } catch (e) {
    console.log("createPreviewPromptRequest error message");
    console.log(e);
  }
};

const postMigratorRoute = () =>
  returnByEnv({
    development:
      "https://g83v9yws08.execute-api.eu-central-1.amazonaws.com/staging-2/aws-migrator",
    staging:
      "https://g83v9yws08.execute-api.eu-central-1.amazonaws.com/staging-2/aws-migrator",
    production:
      "https://bcskoiaw2d.execute-api.eu-central-1.amazonaws.com/production/aws-migrator",
  });

const postInviteRoute = () => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return "https://5zh7d5hpsj.execute-api.eu-central-1.amazonaws.com/production/use-code";
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    return "https://snscbzoip5.execute-api.eu-central-1.amazonaws.com/staging-2/use-code";
  }
  return "https://5zh7d5hpsj.execute-api.eu-central-1.amazonaws.com/production/use-code";
};

const getInviteRoute = () => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return "https://5zh7d5hpsj.execute-api.eu-central-1.amazonaws.com/production/create-code";
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    return "https://snscbzoip5.execute-api.eu-central-1.amazonaws.com/staging-2/create-code";
  }
  return "https://5zh7d5hpsj.execute-api.eu-central-1.amazonaws.com/production/create-code";
};

/* make dynamic later - fetch from Hasura */

const getPriceId = () => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return "price_1Nt6gaFzjYWnBpVCUhtNLFam";
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    /* USD - unlimited - new customer pricing */
    return "price_1N8SjiFzjYWnBpVCM1iJ4YQt";
  }
  return "price_1Nt6gaFzjYWnBpVCUhtNLFam";
};

export enum OLD_PRICE_IDS_STAGING {
  MONTHLY = "price_1KWhNjFzjYWnBpVCqKk5d8Ge",
  ANNUAL = "price_1KWhNjFzjYWnBpVC1BpZOer9",
}

export enum OLD_PRICE_IDS_PRODUCTION {
  MONTHLY = "price_1KOsPjFzjYWnBpVCOQCJs93t",
  ANNUAL = "price_1KWcfUFzjYWnBpVCPYxHZzMQ",
}

export const DEFAULT_CURRENCY = "usd";
export const LEGACY_DEFAULT_CURRENCY = "eur";

const getOldPriceIds = (): string[] => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return [OLD_PRICE_IDS_PRODUCTION.MONTHLY, OLD_PRICE_IDS_PRODUCTION.ANNUAL];
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    return [OLD_PRICE_IDS_STAGING.MONTHLY, OLD_PRICE_IDS_STAGING.ANNUAL];
  }
  return [OLD_PRICE_IDS_PRODUCTION.MONTHLY, OLD_PRICE_IDS_PRODUCTION.ANNUAL];
};

const getOldPriceByInterval = (interval: string) => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    if (interval === "monthly") {
      return OLD_PRICE_IDS_PRODUCTION.MONTHLY;
    }
    if (interval === "annual") {
      return OLD_PRICE_IDS_PRODUCTION.ANNUAL;
    }
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    if (interval === "monthly") {
      return OLD_PRICE_IDS_STAGING.MONTHLY;
    }
    if (interval === "annual") {
      return OLD_PRICE_IDS_STAGING.ANNUAL;
    }
  }
  return interval === "monthly"
    ? OLD_PRICE_IDS_PRODUCTION.MONTHLY
    : OLD_PRICE_IDS_PRODUCTION.ANNUAL;
};

export enum ACTIVE_STRIPE_PRODUCTS_STAGING {
  LIGHT = "prod_NbQw7PQ8OE7AVE",
  PREMIUM = "prod_NbR6aauE2sV8ap",
  UNLIMITED = "prod_NbR9tRtAhDV3s9",
}

export enum ACTIVE_STRIPE_PRODUCTS_PRODUCTION {
  LIGHT = "prod_NmcWwzpHGS07hN",
  PREMIUM = "prod_NmcWhCweMGbTLL",
  UNLIMITED = "prod_NmcWXEthV85Smf",
}

const getActiveStripeProductByPlanName = (planName: string) => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return ACTIVE_STRIPE_PRODUCTS_PRODUCTION[planName as BillingPlanName];
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    return ACTIVE_STRIPE_PRODUCTS_STAGING[planName as BillingPlanName];
  }
  return ACTIVE_STRIPE_PRODUCTS_PRODUCTION[planName as BillingPlanName];
};

const getAllActiveStripeProducts = (): string[] => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    return [
      ACTIVE_STRIPE_PRODUCTS_PRODUCTION.LIGHT,
      ACTIVE_STRIPE_PRODUCTS_PRODUCTION.PREMIUM,
      ACTIVE_STRIPE_PRODUCTS_PRODUCTION.UNLIMITED,
    ];
  }
  if (
    process.env.REACT_APP_APP_ENV === "staging" ||
    process.env.REACT_APP_APP_ENV === "development"
  ) {
    return [
      ACTIVE_STRIPE_PRODUCTS_STAGING.LIGHT,
      ACTIVE_STRIPE_PRODUCTS_STAGING.PREMIUM,
      ACTIVE_STRIPE_PRODUCTS_STAGING.UNLIMITED,
    ];
  }
  return [
    ACTIVE_STRIPE_PRODUCTS_PRODUCTION.LIGHT,
    ACTIVE_STRIPE_PRODUCTS_PRODUCTION.PREMIUM,
    ACTIVE_STRIPE_PRODUCTS_PRODUCTION.UNLIMITED,
  ];
};

const getInviteCode = (
  code: string,
  email: string,
  uid: string,
  waitlist_email: string = ""
) => {
  return ky
    .post(postInviteRoute(), {
      json: {
        code,
        email,
        uid,
        waitlist_email,
      },
      timeout: 30000,
      retry: {
        limit: 2,
        statusCodes: [408, 500],
      },
    })
    .json();
};

const generateInviteCode = () => {
  return ky
    .get(getInviteRoute(), {
      timeout: 30000,
      retry: {
        limit: 2,
        statusCodes: [408, 500],
      },
    })
    .json();
};

const runMigration = async (uid: string, email: string) => {
  try {
    const output = await ky
      .post(postMigratorRoute() as string, {
        json: {
          email,
          new_cognito_id: uid,
        },
        timeout: 30000,
        retry: {
          limit: 2,
          statusCodes: [408, 500],
        },
      })
      .json();

    return output;
  } catch (error) {
    console.log("Exception in migrator request");
    console.log(error);
    return { error };
  }
};

const fetchCountry = async () => {
  try {
    return await ky
      .get("https://api.ipgeolocation.io/ipgeo", {
        timeout: 30000,
        retry: {
          limit: 2,
          statusCodes: [408, 500],
        },
      })
      .json();
  } catch (error) {
    console.log();
    return null;
  }
};

const fetchToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (session) {
      const token = session.getIdToken().getJwtToken();
      if (token) return token;
      else return "";
    }
  } catch (e) {
    logger().log(`fetchToken exception - ${e}`);
    return "";
  }
};

export {
  fetchCountry,
  fetchToken,
  generateInviteCode,
  getInviteCode,
  postInviteRoute,
  encodeForTransfer,
  runMigration,
  customThrow,
  requestHeaders,
  getPriceId,
  makeSendable,
  createEventStreamRequest,
  createPreviewPromptRequest,
  getActiveStripeProductByPlanName,
  getOldPriceIds,
  getAllActiveStripeProducts,
  getOldPriceByInterval,
};
