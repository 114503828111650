const EXTENSION_DISABLED_INTEGRATIONS = {
  UPDATE: `
        mutation updateEDI($id: String, $extension_disabled_integrations: _text) {
          update_db_account_settings(_set: {extension_disabled_integrations: $extension_disabled_integrations}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
              extension_disabled_integrations
              id
            }
          }
        }
      `,
};

export default EXTENSION_DISABLED_INTEGRATIONS;
