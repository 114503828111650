import DB from "../constants";
import createHasuraRequest from "../utils";

export type LeftOverGenerationLimitFromOldPlan = {
  update: (uid: string, token: string, newContent: number) => any;
};

const left_over_generation_limit_from_old_plan: LeftOverGenerationLimitFromOldPlan = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(DB.FLAGS.LEFT_OVER_GENERATION_LIMIT_FROM_OLD_PLAN.UPDATE, token);
    const parameters = {
      uid,
      left_over_generation_limit_from_old_plan: newContent,
    };
    return await request(parameters);
  },
};

export { left_over_generation_limit_from_old_plan };
