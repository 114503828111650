/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable arrow-body-style */
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import App from "./app";
import AuthState, { SagasAuthStateRepo } from "./redux/actions/sagas/auth";
import { getRandomString } from "./redux/sagas/auth/utils/helpers";

export type AuthProps = {
  getCredentials: SagasAuthStateRepo["Credentials"]["getCurrent"];
};

const Auth: FunctionComponent<AuthProps> = ({ getCredentials }) => {
  /* get current user if there exists on mount */
  useEffect(() => {
    getCredentials();

    /* unique browser context id - for broadcast channel */
    if (!sessionStorage.getItem("flowrite-tab-id"))
      sessionStorage.setItem("flowrite-tab-id", getRandomString(30));
  }, []);

  return (
    <>
      <App />
    </>
  );
  // eslint-disable-next-line no-else-return
};

const mapDispatch = {
  getCredentials: AuthState.Credentials.getCurrent,
};

export default connect(null, mapDispatch)(Auth);
