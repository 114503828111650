const LEFT_OVER_GENERATION_LIMIT_FROM_OLD_PLAN = {
  UPDATE: `
          mutation updateLeftOverGenerationCountFlag($uid: String!, $left_over_generation_limit_from_old_plan: Int) {
              update_db_flags(where: {id: {_eq: $uid}}, _set: {left_over_generation_limit_from_old_plan: $left_over_generation_limit_from_old_plan}) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
};

export default LEFT_OVER_GENERATION_LIMIT_FROM_OLD_PLAN;
