import {fetchToken} from "../redux/reducers/utils";
import apiRequests from "../services/api/routes";

export const trackBackend = async (event_name: string, data: {}) => {
    let output = null;
    const token = await fetchToken();
    if (token) {
        output = await apiRequests.flow.logEventTracking(
            event_name,
            {
                environment: process.env.REACT_APP_APP_ENV,
                ...data
            },
            token
        );
    }
    return output;
};
export const backendIdentify = async (data: {}) => {
    let output = null;
    const token = await fetchToken();
    if (token) {
        output = await apiRequests.flow.logSegmentIdentify(
            {
                environment: process.env.REACT_APP_APP_ENV,
                ...data
            },
            token
        );
    }
    return output;
};
