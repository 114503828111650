import DB from "../constants";
import createHasuraRequest from "../utils";

export type Persona = {
  update: (uid: string, token: string, persona: string) => any;
};

const persona: Persona = {
  update: async (uid, token, persona) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.PERSONA.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      persona,
    };

    return await request(parameters);
  },
};

export { persona };
