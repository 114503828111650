import DB from "../constants";
import { OnboardingSteps } from "../types";
import createHasuraRequest from "../utils";

export type Settings = {
  create: (
    uid: string,
    token: string,
    settings: any,
    flowrite_id: string,
    clientId?: string
  ) => any;
  read: (uid: string, environment: string, token: string) => any;
  update: (uid: string, token: string, keyboardId: any, settings: any) => any;
};

const settings: Settings = {
  create: async (uid, token, settings, flowrite_id, clientId) => {
    const request = createHasuraRequest(DB.ACCOUNT_SETTINGS.CREATE, token);
    const parameters = {
      objects: {
        id: uid,
        email: settings.email,
        role: settings.identityProperties.role,
        company_name: settings.identityProperties.companyName,
        company_description: settings.identityProperties.companyDescription,
        processed_company_name:
          settings.identityProperties.processedCompanyName,
        processed_company_description:
          settings.identityProperties.processedCompanyDescription,
        processed_role: settings.identityProperties.processedRole,
        first_preposition: settings.identityProperties.firstPreposition,
        second_preposition: settings.identityProperties.secondPreposition,
        first_name: settings.firstName,
        creativity: settings.creativity,
        completion_length: settings.completion_length,
        lastname: settings.lastName,
        selected_greeting: settings.selectedGreeting,
        selected_signature: settings.selectedSignature,
        temperature: settings.temperature,
        log_out: settings.logOut,
        keyboard_shortcuts_id: "RK5WPjFE8AqDReTYsDt2Pp",
        flowritten_tag: settings.flowrittenTag,
        development_mode: settings.developmentMode,
        dark_mode: settings.darkMode,
        bootstrapped: true,
        extension_disabled_integrations: `{ ${settings.extension_disabled_integrations} }`,
      },
      user: {
        id: uid,
        account_settings_id: uid,
        onboarding_id: "start",
        template_id: uid,
        flags_id: uid,
        flowrite_id,
        signup_client_id: clientId || null,
        signup_code_used: sessionStorage.getItem("signup_code") || null,
        referred_by: localStorage.getItem("ucc") || null,
      },
      flags: {
        id: uid,
        check_list: "{setup}",
        vip_invite_count: 0,
        new_identity_added: true,
        onboarding_mode: true,
        onboarding_step: 1,
        steps_completed: 0,
        onboarding_step_text: OnboardingSteps.Install,
        feedback_count: 0,
        extension_install: false,
        migration_completed: true,
        first_usage_bumper: false,
        existing_user_tfgf_bumper: false,
        migui_bumper_flow: true,
        iterateux_bumper_flow: false,
        copy_used: false,
        generation_limit_exceeded: false,
        generation_count: 0,
        left_over_generation_limit_from_old_plan: 0,
      },
    };

    return await request(parameters);
  },

  read: async (uid, environment, token) => {
    const request = createHasuraRequest(DB.ACCOUNT_SETTINGS.READ, token);
    const parameters = {
      uid,
      environment,
    };
    return await request(parameters);
  },

  update: async (uid, token, keyboardId, settings) => {
    const request = createHasuraRequest(DB.ACCOUNT_SETTINGS.UPDATE, token);
    const parameters = {
      id: uid,
      email: settings.email,
      role: settings.identityProperties.role,
      company_name: settings.identityProperties.companyName,
      company_description: settings.identityProperties.companyDescription,
      processed_role: settings.identityProperties.processedRole,
      processed_company_name: settings.identityProperties.processedCompanyName,
      processed_company_description:
        settings.identityProperties.processedCompanyDescription,
      first_preposition: settings.identityProperties.firstPreposition,
      second_preposition: settings.identityProperties.secondPreposition,
      first_name: settings.firstName,
      creativity: settings.creativity,
      completion_length: settings.completion_length,
      lastname: settings.lastName,
      selected_greeting: settings.selectedGreeting,
      selected_signature: settings.selectedSignature,
      temperature: settings.temperature,
      log_out: settings.logOut,
      keyboard_shortcuts_id: keyboardId,
      flowritten_tag: settings.flowrittenTag,
      development_mode: settings.developmentMode,
      dark_mode: settings.darkMode,
      bootstrapped: settings.bootstrapped,
      usage_reason: `{ ${settings.usageReason} }`,
      persona: settings.defaultUseCasesName,
      extension_disabled_integrations: `{ ${settings.extension_disabled_integrations} }`,
    };

    return await request(parameters);
  },
};

export { settings };
