/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { eventChannel } from "redux-saga";

const subscribe = (source: any) => {
  const subs = (emitter: any) => {
    const events = [
      "message",
      "stop_word",
      "rate_limit_error",
      "signature",
      "unexpected_error",
      "error",
      "end",
    ];
    events.forEach((eventName: string) =>
      source.addEventListener(eventName, (payload: any) => {
        try {
          emitter(payload);
        } catch (e) {
          console.log("SUBSCRIBE FN INTERNAL EVENT LISTNER");
          console.log(e);
        }
      })
    );
    window.addEventListener("error", (e) => {
      console.log("window listener caught error");
      console.log(e);
    });
    try {
      source.stream();
    } catch (e) {
      console.log("SUBSCRIBE FN INTERNAL SOURCE.STREAM");
      console.log(e);
    }
    return () => {
      console.log("CLOSING SOURCE");
      console.log(source);
      source.close();
    };
  };
  return eventChannel(subs);
};

export default subscribe;
