import UseCase, { RemoteUseCaseRepo } from "./use-case";
import UseCases, { RemoteUseCasesRepo } from "./use-cases";

export type RemoteRepo = {
  UseCase: RemoteUseCaseRepo;
  UseCases: RemoteUseCasesRepo;
};

const Remote: RemoteRepo = {
  UseCase,
  UseCases,
};

export default Remote;
