/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {  call, takeEvery, takeLatest } from "redux-saga/effects";
import { ACTION } from "../../actions";
import listenStream from "./listen-stream";
import watchAndValidateRequest from "./watch-and-validate-request";
import watchConcats from "./concats/watch-concats";
import onOnboarding from "./on-onboarding";

function* eventStreamSaga() {
  try {
    // Display test generation during onboarding
    yield takeEvery(ACTION.SAGAS.EVENT_STREAM.ONBOARDING.CREATE, onOnboarding);

    // 1. First step
    // Create and send the request
    // Either new or regeneration

    // listen for new requests
    yield takeLatest(
      ACTION.SAGAS.EVENT_STREAM.REQUEST.SEND,
      watchAndValidateRequest
    );
    // listen for regenerations
    yield takeLatest(
      ACTION.SAGAS.EVENT_STREAM.REQUEST.RESEND,
      watchAndValidateRequest
    );

    // 2. Second step
    // start the actual stream with the prepared request
    yield takeLatest(ACTION.SAGAS.EVENT_STREAM.START, listenStream);

    // 3. Receive the stream events
    // buffer events arriving from the backend
    yield watchConcats();
  } catch (error) {
    yield call(console.log, error);
  }
}

export default eventStreamSaga;
