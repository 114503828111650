import { Auth } from "aws-amplify";
import { apply, put } from "redux-saga/effects";
import AuthState from "../../../actions/local/auth";
import { ExtendedCognitoUser } from "../utils/types";

function* passwordlessSignIn(action: any) {
  try {
    const {
      payload: { email },
    } = action;

    /* control is passed to the auth listener after this event is triggered */
    const user: ExtendedCognitoUser = yield apply(Auth, Auth.signIn, [email]);

    yield put(AuthState.anyFieldUpdate("cognitoUser", user));
    yield put(AuthState.anyFieldUpdate("code_sent", true));
  } catch (e) {
    if ("name" in (e as any) && (e as any).name === "UserNotFoundException") {
      /* user does not exist already, first sign-up */
      yield put(AuthState.Credentials.setSignUpUser(true));
    }
  }
}

export default passwordlessSignIn;
