import { Auth } from "aws-amplify";
import { apply, delay, put, select } from "redux-saga/effects";
import AuthState from "../../../actions/local/auth";

function* answerAuthChallenge(action: any): any {
  const {
    payload: { code },
  } = action;

  const cognitoUser: any = yield select((state) => state.auth.cognitoUser);
  const codeAttempt: any = yield select((state) => state.auth.code_attempt);
  // console.log("found a user", cognitoUser);

  try {
    if (cognitoUser) {
      const user = yield apply(Auth, Auth.sendCustomChallengeAnswer, [
        cognitoUser,
        code,
      ]);
    }
  } catch (e) {
    /*
    Exceptions and reset strategy handled by Hub: on-signin-failure
    Raised on 
    - 3rd wrong attempt
    - use of an expired OTP
    */
  }
  try {
    /* successfully signed in - hub signs in */
    const session = yield apply(Auth, Auth.currentSession, []);
    if (session) yield put(AuthState.resetPasswordlessSignIn());
  } catch (e) {
    /* 
    Exception raised by currentSession on wrong OTP attempts 1 & 2
    The user is still in the CUSTOM_CHALLENGE (OTP) flow
    Hence, attempts 1 & 2 do not lead to sign-in-failures (for Hub)
    */
    console.log("Exception", e);
    if (codeAttempt < 2) {
      yield put(AuthState.anyFieldUpdate("code_attempt", codeAttempt + 1));
      yield put(AuthState.anyFieldUpdate("incorrect_code", true));
      yield put(AuthState.anyFieldUpdate("code", ""));

      /* reset warning */
      yield delay(2000);
      yield put(AuthState.anyFieldUpdate("incorrect_code", false));
    }
  }
}

export default answerAuthChallenge;
