/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, takeEvery } from "redux-saga/effects";
import { closeStream } from "../../../utils/helpers";
import { ACTION } from "../../../../actions";

function* onInterrupt(source: any) {
  try {
    yield closeStream(source);
    yield takeEvery(ACTION.SAGAS.EVENT_STREAM.INTERRUPT, closeStream, source);
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onInterrupt;
