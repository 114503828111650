import { prependRoute } from "./utils";

const billingRoutes = {
  customer: {
    create_customer: "create-customer",
    read_customer: "read-customer",
    create_customer_portal_session: "create-customer-portal-session",
    create_checkout_session: "create-checkout-session",
    read_plans: "read-plans",
    read_plan: "read-plan",
    subscription_success: "subscription-success",
    check_subscription: "check-subscription",
    create_scheduled_subscription: "create-scheduled-subscription",
  },
};

export const billing = prependRoute("stripe/", billingRoutes);
