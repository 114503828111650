const CHECK_LIST = {
  UPDATE: `
  mutation MyMutation($uid: String!, $check_list: _text) {
      update_db_flags(where: {id: {_eq: $uid}}, _set: {check_list: $check_list}) {
        affected_rows
        returning {
          id
          check_list
        }
      }
    }
  `,
};

export default CHECK_LIST;
