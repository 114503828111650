const TEMPLATE = {
  CREATE: `
    mutation addMutation($template: db_templates_insert_input!, $connection: db_users_templates_insert_input!){
      insert_db_templates(objects: [$template]) {
        returning {
          id
          name
        }
      }
      insert_db_users_templates (objects: [$connection]) {
        returning {
          user_id
          template_id
        }
      }
    }
    `,
  UPDATE: `
    mutation templateUpdate(
      $id: String, $name: String,
      $emoji: String, $intention: String,
      $recipient_type: String, $instructions:String, $modified_date: date,
      $template_group: String,
      $template_type: String,
      $template_collection_id: String
    ) {
      update_db_templates(where: {id: {_eq: $id}}, _set: {
        emoji:  $emoji, 
        intention: $intention,
        modified_date: $modified_date, 
        name: $name, 
        recipient_type: $recipient_type,
        instructions: $instructions,
        template_group: $template_group,
        template_type: $template_type,
        template_collection_id: $template_collection_id}) {
        returning {
          id
        }
        affected_rows
      }
    }
      `,
  DELETE: `
    mutation configEditDialogDelete($template_id: String!) {
        delete_db_users_templates(where: {template_id: {_eq: $template_id}}) {
          returning {
            user_id
            template_id
          }
        }
        delete_db_templates(where: {id: {_eq: $template_id}}) {
          returning {
            id
            name
          }
        }
      }
      `,
  INDEX: {
    UPDATE: `
      mutation updateOptionsSwap ($id: String!, $index: Int){
        update_db_templates(where:{id:{_eq: $id}}, _set: {index: $index}) {
          returning {
            id
            index
            intention
          }
        }
      }
        `,
  },
};

export { TEMPLATE };
