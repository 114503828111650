import DB from "../constants";
import createHasuraRequest from "../utils";

export type ObSurveyFields = {
  survey_function: string;
  survey_role: string;
  survey_employees: string;
  survey_usecase: string[];
};

export type ObSurvey = {
  read: (id: string, token: string) => any;
  insert: (id: string, data: ObSurveyFields, token: string) => any;
  update: (id: string, data: ObSurveyFields, token: string) => any;
};

const ob_survey: ObSurvey = {
  read: async (id, token) => {
    const request = createHasuraRequest(DB.OB_SURVEY.READ, token);
    const parameters = {
      id,
    };
    return await request(parameters);
  },
  insert: async (id, data, token) => {
    const request = createHasuraRequest(DB.OB_SURVEY.INSERT, token);
    const parameters = {
      objects: {
        id,
        ...data,
        survey_usecase: JSON.stringify(data.survey_usecase),
      },
    };
    return await request(parameters);
  },
  update: async (id, data, token) => {
    const request = createHasuraRequest(DB.OB_SURVEY.UPDATE, token);
    const parameters = {
      id,
      ...data,
      survey_usecase: JSON.stringify(data.survey_usecase),
    };
    return await request(parameters);
  },
};

export { ob_survey };
