const TEST_MODE = {
  READ: `
    query ReadTestMode($id: String) {
        db_account_settings(where: {id: {_eq: $id}}) {
            test_mode
        }
    }
    `,
  UPDATE: `
    mutation UpdateTestMode($id: String, $test_mode: Boolean) {
        update_db_account_settings(_set: {test_mode: $test_mode}, where: {id: {_eq: $id}}) {
        affected_rows
            returning {
                test_mode
                id
            }
        }
    }
    `,
};

export default TEST_MODE;
