const APP_STATE = {
  CONFIG_ADD_DIALOG: {
    OPEN: "ACTION.SAGAS.APP_STATE.CONFIG_ADD_DIALOG.OPEN",
  },
  ADD_CONFIG: {
    OPEN: "ACTION.SAGAS.APP_STATE.ADD_CONFIG.OPEN",
  },
  SAFETY: {
    ERROR: "ACTION.SAGAS.APP_STATE.SAFETY.ERROR",
  },
  RATE_LIMIT: {
    ERROR: "ACTION.SAGAS.APP_STATE.RATE_LIMIT_ERROR",
  },
  EXTENSION_LOGIN: "ACTION.SAGAS.APP_STATE.EXTENSION_LOGIN",
  EXTENSION_LOGOUT: "ACTION.SAGAS.APP_STATE.EXTENSION_LOGOUT",
  EXTENSION_UPDATE_SETTINGS_MESSAGE:
    "ACTION.SAGAS.APP_STATE.EXTENSION_UPDATE_SETTINGS_MESSAGE",

  INTEGRATION_OPEN: "ACTION.SAGAS.APP_STATE.INTEGRATION_OPEN",
  ON_LOGIN: "ACTION.SAGAS.APP_STATE.ON_LOGIN",
  RETURN_TO_PROMPT: "ACTION.SAGAS.APP_STATE.RETURN_TO_PROMPT",
  FLAGS: {
    UPDATE_CHECKLIST: "ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_CHECKLIST",
    UPDATE_ONBOARDING: "ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_ONBOARDING",
    UPDATE_EXTENSION_INSTALL:
      "ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_EXTENSION_INSTALL",
    UPDATE_FIRST_USAGE_BUMPER:
      "ACTION.SAGAS.APP_STATE.FLAGS.UPDATE_FIRST_USAGE_BUMPER",
  },
  ONBOARDING_REASON: "ACTION.SAGAS.APP_STATE.ONBOARDING_REASON",
  UPDATE: "ACTION.SAGAS.APP_STATE.UPDATE",
  CHECK_SETTINGS_SAFETY: "ACTION.SAGAS.APP_STATE.CHECK_SETTINGS_SAFETY",
  CHECK_ADMIN_RIGHTS: "ACTION.SAGAS.APP_STATE.CHECK_ADMIN_RIGHTS",
  VIP_INVITE: "ACTION.SAGAS.APP_STATE.VIP_INVITE",
  CREATE: "ACTION.SAGAS.APP_STATE.CREATE",
  READ: "ACTION.SAGAS.APP_STATE.READ",
  ACCOUNT_SETTINGS: {
    UPDATE: "ACTION.SAGAS.APP_STATE.ACCOUNT_SETTINGS.UPDATE",
  },
  COMPLETION_LENGTH: {
    UPDATE: "ACTION.SAGAS.APP_STATE.COMPLETION_LENGTH.UPDATE",
  },
  IDENTITY_PROPERTIES: {
    UPDATE: "ACTION.SAGAS.APP_STATE.IDENTITY_PROPERTIES.UPDATE",
  },
  ONBOARDING: {
    UPDATE_REASON: "ACTION.SAGAS.APP_STATE.ONBOARDING.UPDATE_REASON",
    UPDATE_SURVEY: "ACTION.SAGAS.APP_STATE.ONBOARDING.UPDATE_SURVEY",
  },
  ONBOARDING_FINISHED: "ACTION.SAGAS.APP_STATE.ONBOARDING_FINISHED",
  // USER: {
  //   CREATE: "ACTION.SAGAS.APP_STATE.USER.CREATE",
  // },
  FEEDBACK: {
    CREATE: "ACTION.SAGAS.APP_STATE.FEEDBACK.CREATE",
  },
  RECIPIENT: {
    GET: "ACTION.SAGAS.APP_STATE.RECIPIENT.GET",
  },
  IDENTIFY_CALL: "ACTION.SAGAS.APP_STATE.IDENTIFY_CALL",
  MIGRATION: {
    TRIGGER: "ACTION.SAGAS.APP_STATE.MIGRATION.TRIGGER",
    LOGIN_TRIGGER: "ACTION.SAGAS.APP_STATE.MIGRATION.LOGIN_TRIGGER",
  },
};
export default APP_STATE;
