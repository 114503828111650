import { delay, put } from "redux-saga/effects";
import AuthState from "../../../../../../actions/local/auth";
import { Exception } from "./types";

function* onNetworkException(exception: Exception) {
  yield put(AuthState.anyFieldUpdate("network_error", true));
  yield delay(2000);
  yield put(AuthState.Credentials.setEmpty());
}

export default onNetworkException;
