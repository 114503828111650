import { GenerationHistoryItem } from "../../initial-state/generation-history";
import LOCAL from "./constants";

export type LocalGenerationHistoryRepo = {
  update(data: GenerationHistoryItem[]): any;
  reset(): any;
};

const GenerationHistoryActions: LocalGenerationHistoryRepo = {
  update: (data) => ({
    type: LOCAL.GENERATION_HISTORY.UPDATE,
    payload: { data },
  }),
  reset: () => ({
    type: LOCAL.GENERATION_HISTORY.RESET,
    payload: {},
  }),
};

export default GenerationHistoryActions;
