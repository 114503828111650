// eslint-disable-next-line import/no-cycle
import LOCAL from "./constants";

export type LocalUseCasesRepo = {
  create(): any;
  sink(field: string, newContent: any[]): any;
  update(useCasesArray: any[]): any;
  delete(useCaseId: string): any;
  Gallery: {
    create(): any;
    update(useCasesArray: any[]): any;
  };
};

const UseCases: LocalUseCasesRepo = {
  create: () => ({
    type: LOCAL.USE_CASES.CREATE,
    payload: {},
  }),
  Gallery: {
    create: () => ({
      type: LOCAL.USE_CASES.GALLERY_TEMPLATES.CREATE,
      payload: {},
    }),
    update: (useCasesArray) => ({
      type: LOCAL.USE_CASES.GALLERY_TEMPLATES.UPDATE,
      payload: { useCasesArray },
    }),
  },
  sink: (field, newContent) => ({
    type: LOCAL.USE_CASES.SINK,
    payload: { field, newContent },
  }),
  update: (useCasesArray) => ({
    type: LOCAL.USE_CASES.UPDATE,
    payload: { useCasesArray },
  }),
  delete: (useCaseId) => ({
    type: LOCAL.USE_CASES.DELETE,
    payload: { useCaseId },
  }),
};

export default UseCases;
