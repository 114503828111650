import DB from "../constants";
import createHasuraRequest from "../utils";

export type TestMode = {
  read: (uid: string, token: string) => any;
  update: (uid: string, token: string, value: string) => any;
};

const test_mode: TestMode = {
  read: async (uid, token) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.TEST_MODE.READ,
      token
    );
    const parameters = {
      id: uid,
    };

    return await request(parameters);
  },
  update: async (uid, token, value) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.TEST_MODE.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      test_mode: value,
    };

    return await request(parameters);
  },
};

export { test_mode };
