import { Auth } from "aws-amplify";
import { apply, call, put } from "redux-saga/effects";
import AuthState from "../../../actions/local/auth";
import { setCurrentUser } from "../utils/helpers";
import { ExtendedCognitoUser } from "../utils/types";

/*
fetch the current user 
*/
function* getCurrentUser() {
  try {
    yield call(AuthState.Credentials.loading);
    const user: ExtendedCognitoUser = yield apply(
      Auth,
      Auth.currentAuthenticatedUser,
      []
    );
    yield setCurrentUser(user);
  } catch (e) {
    /* if there is not authenticated user, helps redirection to login */
    if (
      typeof e === "string" &&
      e.toLowerCase().includes("user is not authenticated")
    ) {
      yield put(AuthState.Credentials.setEmpty());
    }
  }
}

export default getCurrentUser;
