const APP_STATE = {
  // toggles
  DARK_MODE: {
    ENABLE: "ACTION.LOCAL.APP_STATE.DARK_MODE.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.DARK_MODE.DISABLE",
  },
  BOOLEAN_FIELD: {
    ENABLE: "ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.BOOLEAN_FIELD.DISABLE",
  },
  PROMPT_CHARACTER_LIMIT_ERROR: {
    ENABLE: "ACTION.LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.PROMPT_CHARACTER_LIMIT_ERROR.DISABLE",
  },
  WARNED_LIST: {
    UPDATE: "ACTION.LOCAL.APP_STATE.WARNED_LIST.UPDATE",
    CLEAR: "ACTION.LOCAL.APP_STATE.WARNED_LIST.CLEAR",
  },
  KEYBOARD_SHORTCUT: {
    ENABLE: "ACTION.LOCAL.APP_STATE.KEYBOARD_SHORTCUT.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.KEYBOARD_SHORTCUT.DISABLE",
  },
  FLOWRITING_MODE: {
    ENABLE: "ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.FLOWRITING_MODE.DISABLE",
  },
  PREVIOUS_SECOND_BODY: {
    UPDATE: "ACTION.LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.UPDATE",
    CLEAR: "ACTION.LOCAL.APP_STATE.PREVIOUS_SECOND_BODY.CLEAR",
  },
  PROMPT_STATE: {
    FORWARD: "ACTION.LOCAL.APP_STATE.PROMPT_STATE.FORWARD",
    BACKWARD: "ACTION.LOCAL.APP_STATE.PROMPT_STATE.BACKWARD",
  },
  REPLY_MODE: {
    ENABLE: "ACTION.LOCAL.APP_STATE.REPLY_MODE.ENABLE",
    DISABLE: "ACTION.LOCAL.APP_STATE.REPLY_MODE.DISABLE",
  },
  CONFIG_EDIT_DIALOG: {
    OPEN: "ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.OPEN",
    CLOSE: "ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.CLOSE",
  },
  CONFIG_ADD_DIALOG: {
    OPEN: "ACTION.LOCAL.APP_STATE.CONFIG_ADD_DIALOG.OPEN",
    CLOSE: "ACTION.LOCAL.APP_STATE.CONFIG_ADD_DIALOG.CLOSE",
  },
  CONFIG_EDIT: {
    UPDATE: "ACTION.LOCAL.APP_STATE.CONFIG_EDIT.UPDATE",
  },

  // toggles any Visiblity from the Visilibities object
  VISIBILITY: {
    TOGGLE: "ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE",
  },

  ACTIVE_USE_CASE: {
    UPDATE: "ACTION.LOCAL.APP_STATE.ACTIVE_USE_CASE.UPDATE",
  },

  // spawns -> sagas potential?
  EVENT_STREAM: {
    REQUEST: {
      CREATE: "ACTION.LOCAL.APP_STATE.EVENT_STREAM.REQUEST.SEND",
      SEND: "ACTION.LOCAL.APP_STATE.EVENT_STREAM.REQUEST.SEND",
    },
    START: "ACTION.LOCAL.APP_STATE.EVENT_STREAM.START",
    SSE_REQUEST: "ACTION.LOCAL.APP_STATE.EVENT_STREAM.SSE_REQUEST",
    ERROR: "ACTION.LOCAL.APP_STATE.EVENT_STREAM.ERROR",
  },

  // objects
  COMPLETION: {
    CLEAR: "ACTION.LOCAL.APP_STATE.COMPLETION.CLEAR",
    UPDATE: "ACTION.LOCAL.APP_STATE.COMPLETION.UPDATE",
    CONCAT: "ACTION.LOCAL.APP_STATE.COMPLETION.CONCAT",
  },

  // general field mutations
  ANY_FIELD: {
    UPDATE: "ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE",
  },

  ACCOUNT_FIELD: {
    UPDATE: "ACTION.LOCAL.APP_STATE.ACCOUNT_FIELD.UPDATE",
  },

  BILLING_FIELD: {
    UPDATE: "ACTION.LOCAL.APP_STATE.BILLING_FIELD.UPDATE",
  },

  BILLING: {
    UPDATE: "ACTION.LOCAL.APP_STATE.BILLING.UPDATE",
    PLANS: {
      UPDATE: "ACTION.LOCAL.APP_STATE.BILLING.PLANS.UPDATE",
    },
    PLAN: {
      UPDATE: "ACTION.LOCAL.APP_STATE.BILLING.PLAN.UPDATE",
    },
    GENERATION_COUNT: {
      UPDATE: "ACTION.LOCAL.APP_STATE.BILLING.GENERATION_COUNT.UPDATE",
    },
    CHECK_SUBSCRIPTION: "ACTION.LOCAL.APP_STATE.BILLING.CHECK_SUBSCRIPTION",
    CREATE_SCHEDULED_SUBSCRIPTION: "ACTION.LOCAL.APP_STATE.BILLING.CREATE_SCHEDULED_SUBSCRIPTION",
  },

  IDENTITY_PROPERTIES: {
    UPDATE: "ACTION.LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE",
    UPDATE_FIELD: "ACTION.LOCAL.APP_STATE.IDENTITY_PROPERTIES.UPDATE_FIELD",
  },

  FLAGS: {
    UPDATE: "ACTION.LOCAL.APP_STATE.FLAGS.UPDATE",
    UPDATE_MULTIPLE: "ACTION.LOCAL.APP_STATE.FLAGS.UPDATE_MULTIPLE",
  },

  OB_SURVEY: {
    UPDATE: "ACTION.LOCAL.APP_STATE.OB_SURVEY.UPDATE",
  },

  ACCOUNT: {
    UPDATE: "ACTION.LOCAL.APP_STATE.ACCOUNT.UPDATE",
    READ: "ACTION.LOCAL.APP_STATE.ACCOUNT.READ",
  },

  EXTENSION_INTEGRATION: {
    ENABLE: "ACTION.EXTENSION_INTEGRATION.ENABLE",
    DISABLE: "ACTION.EXTENSION_INTEGRATION.DISABLE",
  },

  // nested mutations - "lenses"
  BODIES: {
    CLEAR: "ACTION.LOCAL.APP_STATE.BODIES.CLEAR",
  },
  SECOND_BODY: {
    CLEAR: "ACTION.LOCAL.APP_STATE.SECOND_BODY.CLEAR",
  },
  CONTENT: {
    UPDATE: "ACTION.LOCAL.APP_STATE.CONTENT.UPDATE",
  },
};

export default APP_STATE;
