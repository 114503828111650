import DB from "../constants";
import createHasuraRequest from "../utils";

export type CompletionLength = {
  update: (uid: string, token: string, completion_length: string) => any;
};

const completion_length: CompletionLength = {
  update: async (uid, token, completion_length) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.COMPLETION_LENGTH.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      completion_length,
    };

    return await request(parameters);
  },
};

export { completion_length };
