import { Auth } from "aws-amplify";
import { apply, put } from "redux-saga/effects";
import AuthState from "../../../actions/sagas/auth";
import { getRandomString } from "../utils/helpers";
import { ExtendedCognitoUser } from "../utils/types";

function* passwordlessSignUp(action: any) {
  try {
    const {
      payload: { email, fullName },
    } = action;

    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        name: fullName,
      },
    };

    const user: ExtendedCognitoUser = yield apply(Auth, Auth.signUp, [params]);
    yield put(AuthState.Credentials.passwordlessSignIn(email));
  } catch (e) {}
}

export default passwordlessSignUp;
