const HISTORY = {
  GENERATIONS: {
    GET: `
    query GetGenerationHistory($uid: String, $start_date: timestamp, $end_date: timestamp, $limit: Int = 20, $offset: Int = 0) {
        generation_hub(where: {uid: {_eq: $uid}, created_at: {_gte: $start_date, _lte: $end_date}}, order_by: {created_at: desc}, limit: $limit, offset: $offset) {
          id
          gen_id
          transformed_output
          raw_output
          created_at
          recipient
          template_name
        }
      }
    `,
  },
  ITERATIONS: {
    GET: `
    query GetIterationHistory($uid: String, $start_date: timestamp, $end_date: timestamp) {
        generation_iterations(where: {uid: {_eq: $uid}, created_at: {_gte: $start_date, _lte: $end_date}}, order_by: {created_at: desc}) {
          iter_id
          parent_gen_id
          transformed_output
          raw_output
          recipient
          created_at
        }
      }
    `,
  },
};

export default HISTORY;
