/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from "redux-saga/effects";
import { ACTION } from "../../../../actions";
import { flowriteModeDisable, streamError } from "../../../utils/helpers";

function* onStopWord(source: any, stopWordEvent: any, bodyId: number) {
  try {
    yield put({
      type: ACTION.LOCAL.APP_STATE.ANY_FIELD.UPDATE,
      payload: { field: `active_stream_${bodyId}`, newContent: false },
    });

    const { active_stream_1, active_stream_2, active_stream_3 } = yield select(
      (state) => state.appState
    );
    if (!active_stream_1 && !active_stream_2 && !active_stream_3) {
      yield streamError(); // customize this for stopWordEvent specifically
      yield flowriteModeDisable();
    }
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onStopWord;
