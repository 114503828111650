const FEEDBACK_COUNT = {
  UPDATE: `
  mutation MyMutation($uid: String!, $count: Int) {
      update_db_flags(where: {id: {_eq: $uid}}, _set: {feedback_count: $count}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
};

export default FEEDBACK_COUNT;
