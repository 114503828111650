import { Auth } from "aws-amplify";
import { apply, call } from "redux-saga/effects";
import { BroadcastMessage } from "../../types";

/*
Triggered when Auth.signOut logs out a user in another tab
reload initiates a change in state to logged out for all tabs hosting Flowrite
*/
function* onLogout(event: BroadcastMessage): any {
  try {
    yield apply(Auth, Auth.signOut as any, []);
  } catch (e) {
    yield call(console.log, e);
  }
}

export default onLogout;
