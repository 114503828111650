import { getRandomString } from "../auth/utils/helpers";

export const fetchId = () => {
  const current = sessionStorage.getItem("flowrite-tab-id");
  if (!current) {
    const id = getRandomString(30);
    sessionStorage.setItem("flowrite-tab-id", id);

    return id;
  }

  return current;
};
