import moment from "moment";
import { put, call, select, all, takeEvery } from "redux-saga/effects";
import db from "../../services/hasura";
import { ACTION } from "../actions";
import { getToken, sortDescByCreatedAtDate } from "./utils/helpers";

function* getGenerationHistory(action: any): any {
  try {
    const { offset } = action.payload;
    const { uid } = yield select((state) => state.auth);
    const { data } = yield select((state) => state.generationHistory);
    const token = yield getToken();

    const start_date = moment().subtract(30, "days").toDate();
    const end_date = moment().toDate();

    const history = yield call(
      db.generation.history.getGenerations,
      uid,
      token,
      start_date,
      end_date,
      typeof offset === "number" ? offset : data.length === 0 ? 20 : data.length
    );
    const generationHub = history?.data?.generation_hub;
    if (generationHub) {
      let historyData = generationHub;

      const regen_start_date =
        generationHub[generationHub.length - 1].created_at;
      const regen_end_date =
        data.length > 0 ? generationHub[0].created_at : end_date;

      const regenHistory = yield call(
        db.generation.history.getIterations,
        uid,
        token,
        regen_start_date,
        regen_end_date
      );

      const iterations = regenHistory?.data?.generation_iterations;
      if (iterations) {
        const allGenerations = generationHub.concat(
          iterations.map((d: any) => ({
            ...d,
            id: d.iter_id,
          }))
        );
        historyData = yield call(sortDescByCreatedAtDate, allGenerations);
      }

      yield put({
        type: ACTION.LOCAL.GENERATION_HISTORY.UPDATE,
        payload: { data: historyData },
      });
    }
  } catch (error) {
    // here instead dispatch error message, could not read from firebase
    yield call(console.log, error);
  }
}

function* useCasesSaga() {
  yield all([
    takeEvery(ACTION.SAGAS.GENERATION_HISTORY.GET, getGenerationHistory),
  ]);
}

export default useCasesSaga;
