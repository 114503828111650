/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStorage } from "typesafe-storage";

export type oneGeneration = {
  //  id: number;
  gen_id: string;
  seed_id: string;
  templateName: string;
  text: string;
  recipient: string;
};

type LocalStorageState = {
  history: oneGeneration[];
};

const compliationStorage: LocalStorageState = {
  history: [
    {
      //  id: 0,
      gen_id: "",
      seed_id: "",
      templateName: "",
      text: "",
      recipient: "",
    },
  ],
};

const storage = createStorage<{
  generations: oneGeneration[];
}>(localStorage);

export { compliationStorage, storage };
