const OB_SURVEY = {
  READ: `
    query ReadObSurvey ($id: String){
      db_ob_survey(where: {id: {_eq: $id}}) {
        survey_function
        survey_role
        survey_employees
        survey_usecase
      }
    }
  `,
  INSERT: `
    mutation InsertObSurvey ($objects: [db_ob_survey_insert_input!]!){
      insert_db_ob_survey(objects: $objects) {
        affected_rows
      }
    }
  `,
  UPDATE: `
    mutation UpdateObSurvey ($id: String!, $survey_function: String, $survey_role: String, $survey_employees: String, $survey_usecase: json){
      update_db_ob_survey(
        where: {id: {_eq: $id}}, 
        _set: {
            survey_function: $survey_function,
            survey_role: $survey_role,
            survey_employees: $survey_employees,
            survey_usecase: $survey_usecase
        }
      ) {
        affected_rows
      }
    }
  `,
};

export { OB_SURVEY };
