import React, { FunctionComponent, useState } from "react";

interface ErrorPopupProps {
  messages: Array<string>;
  closePopupOnClick?: boolean;
}

const ErrorPopup: FunctionComponent<ErrorPopupProps> = (
  props: ErrorPopupProps
) => {
  const { messages, closePopupOnClick } = props;

  const [show, setShow] = useState(true);

  return show ? (
    <div
      className={`fixed top-0 left-0 flex items-center justify-center w-full h-full px-8 bg-gray-200 bg-opacity-20 animate-fadeInSlow z-60 dark:bg-gray-900 blur dark:bg-opacity-80 ${
        closePopupOnClick ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (closePopupOnClick) {
          setShow(false);
        }
      }}
    >
      <div className="text-gray-500 relative flex flex-col items-center p-8 bg-white shadow-lg cursor-auto sm:w-192 dark:border-t rounded-xl max-h-4/5 dark:bg-gray-800 dark:bg-opacity-80 border-gray-750">
        {messages.length > 0 &&
          messages.map((message, index) => <p key={index}>{message}</p>)}
      </div>
    </div>
  ) : null;
};

export default ErrorPopup;
