import DB from "../constants";
import createHasuraRequest from "../utils";

export type DosTemplates = {
  read: (token: string) => any;
};

const dos_templates: DosTemplates = {
  read: async (token) => {
    const request = createHasuraRequest(DB.TEMPLATES.DOS_TEMPLATES.READ, token);
    return await request({});
  },
};

export { dos_templates };
