import SAGAS from "./constants";

export type SagasBillingRepo = {
  customer: {
    read: () => any;
    checkoutSuccess: (session_id: string) => any;
    checkSubscription: () => any;
    createScheduledSubscription: (price_id: string) => any;
  };
  portalSession: {
    create: () => any;
  };
  checkoutSession: {
    create: (price_id: string, return_url: string) => any;
  };
  plans: {
    read: () => any;
  };
};

const Billing: SagasBillingRepo = {
  customer: {
    read: () => ({
      type: SAGAS.BILLING.CUSTOMER.READ,
      payload: {},
    }),
    checkoutSuccess: (session_id) => ({
      type: SAGAS.BILLING.CUSTOMER.CHECKOUT_SUCCESS,
      payload: { session_id },
    }),
    checkSubscription: () => ({
      type: SAGAS.BILLING.CUSTOMER.CHECK_SUBSCRIPTION,
      payload: {},
    }),
    createScheduledSubscription: (price_id) => ({
      type: SAGAS.BILLING.CUSTOMER.CREATE_SCHEDULED_SUBSCRIPTION,
      payload: { price_id },
    }),
  },
  portalSession: {
    create: () => ({
      type: SAGAS.BILLING.PORTAL_SESSION.CREATE,
      payload: {},
    }),
  },
  checkoutSession: {
    create: (price_id, return_url) => ({
      type: SAGAS.BILLING.CHECKOUT_SESSION.CREATE,
      payload: { price_id, return_url },
    }),
  },
  plans: {
    read: () => ({
      type: SAGAS.BILLING.PLANS.READ,
      payload: {},
    }),
  },
};

export default Billing;
