import { api } from "./config";

function makeRequest<R>(endpoint: string, payload: R) {
  return {
    authenticated: (token: string) =>
      api
        .post(endpoint, {
          json: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .json(),
    unauthenticated: () => api.post(endpoint, { json: payload }).json(),
  };
}

function getRequest<R>(endpoint: string) {
    return {
        authenticated: (token: string) =>
            api
                .get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .json(),
        unauthenticated: () => api.get(endpoint).json(),
    };
}
export { makeRequest, getRequest };
