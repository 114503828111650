/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */

import { put } from "redux-saga/effects";
import { ACTION } from "../../actions";
import { oneGeneration } from "../../initial-state/local-storage";

const saveCompilationToStorage = (generations: oneGeneration[]) =>
  put({
    type: ACTION.LOCAL.STORAGE.UPDATE,
    payload: { generations },
  });

export { saveCompilationToStorage };
