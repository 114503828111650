import { billing } from "./billing";
import { flow } from "./flow";
import { auth } from "./auth";

const apiRoutes = {
  billing,
  flow,
  auth,
};

export default apiRoutes;
