/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from "redux-saga/effects";
import { sendSafetyError } from "../../../utils/helpers";

function* onSafetyError(source: any, data: any) {
  try {
    yield sendSafetyError(data.body_label, data.safety_category); // these need to go to analytics or datadog or a logging service
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onSafetyError;
