const KEYBOARD_SHORTCUTS = {
  ID: {
    READ: `
        query MyQuery ($shift_enter: Boolean,$shift_right: Boolean,$shift_left: Boolean ) {
          db_keyboard_shortcuts(where: {shift_enter: {_eq: $shift_enter}, shift_left: {_eq: $shift_left}, shift_right: {_eq: $shift_right}}) {
            shift_enter
            shift_left
            shift_right
            id
          }
        }
        `,
  },
};

export default KEYBOARD_SHORTCUTS;
