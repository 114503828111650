/* eslint-disable import/prefer-default-export */
const BILLING = {
  READ: `
    query BillingRead ($uid: String!){
        db_billing(where: {uid: {_eq:$uid}}) {
            id
            plan_id
            price_id
            stripe_id
            subscription_id
            uid
            PLAN_INTERVAL {
              id
              name
              interval
              generation_limit
              stripe_product_id
              rank
            }
        }
    }
    `,
};

export { BILLING };
