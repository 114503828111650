const USER_TEMPLATES = {
  READ: `
  query getTemplatesQuery ($uid: String!) {
    db_users_templates(where: {user_id: {_eq: $uid}}) {
      template {
        id
        index
        emoji
        intention
        name
        description
        recipient_type
        instructions
        created_date
        modified_date
        template_type
        template_group
        template_collection_id
      }
    }
  }
  `,
};

export default USER_TEMPLATES;
