import DB from "../constants";
import createHasuraRequest from "../utils";

export type NewIdentityAdded = {
  update: (uid: string, token: string, new_identity_added: boolean) => any;
};

const new_identity_added: NewIdentityAdded = {
  update: async (uid, token, new_identity_added) => {
    const request = createHasuraRequest(
      DB.FLAGS.NEW_IDENTITY_ADDED.UPDATE,
      token
    );
    const parameters = {
      uid,
      new_identity_added,
    };
    return await request(parameters);
  },
};

export { new_identity_added };
