const BILLING = {
  CUSTOMER: {
    CREATE: "ACTION.SAGAS.BILLING.CUSTOMER.CREATE",
    READ: "ACTION.SAGAS.BILLING.CUSTOMER.READ",
    // UPDATE: "ACTION.SAGAS.BILLING.CUSTOMER.UPDATE",
    CHECKOUT_SUCCESS: "ACTION.SAGAS.BILLING.CUSTOMER.CHECKOUT_SUCCESS",
    CHECK_SUBSCRIPTION: "ACTION.SAGAS.BILLING.CUSTOMER.CHECK_SUBSCRIPTION",
    CREATE_SCHEDULED_SUBSCRIPTION: "ACTION.SAGAS.BILLING.CUSTOMER.CREATE_SCHEDULED_SUBSCRIPTION"
  },
  PORTAL_SESSION: {
    CREATE: "ACTION.SAGAS.BILLING.PORTAL_SESSION.CREATE",
    // UPDATE: "ACTION.SAGAS.BILLING.PORTAL_SESSION.UPDATE",
  },
  CHECKOUT_SESSION: {
    CREATE: "ACTION.SAGAS.BILLING.CHECKOUT_SESSION.CREATE",
  },
  PLANS: {
    READ: "ACTION.SAGAS.BILLING.PLANS.READ",
  },
};
export default BILLING;
