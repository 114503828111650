import DB from "../constants";
import createHasuraRequest from "../utils";

export type DefaultTemplates = {
  read: (token: string, persona: string) => any;
};

const default_templates: DefaultTemplates = {
  read: async (token, persona) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.DEFAULT_TEMPLATES.READ,
      token
    );
    const parameters = {
      persona,
    };

    return await request(parameters);
  },
};

export { default_templates };
