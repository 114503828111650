import DB from "../constants";
import createHasuraRequest from "../utils";

export type MiguiBumperFlow = {
  update: (uid: string, token: string, newContent: boolean) => any;
};

const migui_bumper_flow: MiguiBumperFlow = {
  update: async (uid, token, newContent) => {
    const request = createHasuraRequest(
      DB.FLAGS.MIGUI_BUMPER_FLOW.UPDATE,
      token
    );
    const parameters = {
      uid,
      migui_bumper_flow: newContent,
    };
    return await request(parameters);
  },
};

export { migui_bumper_flow };
