import DB from "../constants";
import createHasuraRequest from "../utils";

export type UsageReason = {
  update: (uid: string, token: string, usage_reason: any) => any;
};

const usage_reason: UsageReason = {
  update: async (uid, token, usage_reason) => {
    const request = createHasuraRequest(
      DB.ACCOUNT_SETTINGS.USAGE_REASON.UPDATE,
      token
    );
    const parameters = {
      id: uid,
      usage_reason: `{ ${usage_reason} }`,
    };

    return await request(parameters);
  },
};

export { usage_reason };
