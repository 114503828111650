const DEFAULT_TEMPLATES = {
  READ: `
  query defaultTemplateQuery ($persona: String!){
    templates_default_template_set(where: {persona: {_eq: $persona}}) {
      template_1
      template_2
      template_3
      template_4
      template_5
      templates
    }
  }
  `,
};

export default DEFAULT_TEMPLATES;
