/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { key } from "firebase-key";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ACTION } from "../actions";
import { ActionCreate } from "../actions/sagas/use-case";
import { fetchSafetyVals, getNonSafe } from "./app-state";
import { stampIndices, sortByIndexFlat, getToken } from "./utils/helpers";
import db from "../../services/hasura";
import SagasAppState from "../actions/sagas/app-state";
import { trackBackend } from "../../utils/analytics";

function* useCaseSaga() {
  yield all([
    takeEvery(ACTION.SAGAS.USE_CASE.CREATE, useCaseSagas.create),
    takeEvery(ACTION.SAGAS.USE_CASE.DELETE, useCaseSagas.deleteUseCase),
    takeEvery(ACTION.SAGAS.USE_CASE.UPDATE, useCaseSagas.update),
    takeEvery(
      ACTION.SAGAS.USE_CASE.CHECK_SAFETY,
      useCaseSagas.checkTemplateFieldsSafety
    ),
  ]);
}

type useCaseSagas = {
  checkTemplateFieldsSafety(): Generator<any>;
  update(action: ActionCreate): Generator<any>;
  updateFirebase(useCase: any): Generator<any>;
  deleteUseCase(action: any): Generator<any>;
  create(): Generator<any>;
};

const useCaseSagas: any = {
  checkTemplateFieldsSafety: function* checkTemplateFieldsSafety(): any {
    const { tmpRecipientType, tmpIntention, tmpInstructions } = yield select(
      (state) => state.useCases
    );

    // yield call(console.log, `tmpRecipientType ${tmpRecipientType}`);
    // yield call(console.log, `tmpIntention ${tmpIntention}`);

    const uid = yield select((state) => state.auth.uid);
    const token = yield getToken();

    if (uid && token) {
      const safetyVals = yield call(
        fetchSafetyVals,
        {
          recipient_type: tmpRecipientType,
          intention: tmpIntention,
          instructions: tmpInstructions,
          uid,
        },
        "template",
        token
      );

      // yield call(console.log, `safetyVals ${safetyVals}`);

      const nonSafeItems = yield call(getNonSafe, safetyVals);

      yield put({
        type: ACTION.LOCAL.APP_STATE.WARNED_LIST.UPDATE,
        payload: {
          field: "safetyWarnedTemplateFields",
          newArray: nonSafeItems,
        },
      });
    }
  },

  update: function* update(action: ActionCreate): any {
    try {
      const { useCaseId } = action.payload;
      // yield call(console.log, `useCaseId ${useCaseId}`);
      const {
        tmpEmoji,
        tmpRecipientType,
        tmpIntention,
        tmpName,
        tmpInstructions,
      } = yield select((state) => state.useCases);
      let setIndex = 0;
      // yield call(console.log, `tmpRecipientType ${tmpRecipientType}`);
      const { useCasesLocal } = yield select((state) => state.useCases);
      const useCase = yield select((state) =>
        state.useCases.useCasesLocal.find(
          (useCase: any) => useCase.id === useCaseId
        )
      );

      // yield call(console.log, `useCase`);
      // yield call(console.log, useCase);
      const editedUseCase = yield {
        ...useCase,
        emoji: typeof tmpEmoji === "string" ? tmpEmoji : tmpEmoji[0],
        recipient_type: tmpRecipientType,
        intention: tmpIntention,
        instructions: tmpInstructions,
        name: tmpName,
        modified_date: new Date(),
      };
      // check that none of the fields are empty before submitting // limit the length
      // yield call(console.log, `editeUseCase`);
      // yield call(console.log, editedUseCase);

      const filteredUseCaseLocal = useCasesLocal.filter(
        (useCase: any, index: number) => {
          if (useCase.id === useCaseId) {
            setIndex = index;
          }
          return useCase.id !== useCaseId;
        }
      );

      filteredUseCaseLocal.splice(setIndex, 0, editedUseCase);

      // const unsortedArray = yield select((state) =>
      //   state.useCases.useCasesLocal
      //     .filter((useCase: any) => useCase.id !== useCaseId)
      //     .concat([editedUseCase])
      // );

      const arrayToBind = yield call(stampIndices, filteredUseCaseLocal);
      // yield call(console.log, `newArray`);
      // yield call(console.log, arrayToBind);

      yield put({
        type: ACTION.LOCAL.USE_CASE.UPDATE,
        payload: { newArray: arrayToBind },
      });
      yield call(useCaseSagas.updateFirebase, editedUseCase);
      // only if two above were successful - otherwise show error
      yield put({
        type: ACTION.LOCAL.APP_STATE.CONFIG_EDIT.UPDATE,
        payload: { id: "configEdit", targetState: false },
      });
      yield put({
        type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
        payload: { id: "configEditDialog", targetState: false },
      });
      yield put({
        type: ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.CLOSE,
        payload: {},
      });
    } catch (e) {
      yield call(console.log, e);
    }
  },

  updateFirebase: function* updateFirebase(useCase: any): any {
    try {
      const token = yield getToken();

      yield put({
        type: "USE_CASES_MODIFIED_UPDATE_FIREBASE",
        payload: {},
      });
      // hasura call
      const res = yield call(db.template.update, token, useCase);

      yield put(SagasAppState.extensionSettingsMessage());
      // yield call(console.log, res);
    } catch (e) {
      yield call(console.log, e);
    }
  },

  deleteUseCase: function* deleteUseCase(action: any): any {
    try {
      const { useCaseId } = action.payload;
      const token = yield getToken();
      const newArray = yield select((state) =>
        state.useCases.useCasesLocal.filter(
          (useCase: any) => useCase.id !== useCaseId
        )
      );
      const arrayToBind = yield call(stampIndices, newArray);

      const res = yield call(db.template.delete, token, useCaseId);

      const returnedTemplateId =
        res.data.delete_db_users_templates?.returning[0]?.template_id;
      if (returnedTemplateId) {
        yield put({
          type: ACTION.SAGAS.USE_CASES.REORDER,
          payload: {
            useCasesArray: arrayToBind,
          },
        });
      }

      yield put({
        type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
        payload: { id: "configEditDialog", targetState: false },
      });

      yield put(SagasAppState.extensionSettingsMessage());
    } catch (e) {
      yield call(console.log, e);
    }
  },

  create: function* create(): any {
    try {
      const newKey = yield call(key);
      const token = yield getToken();
      const { uid } = yield select((state) => state.auth);
      const { intention, recipientDescription } = yield select(
        (state) => state.errors.component
      );
      const { useCases } = yield select((state) => state);
      let newTmpName = "";
      const {
        tmpEmoji,
        tmpName,
        tmpRecipientType,
        tmpIntention,
        tmpInstructions,
        useCasesLocal,
      } = useCases;
      const instructions = tmpInstructions ? tmpInstructions : "";
      if (tmpName.length === 0) {
        for (var i = 0; i <= useCasesLocal.length; i++) {
          if (useCasesLocal[i].name.includes("My Template")) {
            newTmpName =
              "My Template" + " " + (useCasesLocal.length + 1).toString();
            break;
          } else {
            newTmpName = "My Template";
            break;
          }
        }
      } else {
        newTmpName = tmpName;
      }

      if (!intention.hasNewLineOrDot && !recipientDescription.hasNewLineOrDot) {
        const newHasuraUseCase = {
          id: newKey,
          emoji: typeof tmpEmoji === "string" ? tmpEmoji : tmpEmoji[0],
          intention: tmpIntention,
          name: newTmpName,
          recipient_type: tmpRecipientType,
          instructions: instructions,
          created_date: new Date(),
          template_collection_id: "user_template",
        };
        yield put({
          type: ACTION.LOCAL.APP_STATE.VISIBILITY.TOGGLE,
          payload: { id: "configAddDialog", targetState: false },
        });

        const res = yield call(
          db.template.create,
          uid,
          token,
          newHasuraUseCase
        );

        const returnedTemplateId =
          res.data.insert_db_users_templates?.returning[0]?.template_id;
        if (returnedTemplateId) {
          yield put({
            type: ACTION.SAGAS.USE_CASES.REORDER,
            payload: {
              useCasesArray: [newHasuraUseCase].concat(useCasesLocal),
            },
          });
        }

        // yield put({
        //   type: ACTION.SAGAS.USE_CASES.LOAD,
        //   payload: {},
        // });
        yield put({
          type: ACTION.LOCAL.APP_STATE.CONFIG_EDIT_DIALOG.CLOSE,
          payload: {},
        });

        yield put(SagasAppState.extensionSettingsMessage());

        trackBackend("Custom Template Created", {
          site: `${process.env.REACT_APP_APP_HOST}/templates`,
        });
      }
    } catch (error) {
      // here instead dispatch error message, could not push to firebase
      yield call(console.log, error);
    }
  },
};

export default useCaseSaga;
