/* 
utility to prepend path to sub-route tree -> base_url + path + route
base_url includes /api/ path
*/
function prependRoute<T>(path: string, subRoutes: T): T {
  if (!subRoutes) return subRoutes;

  if (
    typeof subRoutes === "object" &&
    !Array.isArray(subRoutes) &&
    subRoutes !== null
  ) {
    Object.keys(subRoutes as any).forEach((key) => {
      if (typeof (subRoutes as any)[key] === "string") {
        (subRoutes as any)[key] = path + (subRoutes as any)[key];
      } else if (
        typeof (subRoutes as any)[key] === "object" &&
        !Array.isArray(subRoutes) &&
        subRoutes !== null
      ) {
        (subRoutes as any)[key] = prependRoute(path, (subRoutes as any)[key]);
      }
    });
  }
  return { ...subRoutes };
}

export { prependRoute };
