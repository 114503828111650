import SAGAS from "./constants";

export type SagasEventStreamRepo = {
  Request: {
    create(state: any, source: any): any;
    send(appState: any, useCase: any, userId: string, resend: boolean): any;
    resend(appState: any, useCase: any, userId: string, resend: boolean): any;
  };
  Onboarding: {
    create(): any;
  };
  MultiGeneration: {
    create(): any;
  };
  start(source: any): any;
  sseRequest(): any;
  error(): any;
  // rateLimitError(expire: any): any;
  concat(newString: string, pill: boolean, bodyId: number): any;
  interrupt(): any;
};

const EventStream: SagasEventStreamRepo = {
  Request: {
    create: (state, source) => ({
      type: SAGAS.EVENT_STREAM.REQUEST.CREATE,
      payload: { state, source },
    }),
    send: (appState, useCase, userId, resend) => ({
      type: SAGAS.EVENT_STREAM.REQUEST.SEND,
      payload: { appState, useCase, userId, resend },
    }),
    resend: (appState, useCase, userId, resend) => ({
      type: SAGAS.EVENT_STREAM.REQUEST.RESEND,
      payload: { appState, useCase, userId, resend },
    }),
  },
  Onboarding: {
    create: () => ({
      type: SAGAS.EVENT_STREAM.ONBOARDING.CREATE,
      payload: {},
    }),
  },
  MultiGeneration: {
    create: () => ({
      type: SAGAS.EVENT_STREAM.MULTI_GENERATION.CREATE,
      payload: {},
    }),
  },
  start: (source) => ({
    type: SAGAS.EVENT_STREAM.START,
    payload: { source },
  }),
  sseRequest: () => ({
    type: SAGAS.EVENT_STREAM.SSE_REQUEST,
    payload: {},
  }),
  error: () => ({
    type: SAGAS.EVENT_STREAM.ERROR,
    payload: {},
  }),
  // rateLimitError: (expire) => ({
  //   type: SAGAS.EVENT_STREAM.RATE_LIMIT_ERROR,
  //   payload: { expire },
  // }),
  concat: (newString, pill, bodyId) => ({
    type: `SAGAS.EVENT_STREAM.CONCAT_${bodyId}`,
    payload: { newString, pill, bodyId },
  }),

  interrupt: () => ({
    type: SAGAS.EVENT_STREAM.INTERRUPT,
    payload: {},
  }),
};

export default EventStream;
