/*
These errors can co-exist and/or co-occur
Structure definition
- <a word that describes possession of an undesirable state><undesirable state>
- eg <has><SpecialCharacter>
- eg <is><Empty>
*/

const recipientName = {
  isEmpty: false,
};

const intention = {
  hasNewLineOrDot: false,
};

const recipientDescription = {
  hasNewLineOrDot: false,
};
const componentError = {
  recipientName,
  intention,
  recipientDescription,
};

export { recipientName, intention, recipientDescription };
export default componentError;
