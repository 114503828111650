/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable arrow-body-style */
import React, { lazy, FunctionComponent, useEffect } from "react";
// import * as Sentry from "@sentry/react";
import { Switch, Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { history } from "./redux/store";
import AuthIsLoaded from "./auth-is-loaded";
import PrivateRoute from "./private-route";
import UseCases from "./redux/actions/sagas/use-cases";
import RedirectRoute from "./redirect-route";
import AccessibleNavigationAnnouncer from "./components/accessible-navigation-announcer";
import BrowserLocationWrapper from "./containers/browser-history";
import ExtensionUninstall from "./containers/extension-uninstall";
import { sendChromeMessage } from "./utils/env-respective-return";

const Layout = lazy(() => import("./containers/layout"));
const Login = lazy(() => import("./pages/login"));

type AppProps = {
  appState: any;
};

const App: FunctionComponent<AppProps> = (props: AppProps) => {
  const { appState } = props;

  useEffect(() => {
    if (appState.settings.darkMode) {
      document.getElementsByTagName("html")[0].classList.add("dark");
    } else {
      document.getElementsByTagName("html")[0].classList.remove("dark");
    }
  }, [appState.settings.darkMode]);

  /* Activate the idle background script */
  useEffect(() => {
    sendChromeMessage({
      type: "activate_background",
    });
  }, []);

  return (
    <>
      <ConnectedRouter history={history}>
        {/* <BrowserLocationWrapper> */}
        <ExtensionUninstall>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <AuthIsLoaded path="/login">
              <Login />
            </AuthIsLoaded>
            <PrivateRoute path="/">
              <RedirectRoute>
                <Layout />
              </RedirectRoute>
            </PrivateRoute>
          </Switch>
        </ExtensionUninstall>
        {/* </BrowserLocationWrapper> */}
      </ConnectedRouter>
    </>
  );
  // eslint-disable-next-line no-else-return
};

document.addEventListener("flowriteopenintercom", () => {
  window.Intercom("show");
});

const mapStateToProps = (state: any) => ({
  appState: state.appState,
});
const mapDispatchToProps = { SagasUseCasesCreate: UseCases.create };

export default connect(mapStateToProps, mapDispatchToProps)(App);
