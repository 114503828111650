import DB from "../constants";
import createHasuraRequest from "../utils";

export type invideCode = {
  customer: {
    read: (uid: string, email: any, token: any) => any;
  };
};

const invite_codes: invideCode = {
  customer: {
    read: async (uid, email, token) => {
      const request = createHasuraRequest(DB.INVITE_CODES.CUSTOMER.READ, token);
      const parameters = {
        "email": {"_eq": email}
      };
      return await request(parameters);
    },
  },
};

export { invite_codes };
