export type Index = number;
export type Emoji = string;
export type TemplateName = string;
export type RecipientType = string;
export type Intention = string;
export type Description = string;
export type Instructions = string;

export type CreatedDate = any;
export type ModifiedDate = any;
export type UserGroup = string[];
export type CaseType = string[];

export type UseCase = {
  id: string;
  template_id?: string;
  template_collection_id?: string;
  index?: Index;
  emoji: Emoji;
  name: TemplateName;
  description?: Description;
  recipient_type: RecipientType;
  instructions: Instructions;
  intention: Intention;
  created_date?: CreatedDate;
  modified_date?: ModifiedDate;
  user_group?: UserGroup;
  template_type?: CaseType;
};

export type UseCases = UseCase[];

export type UseCasesState = {
  useCasesLocal: UseCases;
  galleryTemplatesLocal: UseCases;
  tmpEmoji: Emoji;
  tmpName: TemplateName;
  tmpRecipientType: RecipientType;
  tmpIntention: Intention;
  tmpDescription: Description;
  tmpInstructions: Instructions;
};

export const AITId = "automatic-template-id";

export const AIT = {
  emoji: "",
  name: "General template",
  description:
    "Use this template when you don't have a more suitable template at hand",
  intention: "",
  recipient_type: "",
  instructions: "",
  template_id: AITId,
  template_collection_id: AITId,
};

const useCases: UseCasesState = {
  useCasesLocal: [],
  galleryTemplatesLocal: [],
  tmpEmoji: "✍️",
  tmpName: "",
  tmpRecipientType: "",
  tmpIntention: "",
  tmpDescription: "",
  tmpInstructions: "",
};

export default useCases;
