const FEEDBACK = {
  CREATE: `
  mutation FeedbackCreate ($objects: [generation_feedback_insert_input!]!){
    insert_generation_feedback(objects: $objects) {
      affected_rows
    }
  }
  `,
};

export default FEEDBACK;
