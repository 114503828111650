/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from "redux-saga/effects";
import { flowriteModeDisable, sendRateLimitError } from "../../../utils/helpers";

function* onRateLimitError(source: any, data: any) {
  try {
    source.close();
    yield sendRateLimitError(data.expire); // these need to go to analytics or datadog or a logging service
    yield flowriteModeDisable();
  } catch (error) {
    yield call(console.log, error);
  }
}

export default onRateLimitError;
