/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import DB from "../constants";
import createHasuraRequest from "../utils";

export type StreamOptions = {
  read: (stream_type: "suggestions", token: string) => any;
};

const stream_options: StreamOptions = {
  read: async (stream_type, token) => {
    const request = createHasuraRequest(DB.STREAM_OPTIONS.READ, token);
    const parameters = {
      stream_type,
    };
    return await request(parameters);
  },
};

export { stream_options };
