const MIGUI_BUMPER_FLOW = {
  UPDATE: `
        mutation updateMiguiBumper($uid: String!, $migui_bumper_flow: Boolean) {
            update_db_flags(where: {id: {_eq: $uid}}, _set: {migui_bumper_flow: $migui_bumper_flow}) {
              affected_rows
              returning {
                id
              }
            }
          }
        `,
};

export default MIGUI_BUMPER_FLOW;
