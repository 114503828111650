/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { call, select } from "redux-saga/effects";
import { decodeReceived, output, trimWhiteSpace } from "../../../utils/helpers";

function* onCompletion(data: any, bodyId: number): any {
  try {
    const decoded = yield call(decodeReceived, data);
    const warning = yield select(
      (state) => state.appState.promptWarningSecondBody
    );

    const trimmed = yield call(
      trimWhiteSpace,
      decoded,
      data,
      warning === "0" ? 0 : 1
    );
    yield output(trimmed, false, bodyId);
  } catch (error) {
    yield call(console.log, error);
  }
}
export default onCompletion;
