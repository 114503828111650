import { CompletionLength } from "../../../../constants/common";
import { stream } from "../../config";
import {getRequest, makeRequest} from "../../helpers";
import apiRoutes from "../constants";
import apiRequests from "../index";

export type GenerationPayload = {
  recipient: string;
  generation_count: number;
  first_body: string;
  second_body: string;
  reply: boolean;
  creativity: string;
  completion_length: CompletionLength;
  template_id: string | null;
  identity: any;
  first_name: string;
  company_name: string;
  last_name: string;
  uid: string;
  automatic_template: boolean;
  seed_id: string | null;
  regeneration: boolean;
  flowrite_mode: string;
};

export type PreviewPromptPayload = {
  recipient: string;
  generation_count: number;
  first_body: string;
  second_body: string;
  reply: boolean;
  completion_length: CompletionLength;
  recipient_type: string | null;
  instructions: string;
  intention: any;
  identity: any;
  first_name: string;
  last_name: string;
  uid: string;
  automatic_template: boolean;
  seed_id: string | null;
  regeneration: boolean;
};

export type SaveUseCasePayload = {
  uid: string;
  template_id: string;
  template_name: string;
  intention: string;
};

export type FlowRequests = {
  time_saving: {
    fetch: (uid: string, token: string) => any;
  };

  recognizeRecipient: (
    first_body: string,
    first_name: string,
    flowrite_mode: any,
    token: string
  ) => any;

  stream: (payload: GenerationPayload, token: string) => any;

  previewPrompt: (payload: PreviewPromptPayload, token: string) => any;

  saveUseCase: (
    uid: string,
    template_id: string,
    template_name: string,
    intention: string,
    token: string
  ) => any;

  identifyUser: (uid: string, token: string) => any;

  sendVipInvite: (
    user_email: string,
    invited_email: string,
    first_name: string,
    last_name: string,
    token: string
  ) => any;

  instantAccessUser: (uid: string, email: string, token: string) => any;
  logEventTracking: (event_name: string, data: {}, token: string) => any;
  logSegmentIdentify: (data: {}, token: string) => any;
  getReferralToken: (token: string) => any;
};

const flowRequests: FlowRequests = {
  time_saving: {
    fetch: (uid, token) =>
      makeRequest(apiRoutes.flow.time_saving, { uid }).authenticated(token),
  },

  recognizeRecipient: (first_body, first_name, flowrite_mode, token) =>
    makeRequest(apiRoutes.flow.recipient_recognition, {
      first_body,
      first_name,
      flowrite_mode,
    }).authenticated(token),

  stream: (payload, token) => stream(JSON.stringify(payload), token),

  previewPrompt: (payload, token) =>
    makeRequest(apiRoutes.flow.preview_prompt, payload).authenticated(token),

  saveUseCase: (uid, template_id, template_name, intention, token) =>
    makeRequest(apiRoutes.flow.save_use_case, {
      uid,
      template_id,
      template_name,
      intention,
    } as SaveUseCasePayload).authenticated(token),

  identifyUser: (uid, token) =>
    makeRequest(apiRoutes.flow.identify_user, { uid }).authenticated(token),

  sendVipInvite: (user_email, invited_email, first_name, last_name, token) =>
    makeRequest(apiRoutes.flow.send_vip_invite, {
      user_email,
      invited_email,
      first_name,
      last_name,
    }).authenticated(token),

  instantAccessUser: (uid, email, token) =>
    makeRequest(apiRoutes.flow.instant_access, {
      uid,
      email,
    }).authenticated(token),

  logEventTracking: (event_name, data, token) =>
    makeRequest(apiRoutes.flow.log_event_tracking, {
      event_name,
      data,
    }).authenticated(token),

  logSegmentIdentify: (data, token) =>
    makeRequest(apiRoutes.flow.log_segment_identify, {
      data,
    }).authenticated(token),

  getReferralToken: (token) =>
      getRequest(apiRoutes.flow.get_referral_token).authenticated(token),
};

export default flowRequests;
