const GENERATION_COUNT = {
  UPDATE: `
          mutation updateGenerationCountFlag($uid: String!, $generation_count: Int) {
              update_db_flags(where: {id: {_eq: $uid}}, _set: {generation_count: $generation_count}) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
};

export default GENERATION_COUNT;
