import DB from "../constants";
import createHasuraRequest from "../utils";

export type TemplatesOrder = {
  read: (uid: string, token: string) => any;
  update: (uid: string, newOrder: string[], token: string) => any;
};

const templates_order: TemplatesOrder = {
  read: async (uid, token) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.TEMPLATES_ORDER.READ,
      token
    );
    const parameters = {
      uid,
    };

    return await request(parameters);
  },
  update: async (uid, newOrder, token) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.TEMPLATES_ORDER.UPDATE,
      token
    );
    const parameters = {
      uid,
      templates_order: `{ ${newOrder} }`,
    };

    return await request(parameters);
  },
};

export { templates_order };
