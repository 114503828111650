/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import Sagas from "./redux/actions/sagas";
import { Circle } from "./icons";
import { MigratorFlag } from "./redux/initial-state/app-state";
import ErrorPopup from "./components/popups/error-popup";
import apiRequests from "./services/api/routes";
import { fetchToken } from "./redux/reducers/utils";

const PrivateRoute: FunctionComponent<any> = (props: any) => {
  const {
    children,
    auth,
    email,
    onLogin,
    migrationFlag,
    migrationCompleted,
    extensionLogin,
    ...rest
  } = props;
  const [emailValidating, setValidating] = useState<boolean>(false);

  /*
  a user is blocked from the product by solely the allowlist
  a try to authenticate will create an identity with our authorization proviider
  the user would be "logged in" even without being on the allowlist.
  if the routes are changed to protected ones [ /write ] by the user
  then they may enter the product.
  This container wraps all protected routes and will check on mount if the auth email is
  in the allowlist, if not, the user will be logged out and redirected to login.
   */

  /* TODO: move to side-effect layer */
  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty && auth.email) {
      const request = apiRequests.auth.bouncer(auth.email, auth.uid);
      setValidating(true);
      request
        .then(async (response: any) => {
          if (response.allowed) {
            /* trigger settings fetch */
            onLogin();
            setValidating(false);
            const token = await fetchToken();
            if (token) {
              const referral_auth = await apiRequests.flow.getReferralToken(
                token
              );
              sessionStorage.setItem("ref_auth_token", referral_auth.token);
              sessionStorage.setItem(
                "ref_product_id",
                referral_auth.product_id
              );
              if (referral_auth.token && referral_auth.product_id) {
                window.cello = window.cello || { cmd: [] };
                window.cello.cmd.push(function (cello: any) {
                  cello.boot({
                    productId: referral_auth.product_id,
                    token: referral_auth.token,
                    showOnBoot: false,
                  });
                });
              }
            }
          } else {
            setValidating(false);
            Auth.signOut();
          }
        })
        .catch((e: any) => {
          setValidating(false);
          Auth.signOut();
        });
    }
  }, [auth.isLoaded, auth.isEmpty]);

  const loader = () => (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-gray-50 dark:bg-gray-900">
      <Circle className="w-10 h-10 text-gray-300 animate-spin dark:text-gray-700" />
      {migrationFlag === MigratorFlag.REQUEST_PENDING && (
        <p className="text-gray-500 animate-fadeInSlower mt-5 text-center sm:mx-6">
          Loading your preferences. This could take a few seconds.
        </p>
      )}
    </div>
  );

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (emailValidating) {
          return loader();
        }
        if (migrationFlag === MigratorFlag.REQUEST_ERROR || auth.hasFailed) {
          return (
            <ErrorPopup
              messages={
                auth.hasFailed
                  ? [
                      auth.failureReason,
                      "Please contact us at support@flowrite.com so we can help you.",
                    ]
                  : [
                      "We've encountered an issue while logging you in.",
                      "Please contact us at support@flowrite.com so we can help you.",
                    ]
              }
            />
          );
        }
        if (!auth.isLoaded || auth.isLoading) {
          return loader();
          /* after auth but user settings not loaded */
          /* we use settings.email here as we are testing that our settings have been fetched from our remote */
        } else if (auth.isLoaded && !auth.isEmpty && email === "") {
          return loader();
          /* user not found */
        } else if (auth.isLoaded && auth.isEmpty) {
          // update extension_install flag in case users use extension before creating account
          if (location.search.includes("extension_install")) {
            localStorage.setItem("extension_install", "true");
          }
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
        if (migrationCompleted) {
          return children;
        }
      }}
    />
  );
};

const mapStateToProps = (state: any, props: any) => ({
  email: state.appState.settings.email,
  auth: state.auth,
  migrationFlag: state.appState.db_migration_flag,
  migrationCompleted: state.appState.settings.flags.migration_completed,
});

const mapDispatchToProps = {
  onLogin: Sagas.AppState.onLogin,
  extensionLogin: Sagas.AppState.extensionLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
