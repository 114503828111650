import SAGAS from "./constants";

export type SagasFlagsRepo = {
  NewIdentityAdded: {
    update: (identityFlag: boolean) => any;
  };
};

const SagasFlagsState: SagasFlagsRepo = {
  NewIdentityAdded: {
    update: (identityFlag) => ({
      type: SAGAS.FLAGS.NEW_IDENTITY_ADDED.UPDATE,
      payload: { identityFlag },
    }),
  },
};

export default SagasFlagsState;
