import DB from "../constants";
import createHasuraRequest from "../utils";

export type History = {
  getGenerations: (
    uid: string,
    token: string,
    start_date: Date,
    end_date: Date,
    offset: number
  ) => any;
  getIterations: (
    uid: string,
    token: string,
    start_date: Date,
    end_date: Date
  ) => any;
};

const history: History = {
  getGenerations: async (uid, token, start_date, end_date, offset) => {
    const request = createHasuraRequest(
      DB.GENERATION.HISTORY.GENERATIONS.GET,
      token
    );
    const parameters = { uid, start_date, end_date, offset };

    return await request(parameters);
  },
  getIterations: async (uid, token, start_date, end_date) => {
    const request = createHasuraRequest(
      DB.GENERATION.HISTORY.ITERATIONS.GET,
      token
    );
    const parameters = { uid, start_date, end_date };

    return await request(parameters);
  },
};

export { history };
