import DB from "../constants";
import createHasuraRequest from "../utils";

type OnboardingFlags = {
  onboarding_mode: boolean;
  onboarding_step: number;
  steps_completed: number;
  onboarding_step_text: string;
  step_completed_text: string;
};

export type Onboarding = {
  update: (uid: string, token: string, flags: OnboardingFlags) => any;
};

const onboarding: Onboarding = {
  update: async (uid, token, flags) => {
    const request = createHasuraRequest(DB.FLAGS.ONBOARDING.UPDATE, token);
    const {
      onboarding_mode,
      onboarding_step,
      steps_completed,
      onboarding_step_text,
      step_completed_text,
    } = flags;
    const parameters = {
      uid,
      onboarding_mode,
      onboarding_step,
      steps_completed,
      onboarding_step_text,
      step_completed_text,
    };

    return await request(parameters);
  },
};

export { onboarding };
