const IDENTITY = {
  UPDATE: `
      mutation updateIdentity (
        $id: String,
        $role: String,
        $company_name: String,
        $company_description: String,
        $processed_role: String,
        $processed_company_name: String,
        $processed_company_description: String,
        $first_preposition: String,
        $second_preposition: String,
    )
    {
      update_db_account_settings(where: {id: {_eq: $id}}, _set: {
        role: $role,
        company_name: $company_name,
        company_description: $company_description,
        processed_role: $processed_role,
        processed_company_name: $processed_company_name,
        processed_company_description: $processed_company_description,
        first_preposition: $first_preposition,
        second_preposition: $second_preposition,
      }) {
        returning {
          id
        }
        affected_rows
      }
      update_db_flags(where: {id: {_eq: $id}}, _set: {
        new_identity_added: true
      }) {
        affected_rows
      }
    }
  `,
  UPDATE_PROCESSED: `
        mutation updateIdentity (
          $id: String,
          $processed_role: String,
          $processed_company_name: String,
          $processed_company_description: String,
       )
       {
        update_db_account_settings(where: {id: {_eq: $id}}, _set: {
          processed_role: $processed_role,
          processed_company_name: $processed_company_name,
          processed_company_description: $processed_company_description,
        }) {
          returning {
            id
            processed_role
            processed_company_name
            processed_company_description
          }
          affected_rows
        }
      }
    `,
};

export default IDENTITY;
