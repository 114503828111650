import DB from "../constants";
import createHasuraRequest from "../utils";

export type BulletSuggestions = {
  read: (token: string, template_id: string, mode: string) => any;
};

const bullet_suggestions: BulletSuggestions = {
  read: async (token, template_id, mode) => {
    const request = createHasuraRequest(
      DB.TEMPLATES.BULLET_SUGGESTIONS.READ,
      token
    );
    const parameters = {
      template_id,
      mode,
    };

    return await request(parameters);
  },
};

export { bullet_suggestions };
