import { call } from "redux-saga/effects";

const createGreeting = (appState: any) =>
  call(
    ({ recipient, settings }) =>
      `${settings.selectedGreeting.replace(
        ":recipient:",
        recipient === "" ? "<name>" : recipient
      )}`,
    appState
  );

export default createGreeting;
